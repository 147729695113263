import { clientInstance } from '../utils/http-client'
import { PaginatedResponse } from '../models/paginated-response'
import { baseAPICall } from './baseAPICall'
import { EmailIntegration, EmailMessage } from 'models/emailIntegration'
import { useQuery } from '@tanstack/react-query'

export async function getEmailIntegrationList(
  params = {},
  signal?: AbortSignal
) {
  return baseAPICall<PaginatedResponse<EmailIntegration>>(() =>
    clientInstance.get(`/email_integration/`, {
      params: {
        ...params,
      },
      signal,
    })
  )
}
async function getEmailIntegrationOptions(withNoneOption?: boolean) {
  return baseAPICall<
    {
      label: string
      value: number
      original?: EmailIntegration
    }[]
  >(() =>
    clientInstance.get(`/email_integration/`, {
      transformResponse: (data) => {
        const response: PaginatedResponse<EmailIntegration> = JSON.parse(data)
        const opts = response.results.map((emailIntegration) => ({
          label: emailIntegration.email,
          value: emailIntegration.id,
          original: emailIntegration as EmailIntegration | undefined,
        }))

        if (withNoneOption) {
          opts.unshift({ label: 'No Email', value: -1, original: undefined })
        }

        return opts
      },
    })
  )
}

export const useGetEmailIntegrationOptions = (withNoneOption?: boolean) =>
  useQuery({
    queryKey: ['email-integrations', withNoneOption ? 'with-none' : ''],
    queryFn: () => getEmailIntegrationOptions(withNoneOption),
  })

export async function getEmailIntegrationAuthUrl({
  provider,
  isShared,
}: {
  provider?: string
  isShared: boolean
}): Promise<{ auth_url: string }> {
  return baseAPICall(() =>
    clientInstance.get(`/email_integration/get_auth_url/`, {
      params: {
        is_shared: isShared,
        provider,
      },
    })
  )
}

export async function updateEmailIntegration(
  emailIntegrationId: number,
  data: Partial<{ is_shared: boolean }>
) {
  return baseAPICall(() =>
    clientInstance.patch(`/email_integration/${emailIntegrationId}/`, data)
  )
}

export async function syncEmailIntegration(emailIntegrationId: number) {
  return baseAPICall(() =>
    clientInstance.post(`/email_integration/${emailIntegrationId}/sync/`)
  )
}

export async function deleteEmailIntegration(emailIntegrationId: number) {
  return baseAPICall(() =>
    clientInstance.delete(`/email_integration/${emailIntegrationId}/`)
  )
}

export async function getEmailMessageList(params = {}, signal?: AbortSignal) {
  return baseAPICall<PaginatedResponse<EmailMessage>>(() =>
    clientInstance.get(`/email_integration/email_message/`, {
      params: {
        ...params,
      },
      signal,
    })
  )
}

export async function sendEmailMessage(
  emailIntegrationId: number,
  data: {
    to: {
      name: string
      email: string
    }[]
    subject: string
    body: string
  }
) {
  return baseAPICall(() =>
    clientInstance.post(`/email_integration/${emailIntegrationId}/send/`, data)
  )
}

export async function getEmailMessageById(
  emailId: string | number,
  signal?: AbortSignal
) {
  return baseAPICall<EmailMessage>(() =>
    clientInstance.get(`/email_integration/email_message/${emailId}/`, {
      signal,
    })
  )
}
