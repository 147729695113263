import { FiDownload, FiFile, FiFileText, FiImage } from 'react-icons/fi'
import { formatBytes } from 'utils/formatBytes'

interface AttachmentProps {
  filename: string
  size: number
  contentType: string
  onClick?: () => void
}

export const Attachment = ({
  filename,
  size,
  contentType,
  onClick,
}: AttachmentProps) => {
  const getIcon = () => {
    if (contentType.startsWith('image/')) {
      return <FiImage size={18} />
    } else if (
      contentType === 'text/plain' ||
      contentType === 'text/html' ||
      contentType === 'application/pdf'
    ) {
      return <FiFileText size={18} />
    } else {
      return <FiFile size={18} />
    }
  }

  return (
    <div
      className="px-3 py-2 border rounded flex items-center gap-2 bg-gray-50 hover:bg-gray-100 cursor-pointer"
      onClick={onClick}
    >
      <div className="text-gray-500">{getIcon()}</div>
      <div className="flex flex-col">
        <span className="text-sm font-medium truncate max-w-[150px]">
          {filename}
        </span>
        <span className="text-xs text-gray-500">{formatBytes(size)}</span>
      </div>
      <div className="ml-2 text-gray-500">
        <FiDownload size={16} />
      </div>
    </div>
  )
}
