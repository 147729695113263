import to from 'await-to-js'
import axios, { AxiosResponse } from 'axios'
import { notifyError, notifySuccess } from './toast'

export interface BaseAPICallOptions {
  successMessage?: string
  disableNotifyError?: boolean
  disableNotifyGenericError?: boolean
}

export async function baseAPICall<T>(
  fetcher: () => Promise<AxiosResponse<T>>,
  {
    successMessage,
    disableNotifyError,
    disableNotifyGenericError,
  }: BaseAPICallOptions = {}
) {
  const [err, res] = await to(fetcher())

  const ignoreErrorCodes = ['ERR_CANCELED']
  if (
    err &&
    axios.isAxiosError(err) &&
    !ignoreErrorCodes.includes(err.code ?? '')
  ) {
    const currentTime = new Date().getTime()
    const startTime = (err.config as any)?.metadata?.startTime ?? currentTime // If not set, assume the request started now
    const requestDuration = currentTime - startTime
    if (err.code === 'ERR_NETWORK') {
      if (requestDuration > 30000) {
        notifyError(
          'The request timed out or there are issues with your internet connection. Please check your connection and try again.'
        )
      }
    } else if (err.response?.status === 408) {
      notifyError(
        'Sorry, our servers are under heavy load now, please try again.'
      )
    } else {
      if (err?.response?.data.error_message) {
        if (!disableNotifyError) {
          notifyError(err.response.data.error_message)
        }
      } else if (!disableNotifyGenericError) {
        notifyError(err.message)
      }
    }
    throw err
  }

  if (!res) throw err

  if (successMessage) {
    notifySuccess(successMessage)
  }

  return res.data
}
