import { CompanyTypeSlug } from '../models/companies'

export const CHAINS_TABLE_KEY = 'all-chains-table'
export const DOORS_TABLE_KEY = 'all-doors-table'
export const COLLEGE_AND_UNIVERSITY_TABLE_KEY = 'college-and-universities-table'
export const K12_TABLE_KEY = 'k-12-table'
export const CHAIN_SUMMARY_KEY = 'chains_summary'
export const DOOR_SUMMARY_KEY = 'places_summary'
export const UGC_TABLE_KEY = 'ugc-table'
export const DEAL_TABLE_KEY = 'all-deals-table'
export const CONTACTS_TABLE_KEY = 'contacts-table'
export const CONTACT_REQUEST_JOB_TABLE_KEY = 'contact-requests-table'
export const HOSPITALS_TABLE_KEY = 'hospitals-table'
export const NURSING_HOMES_TABLE_KEY = 'nursing-homes-table'
export const LODGING_TABLE_KEY = 'lodging-table'
export const LODGING_DOOR_TABLE_KEY = 'lodging-door-table'
export const ALL_CAMPAIGNS_TABLE_KEY = 'ALL_CAMPAIGNS_TABLE'

export const getCompanyTableKey = (slug: CompanyTypeSlug) =>
  `${slug}-company-table`
export const getCampaignTableKey = (tableKey: string, campaignID: number) =>
  `${tableKey}-campaign-${campaignID}`
