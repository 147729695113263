import { useEffect, useState } from 'react'
import { EmailMessage } from '../../models/emailIntegration'
import apiService from '../../services/api'
import { Modal } from '../UI/Modal/Modal'
import { format } from 'date-fns'
import to from 'await-to-js'
import { Editor } from 'components/Editor/Editor'
import { Attachment } from 'components/UI/Attachment/Attachment'

export default function EmailViewModal(props: {
  partialEmailData: EmailMessage
  show: boolean
  handleClose: () => void
}) {
  const { partialEmailData } = props
  const api = apiService()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [emailData, setEmailData] = useState<EmailMessage | null>(null)

  useEffect(() => {
    if (props.show && partialEmailData?.id) {
      loadEmailContent()
    }
  }, [props.show, partialEmailData?.id])

  const loadEmailContent = async () => {
    if (!partialEmailData?.id) return

    setIsLoading(true)
    const [err, response] = await to(
      api.emailIntegration.getEmailMessageById(partialEmailData.id)
    )

    if (err) {
      console.error('Error loading email content', err)
      setIsLoading(false)
      return
    }

    setEmailData(response)
    setIsLoading(false)
  }

  const formatDate = (date: string) => {
    return format(new Date(date), 'PPpp') // Format: Apr 29, 2023, 1:30:00 PM
  }

  return (
    <Modal
      size="xl"
      open={props.show}
      onOpenChange={(open) => !open && props.handleClose()}
      title={partialEmailData?.subject || 'Email'}
      onAccept={props.handleClose}
      acceptButtonText="Close"
      loading={isLoading}
      hideCancelButton
    >
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="flex justify-between">
            <div className="text-gray-700">
              <strong>From:</strong>{' '}
              {partialEmailData?.related_contacts
                ?.map((contact) => `${contact.first_name} ${contact.last_name}`)
                .join(', ') || 'Unknown'}
            </div>
            <div className="text-gray-500 text-sm">
              {formatDate(partialEmailData.date)}
            </div>
          </div>

          <div className="flex gap-2">
            {partialEmailData.outbound ? 'Outbound' : 'Inbound'}
          </div>
        </div>

        {/* Email content */}
        <div className="min-h-[300px] max-h-[400px] overflow-y-auto p-2 border rounded transition-all duration-300">
          {isLoading ? (
            <div className="flex h-full m-3">
              <p className="text-gray-500">Loading email content...</p>
            </div>
          ) : (
            <Editor
              value={emailData?.body || ''}
              readOnly
              disableToolbar
              noBorder
            />
          )}
        </div>

        {/* Attachments */}
        {emailData?.attachments && emailData?.attachments.length > 0 && (
          <div>
            <h3 className="text-md font-semibold mb-2">Attachments</h3>
            <div className="flex flex-wrap gap-2">
              {emailData.attachments.map((attachment, index) => (
                <Attachment
                  key={index}
                  filename={attachment.filename}
                  size={attachment.size}
                  contentType={attachment.content_type}
                  onClick={() => {
                    /* Handle attachment download here */
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
