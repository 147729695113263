import { useMemo, useEffect } from 'react'
import { DataTable, DataTableProvider } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { ColumnsStoreProvider } from '../../stores/ColumnsStore/ColumnsStoreProvider'
import {
  useGetRebateClaims,
  RebateClaim,
  PaginatedRebateClaims,
} from './tradespend_api'
import {
  filterStoreRepo,
  useFilterParams,
} from '../../components/Filters/FilterStore'
import { TableSearch } from '../../components/Filters/TableSearch'
import { FilterChips } from '../../components/Filters/components/FilterChips/FilterChips'
import { ColumnSelectorRecipient } from '../../components/Modals/ColumnModal/ColumnModal'
import { RebateClaimsFilterset } from './RebateClaimsFilterset'
import * as S from '../../components/Tables/CommonTable.styles'
import { FilterIdentifier } from '../../models/saved_view'
import { dataTableSortingStoreRepo } from '../../components/DataTable/DataTableSorting/DataTableSortingStore'
import { useRebateClaimsColumns } from './useRebateClaimsColumns'
import { QueryObserverSuccessResult } from '@tanstack/react-query'

const PAGE_SIZE = 20
const IDENTIFIER: FilterIdentifier = 'REBATE_CLAIMS_TABLE'
type SortableFields = (keyof RebateClaim | string)[]

const sortableFields: SortableFields = [
  'company_name',
  'status',
  'payout_amount',
  'offer_name',
  'enrollment_date',
  'created',
]

function RebateClaimsTableComponent(props: {
  offerId?: number
  tableKey: string
  limit?: number
  isPaginationEnabled?: boolean
  hideFilters?: boolean
  hideColumnSelector?: boolean
  disableSorting?: boolean
}) {
  const rebateClaimsFilterStore = filterStoreRepo.getStore(IDENTIFIER)
  const { page, setPage } = rebateClaimsFilterStore()

  const { sorting, setSorting } = dataTableSortingStoreRepo.getStore(
    props.tableKey
  )()

  // If disableSorting is true, enforce created descending sort
  useEffect(() => {
    if (props.disableSorting) {
      setSorting([{ id: 'created', desc: true }])
    }
  }, [props.disableSorting, setSorting])

  const filtersAsParams = useFilterParams(rebateClaimsFilterStore)

  const filterParams = useMemo(() => {
    const params: Record<string, unknown> = { ...filtersAsParams }

    if (props.offerId) {
      params['offer_id'] = props.offerId
    }

    return params
  }, [filtersAsParams, props.offerId])

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    // If disableSorting is true, always use created descending
    if (props.disableSorting) {
      params['sort'] = '-created'
    } else if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting, props.disableSorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
      page: props.isPaginationEnabled === false ? 1 : page,
      limit: props.limit || PAGE_SIZE,
    }),
    [filterParams, sortParams, page, props.limit, props.isPaginationEnabled]
  )

  const { data: claimsData, isLoading } = useGetRebateClaims(
    filterAndSortParams
  ) as QueryObserverSuccessResult<PaginatedRebateClaims, Error>

  const columns = useRebateClaimsColumns()

  return (
    <DataTableContainer>
      {!props.hideFilters && (
        <S.SearchContainer>
          <div className="flex w-full gap-2 items-center">
            <TableSearch
              className={'flex-1'}
              filterStore={rebateClaimsFilterStore}
              searchPlaceholder="Search by company name or offer name"
            />
            <RebateClaimsFilterset filterIdentifier={IDENTIFIER} />
            {!props.hideColumnSelector && (
              <ColumnSelectorRecipient tableKey={props.tableKey} />
            )}
          </div>
          <div className="mt-2">
            <FilterChips
              identifier={IDENTIFIER}
              store={rebateClaimsFilterStore}
              showActive
              clearAllButton
            />
          </div>
        </S.SearchContainer>
      )}

      <DataTable
        tableHeader={undefined}
        loading={isLoading}
        data={claimsData?.results ?? []}
        columns={columns}
        tableKey={props.tableKey}
        isPaginationEnabled={props.isPaginationEnabled ?? true}
        sortableFields={props.disableSorting ? [] : sortableFields}
        paginationOptions={{
          totalRows: claimsData?.count ?? 0,
          pageSize: PAGE_SIZE,
          setPage: setPage,
          page: page,
          isPaginationLoading: isLoading,
        }}
      />
    </DataTableContainer>
  )
}

export function RebateClaimsTable(props: {
  offerId?: number
  limit?: number
  isPaginationEnabled?: boolean
  tableKey?: string
  hideFilters?: boolean
  hideColumnSelector?: boolean
  disableSorting?: boolean
}) {
  const tableKey = props.tableKey || 'rebate-claims-table'
  return (
    <ColumnsStoreProvider tableKey={tableKey}>
      <DataTableProvider tableKey={tableKey}>
        <RebateClaimsTableComponent tableKey={tableKey} {...props} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
