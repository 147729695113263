import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { AiOutlineCheckCircle } from 'react-icons/ai'

interface SuccessBannerProps {
  text: string
}

const SuccessBanner: React.FC<SuccessBannerProps> = ({ text }) => {
  return (
    <Alert variant="success" className="mb-0 py-[8px]">
      <div className="flex items-center gap-2 text-xl">
        <AiOutlineCheckCircle size={14} className="text-fb-dark-green" />
        <i className="text-sm">{text}</i>
      </div>
    </Alert>
  )
}

export default SuccessBanner
