import { useState } from 'react'
import { ExternalLinkIcon, Smartphone, Monitor } from 'lucide-react'
import { MobilePreview, ViewportPreset } from 'components/MobilePreview'
import { Modal } from 'components/UI/Modal/Modal'
import FbButton from '../../../components/FbUI/FbButton'

function PreviewModal({
  url,
  offerName,
  isOpen,
  onClose,
}: {
  url: string
  offerName: string
  isOpen: boolean
  onClose: () => void
}) {
  const [isMobileView, setIsMobileView] = useState(true)
  const [selectedViewport] = useState<ViewportPreset>('iPhone')

  return (
    <Modal
      open={isOpen}
      hideCancelButton
      onOpenChange={(open: boolean) => !open && onClose()}
      title={`Preview: ${offerName}`}
      size="2xl"
    >
      <div className="flex flex-col h-[650px]">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1">
            <FbButton
              variant={isMobileView ? 'primary' : 'secondary'}
              size="sm"
              onClick={() => setIsMobileView(true)}
            >
              <Smartphone className="h-4 w-4 mr-1" />
              Mobile
            </FbButton>
            <FbButton
              variant={!isMobileView ? 'primary' : 'secondary'}
              size="sm"
              onClick={() => setIsMobileView(false)}
            >
              <Monitor className="h-4 w-4 mr-1" />
              Desktop
            </FbButton>
          </div>

          <FbButton
            variant="secondary"
            size="sm"
            onClick={() => window.open(url, '_blank')}
          >
            <ExternalLinkIcon className="h-4 w-4 mr-1" />
            Open in new tab
          </FbButton>
        </div>

        <div className="flex-1 rounded-lg overflow-hidden">
          {isMobileView ? (
            <div className="w-full h-full p-1">
              <MobilePreview selectedViewport={selectedViewport} url={url} />
            </div>
          ) : (
            <div className="w-full h-full  p-1">
              <iframe
                src={url}
                className="w-full h-full border-0 rounded-lg shadow-md"
                title="Offer Preview"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default PreviewModal
