import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { FilterIdentifier } from 'models/saved_view'
import { filterStoreRepo } from 'components/Filters/FilterStore'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { REBATE_OFFERS_COLUMNS } from './rebateOffersColumnInfo'
import apiService from 'services/api'
import { QuickValueFilter } from '../../components/Filters/fields/QuickFilter'

export function RebateOffersFilterset(props: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const rebateOffersFilterStore = filterStoreRepo.getStore(
    props.filterIdentifier
  )

  const rebateFilterHelper = createFilterColumnHelper({
    columns: REBATE_OFFERS_COLUMNS,
  })
  const { data: productsOptions } = api.useGetProductsOptions()

  const stateOptions = [
    { label: 'Published', value: 'PUBLISHED' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Deleted', value: 'DELETED' },
  ]

  return (
    <FilterModal
      store={rebateOffersFilterStore}
      identifier={props.filterIdentifier}
    >
      <FilterGroup title="Quick filters" fontStyle="italic">
        <QuickValueFilter
          {...rebateFilterHelper.retrieveProps('state')}
          title="Offer State"
          valueLabel="Deleted"
          predefinedValue={[{ label: 'Deleted', value: 'DELETED' }]}
          filterStore={rebateOffersFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Offer Details">
        <SelectFilter
          {...rebateFilterHelper.retrieveProps('state')}
          options={stateOptions}
          filterStore={rebateOffersFilterStore}
        />
        <SelectFilter
          {...rebateFilterHelper.retrieveProps('offer_type')}
          options={[
            { label: 'First Time', value: 'FIRST_OFFER' },
            { label: 'Time Limited', value: 'TIME_OFFER' },
          ]}
          filterStore={rebateOffersFilterStore}
        />
        <SelectFilter
          {...rebateFilterHelper.retrieveProps('products')}
          options={productsOptions as any}
          filterStore={rebateOffersFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Claims">
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('total_enrollments')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('total_claims')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('pending_validation_claims')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('invalid_claims')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('paid_claims')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('unpaid_valid_claims')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('total_validated_units')}
          store={rebateOffersFilterStore}
        />
        <RangeFilter
          {...rebateFilterHelper.retrieveProps('total_validated_payout')}
          store={rebateOffersFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Dates">
        <DateRangeFilter
          {...rebateFilterHelper.retrieveProps('valid_from')}
          filterStore={rebateOffersFilterStore}
        />
        <DateRangeFilter
          {...rebateFilterHelper.retrieveProps('valid_to')}
          filterStore={rebateOffersFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
