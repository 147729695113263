import { AccessorKeyColumnDef, ColumnHelper } from '@tanstack/react-table'
import { Campaign } from 'models/campaign'
import { CompanyDistributor } from 'models/companies'
import { Deal } from 'models/deals'
import { Preferences } from 'models/preferences'
import { Tag } from 'models/tags'
import { DataTableColumnSort } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import {
  AccountOwnerCell,
  CampaignsCell,
  DistributorsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../../FbUI/StagePill'
import { COMMON_COLUMNS } from '../CommonColumnsInfo'
import { createTableColumnHelper } from '../commonColumnHelpers'

interface RequiredFields {
  contact_count?: number
  note_count?: number
  deal_count?: number
  campaign_attributed_deal_count?: number
  taglist?: Tag[] | null
  distributors?: CompanyDistributor[]
  campaigns?: Campaign[] | null
  deals?: Deal[] | null
}

export function getCommonContactCompanyColumns<
  T extends RequiredFields | { chain_proxy: RequiredFields },
>(
  baseColumnHelper: ColumnHelper<T>,
  sorting?: DataTableColumnSort[],
  preferences?: Preferences,
  inCampaigns?: boolean,
  accessor?: string
) {
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const commonColumnHelper = createTableColumnHelper({
    columns: COMMON_COLUMNS,
    tooltips,
  })
  // NOTE: magic trick to get the correct type
  const columnHelper = baseColumnHelper as ColumnHelper<any>
  const getAccessor = (key: keyof RequiredFields) => {
    return accessor ? `${accessor}.${key}` : key
  }
  const getValueWithAccessor = (info: any) => {
    return accessor ? info.row.original[accessor] : info.row.original
  }
  const cols: any[] = [
    columnHelper.accessor(getAccessor('campaigns'), {
      ...commonColumnHelper.retrieveColumn('campaigns'),
      cell: (info) => {
        return <CampaignsCell campaigns={info.getValue()} />
      },
      size: 260,
    }),
    columnHelper.display({
      ...commonColumnHelper.retrieveColumn('sales_stages'),
      size: 260,
      cell: (info) => {
        return (
          <SaleStagesCellFromDeals
            deals={getValueWithAccessor(info)?.deals}
            sortAsc={
              sorting?.length && sorting[0].id === 'sales_stages'
                ? !sorting[0].desc
                : undefined
            }
          />
        )
      },
    }),
    columnHelper.display({
      ...commonColumnHelper.retrieveColumn('account_owners'),
      size: 260,
      cell: (info) => {
        const deals = getValueWithAccessor(info)?.deals
        return <AccountOwnerCell deals={deals} />
      },
    }),
    columnHelper.accessor(getAccessor('taglist'), {
      ...commonColumnHelper.retrieveColumn('taglist'),
      size: 260,
      cell: (info) => {
        const taglist = info.getValue()
        return <TaglistCell taglist={taglist} />
      },
    }),
    columnHelper.accessor(getAccessor('contact_count'), {
      ...commonColumnHelper.retrieveColumn('contact_count', {
        meta: {
          rightAlign: true,
        },
      }),
      size: 110,
      cell: (info) => {
        return info.getValue() || '-'
      },
    }),
    columnHelper.accessor(getAccessor('note_count'), {
      ...commonColumnHelper.retrieveColumn('note_count', {
        meta: {
          rightAlign: true,
        },
      }),
      size: 110,
      cell: (info) => {
        return info.getValue() || '-'
      },
    }),
    columnHelper.accessor(getAccessor('deal_count'), {
      ...commonColumnHelper.retrieveColumn('deal_count', {
        meta: {
          rightAlign: true,
        },
      }),
      size: 110,
      cell: (info) => {
        return info.getValue() || '-'
      },
    }),
    columnHelper.accessor(getAccessor('distributors'), {
      ...commonColumnHelper.retrieveColumn('distributors'),
      size: 260,
      cell: (info) => {
        return (
          <DistributorsCell
            distributors={getValueWithAccessor(info)?.distributors}
          />
        )
      },
    }),
  ]

  if (inCampaigns) {
    cols.push(
      columnHelper.accessor('campaign_attributed_deal_count', {
        ...commonColumnHelper.retrieveColumn('campaign_attributed_deal_count', {
          meta: {
            rightAlign: true,
          },
        }),
        size: 110,
        cell: (info) => {
          return info.row.original.campaign_attributed_deal_count || '-'
        },
      })
    )
  }

  return cols as AccessorKeyColumnDef<T, any>[] // NOTE: yet another magic trick
}
