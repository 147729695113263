import { GooglePlaceDoorBase } from 'models/google_place_door'
import { DataTypeColumn } from '../../Common/commonColumnHelpers'

const GOOGLE_PLACE_DOOR_BASE_COLUMNS = {
  name: {
    header: '{datatypeName} Name',
    group: 'Opportunity Attributes',
  },
  place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
  },
  address: {
    header: 'Address',
    group: 'Opportunity Attributes',
  },
  city: {
    header: 'City',
    group: 'Opportunity Attributes',
  },
  state: {
    header: 'State',
    group: 'Opportunity Attributes',
  },
  country: {
    header: 'Country',
    group: 'Opportunity Attributes',
  },
  latitude: {
    header: 'Latitude',
    group: 'Opportunity Attributes',
  },
  longitude: {
    header: 'Longitude',
    group: 'Opportunity Attributes',
  },
  phone: {
    header: 'Phone',
    group: 'Opportunity Attributes',
  },
  website: {
    header: 'Website',
    group: 'Opportunity Attributes',
  },
  reviews_avg: {
    header: 'Average Rating',
    group: 'Opportunity Attributes',
  },
  reviews_cnt: {
    header: 'Total Reviews',
    group: 'Opportunity Attributes',
  },
  reviews_per_score: {
    header: 'Reviews Per Score',
    group: 'Opportunity Attributes',
  },
  open_hours: {
    header: 'Open Hours',
    group: 'Opportunity Attributes',
  },
  closed_status: {
    header: 'Closed Status',
    group: 'Opportunity Attributes',
  },
  store_type: {
    header: 'Store Type',
    group: 'Opportunity Attributes',
  },
} satisfies Partial<Record<keyof GooglePlaceDoorBase, DataTypeColumn>>

export const getGooglePlaceDoorBaseColumns = (datatypeName: string) => {
  return Object.entries(GOOGLE_PLACE_DOOR_BASE_COLUMNS).reduce(
    (acc, [key, value]) => {
      acc[key as keyof GooglePlaceDoorBase] = {
        ...value,
        header: value.header.replace('{datatypeName}', datatypeName),
      }
      return acc
    },
    {} as Record<keyof GooglePlaceDoorBase, DataTypeColumn>
  )
}
