const OFFERINGS_MAP = {
  has_shopping: 'Shopping',
  has_prepared_foods: 'Prepared Foods',
  has_quick_bites: 'Quick Bites',
  has_salad_bar: 'Salad Bar',
  has_beer: 'Beer',
  has_wine: 'Wine',
  has_liquor: 'Liquor',
}

export const OFFERINGS_OPTIONS = Object.entries(OFFERINGS_MAP).map(
  ([value, label]) => ({
    value,
    label,
  })
)
