import apiService from 'services/api'
import * as S from './styles'

function Privacy() {
  const api = apiService()

  const { data } = api.useGetTextContent()

  return (
    <S.Page>
      <S.Container
        className="px-5 py-10 prose prose-a:text-[#087443] hover:prose-a:text-[#83B9A1] visited:prose-a:text-[#002414]"
        dangerouslySetInnerHTML={{ __html: data?.firstbite_privacy_policy }}
      ></S.Container>
    </S.Page>
  )
}

export default Privacy
