import { DECommonTable } from 'components/DataExpansionComponents/Common/CommonTable/CommonTable'
import { DataTableProvider } from 'components/DataTable'
import { ContactResponse } from 'models/contacts'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { dataTableSortingStoreRepo } from '../../../../DataTable/DataTableSorting/DataTableSortingStore'
import { IColumnVisibility } from '../../../../DataTable/types'
import { useLodgingTableCols } from './LodgingTableColumns'
import { FilterIdentifier } from 'models/saved_view'
import { LodgingFilterset } from './LodgingFilterset'
import LodgingExportModal from 'components/Modals/ExportModal/LodgingExportModal'
import { StandardSummaryResponse } from 'models/paginated-response'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'note_count',
  'contact_count',
  'deal_count',
  'name',
  'domain',
  'reviews_avg',
  'reviews_cnt',
  'doors_cnt',
]

export interface LodgingTableProps {
  setTotalRowsCount?: (count: StandardSummaryResponse) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  hideCreateCompanyButton?: boolean
  AfterSearchComponent?: React.ReactNode
}

function LodgingTableComponent(props: LodgingTableProps) {
  const api = apiService()
  const { tableKey, ...rest } = props
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const columns = useLodgingTableCols({
    sorting,
    inCampaigns: props.baseFilters?.campaigns,
  })
  return (
    <DECommonTable
      {...rest}
      tableKey={tableKey}
      columns={columns}
      sortableFields={sortableFields}
      filterIdentifier="LODGING_TABLE"
      apiGetListAction={api.getLodgingList}
      apiGetSummaryAction={api.getLodgingSummary}
      companyTypeSlug="lodging-hospitality"
      tableTypeSlug="lodging-hospitality"
      searchPlaceholder="Search lodging by name"
      FilterSet={LodgingFilterset}
      ExportModal={LodgingExportModal}
    />
  )
}

export function LodgingTable(
  props: LodgingTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      savedPresetsIdentifier="LODGING_TABLE"
      preferredGroupOrder={['Opportunity Attributes', 'Campaigns and Deals']}
    >
      <DataTableProvider tableKey={tableKey}>
        <LodgingTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
