import { useState } from 'react'
import { formatInteger } from '../../utils/formatting'
import { CollapsibleSection } from '../UI/CollapsibleSection/CollapsibleSection'
import { LODGING_DOOR_TABLE_KEY } from 'constants/tableQueryKeys'
import { LodgingDoorTable } from 'components/DataExpansionComponents/GooglePlaces/LodgingDoors/LodgingDoorsTable/LodgingDoorsTable'

const LodgingDoorsTableSection = ({
  forChainProxyId,
}: {
  forChainProxyId: number
}) => {
  const [lodgingCount, setLodgingCount] = useState<number>(0)
  const TABLE_KEY = `${LODGING_DOOR_TABLE_KEY}-${forChainProxyId}`

  return (
    <>
      <CollapsibleSection
        forceClose={lodgingCount < 1}
        summary={
          <div className={'flex justify-between items-center w-full'}>
            <div>
              Doors{' '}
              <span className={'font-thin'}>
                ({formatInteger(lodgingCount)})
              </span>
            </div>
          </div>
        }
      >
        <LodgingDoorTable
          tableKey={TABLE_KEY}
          setTotalRowsCount={(count) => setLodgingCount(count?.count ?? 0)}
          hideCreateCompanyButton
          baseFilters={{ chain_proxy_id: forChainProxyId }}
        />
      </CollapsibleSection>
    </>
  )
}

export default LodgingDoorsTableSection
