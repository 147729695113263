import { createColumnHelper } from '@tanstack/react-table'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { Anchor } from 'components/UI/Anchor'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import { integerFormatterOrFallback } from 'utils/formatting'
import { DataTableColumnSort } from '../../../../DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from '../../../../FbUI/FbLink'
import {
  createTableColumnHelper,
  DataTypeColumn,
} from 'components/DataExpansionComponents/Common/commonColumnHelpers'

import {
  AllGooglePlacesDoorProxies,
  AllGooglePlacesDoorsAccessors,
  GooglePlaceDoorBase,
} from 'models/google_place_door'

export const useGooglePlacesDoorBaseTableCols = ({
  sorting,
  inCampaigns,
  accesor,
  tableColumns,
  linkPrefix,
}: {
  sorting?: DataTableColumnSort[]
  inCampaigns?: boolean
  accesor: AllGooglePlacesDoorsAccessors
  tableColumns: Record<keyof GooglePlaceDoorBase, DataTypeColumn>
  linkPrefix: string
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const featureFlag = featureFlagService()
  const columnHelper = createColumnHelper<AllGooglePlacesDoorProxies>()
  const googlePlaceColumnHelper = createTableColumnHelper({
    columns: tableColumns,
    tooltips,
  })

  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor(`${accesor}.name`, {
        ...googlePlaceColumnHelper.retrieveColumn('name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 250,
        cell: (info) => {
          const name = info.getValue()
          const link = `/${linkPrefix}/${info.row.original.lodging?.id}`
          return (
            <FbLink to={link} target="_blank">
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor(`${accesor}.place_url`, {
        ...googlePlaceColumnHelper.retrieveColumn('place_url'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              Google Place
            </Anchor>
          )
        },
      }),
      columnHelper.accessor(`${accesor}.address`, {
        ...googlePlaceColumnHelper.retrieveColumn('address'),
        size: 250,
      }),
      columnHelper.accessor(`${accesor}.city`, {
        ...googlePlaceColumnHelper.retrieveColumn('city'),
        size: 150,
      }),
      columnHelper.accessor(`${accesor}.state`, {
        ...googlePlaceColumnHelper.retrieveColumn('state'),
        size: 200,
      }),

      columnHelper.accessor(`${accesor}.country`, {
        ...googlePlaceColumnHelper.retrieveColumn('country'),
        size: 150,
      }),
      columnHelper.accessor(`${accesor}.reviews_avg`, {
        ...googlePlaceColumnHelper.retrieveColumn('reviews_avg'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(`${accesor}.reviews_cnt`, {
        ...googlePlaceColumnHelper.retrieveColumn('reviews_cnt'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(`${accesor}.phone`, {
        ...googlePlaceColumnHelper.retrieveColumn('phone'),
        size: 150,
      }),
      columnHelper.accessor(`${accesor}.website`, {
        ...googlePlaceColumnHelper.retrieveColumn('website'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              Visit
            </Anchor>
          )
        },
      }),
      ...getCommonContactCompanyColumns(
        columnHelper,
        sorting,
        preferences,
        inCampaigns,
        'chain_proxy'
      ),
    ]
    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [
    columnHelper,
    sorting,
    featureFlag,
    googlePlaceColumnHelper,
    preferences,
    inCampaigns,
  ])
  return columns
}
