import { useEffect } from 'react'
import {
  ContactRequestStep,
  useContactRequestContext,
} from '../ContactRequestContext'
import { LoadingStep } from './LoadingStep'
import { InProgressStep } from '../InProgressStep'
import { FoundContactsStep } from '../FoundContactsStep'
import { ConfirmStep } from '../ConfirmStep'
import { SuccessStep } from '../SuccessStep'
import { toast } from 'react-toastify'

export function StepManager() {
  const { step, loading, checkStatus } = useContactRequestContext()

  // Check status when the component mounts
  useEffect(() => {
    checkStatus().catch((err) => {
      toast.error(`Error checking contact status: ${err}`)
    })
  }, [])

  // Show loading state while checking status
  if (loading) {
    // Determine the current step for the loading indicator
    let currentStep = 1 // Start with 1 for initial loading
    let stepTitle = 'Checking Status'

    switch (step) {
      case ContactRequestStep.IN_PROGRESS:
        currentStep = 1
        stepTitle = 'Companies with In-Progress Requests'
        break
      case ContactRequestStep.FOUND_CONTACTS:
        currentStep = 2
        stepTitle = 'Companies with Existing Contacts'
        break
      case ContactRequestStep.CONFIRM:
        currentStep = 3
        stepTitle = 'Request Contacts'
        break
      case ContactRequestStep.SUCCESS:
        currentStep = 3
        stepTitle = 'Success'
        break
    }

    return <LoadingStep title={stepTitle} currentStep={currentStep} />
  }

  switch (step) {
    case ContactRequestStep.IN_PROGRESS:
      return <InProgressStep />
    case ContactRequestStep.FOUND_CONTACTS:
      return <FoundContactsStep />
    case ContactRequestStep.CONFIRM:
      return <ConfirmStep />
    case ContactRequestStep.SUCCESS:
      return <SuccessStep />
    default:
      return <ConfirmStep />
  }
}
