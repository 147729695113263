import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnVisibility } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import EmailViewModal from 'components/Modals/EmailViewModal'
import { ContactResponse } from 'models/contacts'
import { EmailMessage } from 'models/emailIntegration'
import { useEffect, useMemo, useState } from 'react'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { FilterIdentifier } from '../../../models/saved_view'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { TableSearch } from '../../Filters/TableSearch'
import * as S from '../CommonTable.styles'
import { useEmailsTableColumns } from './useEmailsTableColumns'
import EmailsFilterset from './EmailsFilterset'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { BiExpandAlt } from 'react-icons/bi'

type SortableFields = (keyof ContactResponse | string)[]

export type ContactsTableRef = {
  refetch: () => void
}

const sortableFields: SortableFields = [
  'outbound',
  'subject',
  'snippet',
  'date',
  'related_contacts',
]

const PAGE_SIZE = 100

interface EmailsTableProps {
  setTotalRowsCount?: (count: number) => void
  handleContactUpdated?: () => void
  disableUrlPagination?: boolean
  forChainProxyId?: number
  disableExportButton?: boolean
  tableKey: string
  filterIdentifierModifier?: string
  baseFilters?: Record<string, any>
}

function EmailsTableComponent(props: EmailsTableProps) {
  const api = apiService()
  const IDENTIFIER = 'EMAILS_TABLE' + (props.filterIdentifierModifier ?? '')
  const emailsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )
  const { page, setPage } = emailsFilterStore()
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  // Email view modal state
  const [selectedEmail, setSelectedEmail] = useState<EmailMessage>()

  const handleViewEmail = (email: EmailMessage) => {
    setSelectedEmail(email)
  }

  const handleCloseEmailModal = () => {
    setSelectedEmail(undefined)
  }

  const filtersAsParams = {
    ...useFilterParams(emailsFilterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(() => {
    const defaultParams = {
      ...filtersAsParams,
      ...sortParams,
    }
    if (props.forChainProxyId) {
      return {
        ...defaultParams,
        chain_proxy_id: props.forChainProxyId,
      }
    }
    return defaultParams
  }, [filtersAsParams, sortParams])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: page,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      return await api.emailIntegration.getEmailMessageList(
        { ...filterAndSortParams, page: page },
        signal
      )
    },
  })
  // END DATA FETCHING

  // Base columns
  const baseColumns = useEmailsTableColumns()
  const columnHelper = createColumnHelper<EmailMessage>()

  // Add action column
  const columns = useMemo(() => {
    return [
      ...baseColumns,
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        size: 80,
        meta: {
          isEditable: false,
          isLastColumn: true,
          rightAlign: true,
        },
        cell: (info) => {
          const email = info.row.original
          return (
            <ActionButtons
              className={'justify-end'}
              onSee={() => handleViewEmail(email)}
              seeIcon={BiExpandAlt}
            />
          )
        },
      }),
    ]
  }, [baseColumns])

  useEffect(() => {
    clearSelectedRows()
    if (data?.count) {
      props.setTotalRowsCount?.(data.count)
      setTotalRowsInBackend(data.count)
    }
  }, [data])

  return (
    <DataTableContainer>
      <DataTable
        key={props.tableKey}
        tableHeader={
          <S.SearchContainer>
            <S.SearchContainerFilters>
              <S.TableSearchContainer>
                <TableSearch
                  filterStore={emailsFilterStore}
                  searchPlaceholder="Search by contact name or email"
                />
              </S.TableSearchContainer>
              <S.TableButtonsContainer>
                <EmailsFilterset
                  filterIdentifier={IDENTIFIER as FilterIdentifier}
                />
                <ColumnSelectorRecipient tableKey={props.tableKey} />
              </S.TableButtonsContainer>
            </S.SearchContainerFilters>
            <FilterChips
              identifier={'EMAILS_TABLE'}
              store={emailsFilterStore}
              clearAllButton
              showActive
            />
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={false}
        isPaginationEnabled={true}
        paginationOptions={{
          totalRows: data?.count ?? 0,
          pageSize: PAGE_SIZE,
          setPage: setPage,
          page: page,
          isPaginationLoading: isFetching,
        }}
        selectAllText={'Select Contacts'}
      />

      {/* Email View Modal */}
      {selectedEmail && (
        <EmailViewModal
          show={!!selectedEmail}
          handleClose={handleCloseEmailModal}
          partialEmailData={selectedEmail!}
        />
      )}
    </DataTableContainer>
  )
}

EmailsTableComponent.displayName = 'EmailsTable'

export function EmailsTable(
  props: EmailsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={['Email']}
    >
      <DataTableProvider tableKey={tableKey}>
        <EmailsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
