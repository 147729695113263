import { DECommonTable } from 'components/DataExpansionComponents/Common/CommonTable/CommonTable'
import { DataTableProvider } from 'components/DataTable'
import NursingHomeExportModal from 'components/Modals/ExportModal/NursingHomeExportModal'
import { ContactResponse } from 'models/contacts'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { FilterIdentifier } from '../../../../models/saved_view'
import { dataTableSortingStoreRepo } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { IColumnVisibility } from '../../../DataTable/types'
import { NursingHomesFilterset } from './NursingHomesFilterset'
import { useNursingHomesTableCols } from './NursingHomesTableColumns'
import { StandardSummaryResponse } from 'models/paginated-response'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'provider_name',
  'number_of_certified_beds',
  'average_number_of_residents_per_day',
  'overall_rating',
  'health_inspection_rating',
  'qm_rating',
  'staffing_rating',
  'address',
  'city',
  'state',
  'zip_code',
  'projected_meals_per_day',
  'ownership_type',
  'provider_type',
  'continuing_care_retirement_community',
]

interface NursingHomesTableProps {
  setTotalRowsCount?: (count: StandardSummaryResponse) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  hideCreateCompanyButton?: boolean
}

function NursingHomesTableComponent(props: NursingHomesTableProps) {
  const api = apiService()
  const { tableKey, ...rest } = props
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const columns = useNursingHomesTableCols({
    sorting,
    inCampaigns: props.baseFilters?.campaigns,
  })

  return (
    <DECommonTable
      {...rest}
      tableKey={tableKey}
      columns={columns}
      sortableFields={sortableFields}
      filterIdentifier="NURSING_HOME_TABLE"
      apiGetListAction={api.getNursingHomesList}
      apiGetSummaryAction={api.getNursingHomesSummary}
      companyTypeSlug="healthcare-nursinghomes"
      tableTypeSlug="healthcare-nursinghomes"
      searchPlaceholder="Search nursing homes by name"
      FilterSet={NursingHomesFilterset}
      ExportModal={NursingHomeExportModal}
    />
  )
}

export function NursingHomesTable(
  props: NursingHomesTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      savedPresetsIdentifier="NURSING_HOME_TABLE"
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Dining Services',
        'Nursing Home Insights',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <NursingHomesTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
