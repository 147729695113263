import { useMemo } from 'react'
import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import {
  SelectFilter,
  SelectFilterOption,
} from 'components/Filters/fields/SelectFilter'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'
import { FilterIdentifier } from '../../../models/saved_view'
import apiService from 'services/api'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { CONTACT_REQUESTS_COLUMNS } from './contactRequestsColumnInfo'
import { ContactRequestJobStatus } from 'models/contact_request'

interface ContactRequestsFiltersetProps {
  filterIdentifier: FilterIdentifier
}

export default function ContactRequestsFilterset(
  props: ContactRequestsFiltersetProps
) {
  const api = apiService()
  const filtersStore = filterStoreRepo.getStore(props.filterIdentifier)

  // Create filter column helper to ensure consistent filter keys
  const contactRequestsFilterHelper = createFilterColumnHelper({
    columns: CONTACT_REQUESTS_COLUMNS,
  })

  // Fetch users for filter dropdown
  const { data: userOptions } = api.useGetUserOptions({
    onlyActive: true,
    withNoneOption: true,
  })

  // Fetch campaigns options
  const { data: campaignOptions } = api.useGetCampaignsOptions?.() || {
    data: [],
  }

  // Fetch company categories and subcategories for the Type filter
  const { data: companyCatAndSub } = api.useGetCompanyCategoriesAndSub?.() || {
    data: [],
  }

  // Create subcategory options similar to ContactsFilterset
  const subCatOptions: SelectFilterOption[] = useMemo(() => {
    if (!companyCatAndSub) return []

    return companyCatAndSub.flatMap((cat) =>
      cat.sub_categories.map((subCat) => ({
        label: subCat.name,
        value: subCat.id.toString(),
      }))
    )
  }, [companyCatAndSub])

  // Define job status options matching the backend ContactRequestJobStatus enum and serializer logic
  const jobStatusOptions: SelectFilterOption[] = [
    {
      label: 'Submitted',
      value: ContactRequestJobStatus.SUBMITTED.toString(),
    },
    {
      label: 'Started',
      value: ContactRequestJobStatus.STARTED.toString(),
      // Started status includes: Submitted (with contacts found), Processing, or Ready for Review
    },
    {
      label: 'Completed',
      value: ContactRequestJobStatus.ACCEPTED.toString(),
      // Accepted status represents completed requests
    },
    {
      label: 'Cancelled',
      value: ContactRequestJobStatus.CANCELLED.toString(),
    },
  ]

  // Convert user options to string values for compatibility
  const formattedUserOptions: SelectFilterOption[] = useMemo(() => {
    if (!userOptions) return []

    return userOptions.map((user: { label: string; value: number }) => ({
      label: user.label,
      value: user.value.toString(),
    }))
  }, [userOptions])

  // Convert campaign options to string values for compatibility
  const formattedCampaignOptions: SelectFilterOption[] = useMemo(() => {
    if (!campaignOptions) return []

    return campaignOptions.map(
      (campaign: { label: string; value: string | number }) => ({
        label: campaign.label,
        value:
          typeof campaign.value === 'number'
            ? campaign.value.toString()
            : campaign.value,
      })
    )
  }, [campaignOptions])

  return (
    <FilterModal store={filtersStore} identifier={props.filterIdentifier}>
      {/* Campaign Details Group */}
      <FilterGroup title="Campaign Details">
        <SelectFilter
          {...contactRequestsFilterHelper.retrieveProps('sub_category')}
          filterKey="sub_category"
          filterStore={filtersStore}
          options={subCatOptions.length > 0 ? subCatOptions : []}
        />
        <SelectFilter
          {...contactRequestsFilterHelper.retrieveProps('campaign')}
          filterStore={filtersStore}
          options={formattedCampaignOptions}
        />
      </FilterGroup>

      {/* Request Details Group */}
      <FilterGroup title="Request Details">
        <SelectFilter
          {...contactRequestsFilterHelper.retrieveProps('user')}
          filterStore={filtersStore}
          options={formattedUserOptions}
        />
        <RangeFilter
          {...contactRequestsFilterHelper.retrieveProps(
            'unique_companies_count'
          )}
          store={filtersStore}
        />
        <RangeFilter
          {...contactRequestsFilterHelper.retrieveProps('credits_used')}
          store={filtersStore}
        />
        <SelectFilter
          {...contactRequestsFilterHelper.retrieveProps('status')}
          filterStore={filtersStore}
          options={jobStatusOptions}
        />
        <DateRangeFilter
          {...contactRequestsFilterHelper.retrieveProps('created')}
          filterStore={filtersStore}
        />
        <DateRangeFilter
          {...contactRequestsFilterHelper.retrieveProps('modified')}
          filterStore={filtersStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
