import React, { useState } from 'react'
import { Dialog, DialogContent } from '../../UI/Modal/ModalPrimitive'
import FbButton, { FbButtonVariants } from '../../FbUI/FbButton'
import { ContactRequestProvider } from './ContactRequestContext'
import { StepManager } from './components/StepManager'

interface ContactRequestJobFromCampaignModalProps {
  campaignId: number
  buttonText?: string
  variant?: FbButtonVariants
  onContactRequested?: () => void
}

export const ContactRequestJobFromCampaignModal: React.FC<
  ContactRequestJobFromCampaignModalProps
> = ({
  campaignId,
  buttonText = 'Request Contacts',
  variant = 'primary',
  onContactRequested,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <FbButton variant={variant} onClick={handleOpen}>
        {buttonText}
      </FbButton>

      <Dialog open={open} onOpenChange={(open) => !open && handleClose()}>
        <DialogContent id={'fb-modal-content'} size="2xl">
          <ContactRequestProvider
            campaignId={campaignId}
            handleClose={handleClose}
            onContactRequested={onContactRequested}
          >
            <StepManager />
          </ContactRequestProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ContactRequestJobFromCampaignModal
