import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { JobActionButtons } from 'components/Buttons/ActionButtons'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnVisibility } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { TableSearch } from 'components/Filters/TableSearch'
import { ContactRequestJobDetailsModal } from 'components/Modals/ContactRequestJobDetailsModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import { ContactRequestJobResponse } from 'models/contact_request'
import { useMemo, useState, useEffect } from 'react'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { featureFlagService } from 'utils/featureFlagService'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import * as S from '../CommonTable.styles'
import { FilterIdentifier } from '../../../models/saved_view'
import ContactRequestsFilterset from './ContactRequestsFilterset'
import { useContactRequestsTableColumns } from './useContactRequestsTableColumns'

const PAGE_SIZE = 100

type SortableFields = (keyof ContactRequestJobResponse | string)[]

const sortableFields: SortableFields = [
  'created',
  'modified',
  'status',
  'sub_category',
  'user',
  'campaign',
  'unique_companies_count',
  'credits_used',
]

interface ContactRequestsTableProps {
  campaignId?: number
  tableKey: string
  filterIdentifierModifier?: string
  defaultColumnVisibility?: IColumnVisibility<any>
  setTotalCreditsUsed?: React.Dispatch<React.SetStateAction<number | null>>
}

function ContactRequestsTableComponent(props: ContactRequestsTableProps) {
  const api = apiService()
  const featureFlag = featureFlagService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null)

  // Filter store setup
  const IDENTIFIER =
    'CONTACT_REQUESTS_TABLE' + (props.filterIdentifierModifier ?? '')
  const contactRequestsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )
  const { page, setPage } = contactRequestsFilterStore()

  const {
    methods: { clearSelectedRows, setTotalRowsInBackend },
    state: { totalSelectedRows },
  } = useDataTableContext()

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()

  // Filter params
  const filtersAsParams = useFilterParams(contactRequestsFilterStore)

  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}
    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }
    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filtersAsParams,
      ...sortParams,
      campaign: props.campaignId,
    }),
    [filtersAsParams, sortParams, props.campaignId]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: page,
  })

  const { data, isFetching } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      const response = await api.fetchContactRequestJobs(
        {
          ...filterAndSortParams,
          limit: PAGE_SIZE,
          page: page,
        },
        signal
      )
      return response
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
  })

  // Update total rows count and total credits used when data changes
  useEffect(() => {
    if (data?.count) {
      setTotalRowsInBackend(data.count)
    }
    // Update total credits used if the setter is provided
    if (props.setTotalCreditsUsed && data?.total_credits_used !== undefined) {
      props.setTotalCreditsUsed(data.total_credits_used)
    }
  }, [data, setTotalRowsInBackend, props.setTotalCreditsUsed])

  const handleViewJobDetails = (jobId: number) => {
    setSelectedJobId(jobId)
    setShowModal(true)
  }

  // Get columns from the hook instead of defining them inline
  const baseColumns = useContactRequestsTableColumns()

  // Add actions column and filter columns based on feature flags
  const columns: ColumnDef<ContactRequestJobResponse, any>[] = useMemo(() => {
    let cols = [
      ...baseColumns,
      {
        id: 'actions',
        meta: {
          isLastColumn: true,
          headerDisableOrdering: true,
        },
        header: () => <div className="ml-[-8px]">Actions</div>,
        size: 80,
        cell: (info: CellContext<ContactRequestJobResponse, unknown>) => {
          return (
            <JobActionButtons
              className={'justify-center pr-0'}
              onView={() => handleViewJobDetails(info.row.original.id)}
            />
          )
        },
      },
    ]

    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }

    return cols
  }, [baseColumns, handleViewJobDetails, featureFlag.enableCampaigns])

  return (
    <>
      <DataTableContainer>
        <DataTable
          tableKey={props.tableKey}
          loading={isFetching}
          stickyLastColumn={true}
          data={data?.results ?? []}
          columns={columns}
          virtualizeRows={false}
          isPaginationEnabled={true}
          sortableFields={sortableFields}
          defaultSort={[{ id: 'created' as any, desc: true }]}
          tableHeader={
            <S.SearchContainer>
              <S.SearchContainerFilters>
                {!props.campaignId && (
                  <S.TableSearchContainer>
                    <TableSearch
                      filterStore={contactRequestsFilterStore}
                      searchPlaceholder="Search by campaign"
                    />
                  </S.TableSearchContainer>
                )}
                <S.TableButtonsContainer>
                  <ContactRequestsFilterset
                    filterIdentifier={IDENTIFIER as FilterIdentifier}
                  />
                  <ColumnSelectorRecipient tableKey={props.tableKey} />
                  <TableButtonAction
                    items={[]}
                    disabled={!totalSelectedRows}
                    selectedRowsCount={totalSelectedRows ?? 0}
                  />
                </S.TableButtonsContainer>
              </S.SearchContainerFilters>
              <FilterChips
                identifier={IDENTIFIER as FilterIdentifier}
                store={contactRequestsFilterStore}
                clearAllButton
                showActive
              />
            </S.SearchContainer>
          }
          paginationOptions={{
            totalRows: data?.count ?? 0,
            pageSize: PAGE_SIZE,
            setPage: setPage,
            page: page,
            isPaginationLoading: isFetching,
          }}
        />
      </DataTableContainer>
      <ContactRequestJobDetailsModal
        open={showModal}
        onClose={() => setShowModal(false)}
        jobId={selectedJobId}
      />
    </>
  )
}

export function ContactRequestsTable(props: ContactRequestsTableProps) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={['Campaign Details', 'Request Details']}
    >
      <DataTableProvider tableKey={tableKey}>
        <ContactRequestsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
