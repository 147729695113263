import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import apiService from '../../services/api'
import { Modal } from '../UI/Modal/Modal'
import { ContactResponse } from 'models/contacts'
import CreatableSelect from 'components/FormUtils/CreatableSelect'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextInput } from 'components/FormUtilsV2/TextInput/text-input'
import { EditorRHF } from 'components/Editor/EditorRHF'

const formShape = z.object({
  to: z.string({ message: 'Please enter a valid email address' }).email(),
  subject: z.string().min(1),
  from_id: z.number({ message: 'Please select a from email' }),
  body: z.string().min(1),
})

type formValues = z.infer<typeof formShape>

export default function WriteEmailModal(props: {
  show: boolean
  handleClose: () => void
  onSubmitSuccess?: () => void
  contact: ContactResponse
}) {
  const api = apiService()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { data: emailIntegrationOptions } =
    api.emailIntegration.useGetEmailIntegrationOptions()

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      to: props.contact.email_preferred,
      subject: '',
      body: '',
    } as formValues,
    resolver: zodResolver(formShape),
    mode: 'all',
  })

  useMemo(() => {
    if (emailIntegrationOptions?.length === 1) {
      setValue('from_id', emailIntegrationOptions[0].value)
    }
  }, [emailIntegrationOptions])

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true)

    await api.emailIntegration.sendEmailMessage(values.from_id, {
      to: [
        {
          name: `${props.contact.first_name} ${props.contact.last_name}`,
          email: values.to,
        },
      ],
      subject: values.subject,
      body: values.body,
    })
    setIsSubmitting(false)
    props.onSubmitSuccess?.()
    props.handleClose()
  })
  return (
    <Modal
      size="lg"
      open={props.show}
      onOpenChange={(open) => !open && props.handleClose()}
      title={'Write Email'}
      onAccept={onSubmit}
      acceptButtonText={isSubmitting ? 'Sending...' : 'Send'}
      loading={isSubmitting}
      cancelButtonText={isSubmitting ? 'Cancel' : 'Close'}
      onCancel={props.handleClose}
    >
      <CreatableSelect
        label={'To'}
        control={control}
        name="to"
        placeholder="To email"
        disableCreate
        options={[
          {
            label: props.contact.email_preferred,
            value: props.contact.email_preferred,
          },
        ]}
        className={'flex-1 mt-0'}
        onCreateOption={() => Promise.resolve(null)}
      />
      <CreatableSelect
        label={'From'}
        control={control}
        name="from_id"
        placeholder="From email"
        disableCreate
        options={emailIntegrationOptions}
        className={'flex-1 mt-0'}
        onCreateOption={() => Promise.resolve(null)}
      />
      <TextInput
        control={control}
        name="subject"
        type="text"
        label="Subject"
        placeholder="Subject"
      />
      <EditorRHF
        name={'body'}
        className={'h-28'}
        control={control}
        label={'Email'}
        placeholder={'Email'}
      />
    </Modal>
  )
}
