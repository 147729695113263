import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import to from 'await-to-js'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { DataTable, DataTableProvider } from 'components/DataTable'
import dialogConfirm from 'components/dialogConfirm'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { DateCell } from 'components/Tables/CommonTableCells/DateCell'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../../components/DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../../components/DataTable/UI'
import FbButton, { IFbButtonProps } from '../../../components/FbUI/FbButton'
import { Modal } from '../../../components/UI/Modal/Modal'
import * as S from './styles'
import { EmailIntegration } from 'models/emailIntegration'
import { FiRefreshCw } from 'react-icons/fi'
import { Input } from 'components/FormUtilsV2/Input/input'
import CustomCheckbox from 'components/Checkbox/checkbox'

const PAGE_SIZE = 25

const sortableFields = ['email', 'modified', 'created']

const FBGoogleButton = (props: IFbButtonProps) => {
  return (
    <FbButton variant="secondary" {...props}>
      <div className="min-w-[20px] min-h-[20px] mr-2">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{ display: 'block' }}
        >
          <path
            fill="#EA4335"
            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
          ></path>
          <path
            fill="#4285F4"
            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
          ></path>
          <path
            fill="#FBBC05"
            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
          ></path>
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
          ></path>
          <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
      </div>
      <span className="whitespace-nowrap">Continue with Google</span>
    </FbButton>
  )
}

const FBOutlookButton = (props: IFbButtonProps) => {
  return (
    <FbButton variant="secondary" {...props}>
      <div className="min-w-[20px] min-h-[20px] mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
        >
          <title>MS-SymbolLockup</title>
          <rect x="1" y="1" width="9" height="9" fill="#f25022" />
          <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
          <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
          <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
        </svg>
      </div>
      <span className="whitespace-nowrap">Continue with Outlook</span>
    </FbButton>
  )
}

const tableKey = 'email-integration-table'
export function EmailIntegrationTableComponent() {
  const api = apiService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedEmailID, setSelectedEmailID] = useState<number>()
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [isShared, setIsShared] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const { sorting } = dataTableSortingStoreRepo.getStore(tableKey)()

  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const success = urlParams.get('success')
    if (success) {
      setShowSuccessModal(true)
      // Remove the success param from the URL
      urlParams.delete('success')
      window.history.replaceState({}, '', `${window.location.pathname}`)
    }
  }, [])

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    setSelectedEmailID(undefined)
  }

  const [page, setPage] = useState(1)

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey,
    filterParams: sortParams,
    page: page,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      const [err, data] = await to(
        api.emailIntegration.getEmailIntegrationList(
          {
            ...sortParams,
            limit: PAGE_SIZE,
            page: page,
          },
          signal
        )
      )

      if (err) {
        throw err
      }

      return data
    },
  })

  const onContinueButtonClick = async (provider: string) => {
    const [err, response] = await to(
      api.emailIntegration.getEmailIntegrationAuthUrl({
        provider,
        isShared,
      })
    )
    if (err) {
      toast.error('Error creating email')
      return
    }

    if (response?.auth_url) {
      // Redirect to the auth url
      window.location.href = response.auth_url
    }

    toast.success('Email integration requested')
    await refetch()
    hideModal()
  }

  const onSubmitUpdate = async () => {
    if (!selectedEmailID) return

    const [err] = await to(
      api.emailIntegration.updateEmailIntegration(selectedEmailID, {
        is_shared: isShared,
      })
    )
    if (err) {
      toast.error('Error updating email')
      return
    }

    toast.success('Email updated')
    await refetch()
    hideModal()
  }

  async function handleRowDelete(index: number) {
    const id = data?.results?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this email',
        'Delete Email'
      )
    ) {
      const [err] = await to(api.emailIntegration.deleteEmailIntegration(id))
      if (err) {
        toast.error('Error deleting email')
        return
      }
      toast.success('Email deleted')
      await refetch()
    }
  }

  function handleRowEdit(index: number) {
    setShowModal(true)
    const email = data?.results?.[index]
    if (email) {
      setSelectedEmailID(email.id)
      setIsShared(email.is_shared)
      setEmail(email.email)
    }
  }

  async function syncEmailIntegration(id: number) {
    const [err] = await to(api.emailIntegration.syncEmailIntegration(id))
    if (err) {
      toast.error('Error syncing email')
      return
    }
    toast.info(
      'Started syncing emails for this account. You will be notified when it is done.'
    )
    await refetch()
  }

  const columnHelper = createColumnHelper<EmailIntegration>()

  const descriptionText = `Email Integration allows you to send emails to your contacts directly from the platform.`

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <S.SettingsTableHeader>
            <S.TableDescriptionText>
              <OverflownTextTooltip
                tooltipText={descriptionText}
                maxLines={4}
                placement="bottom"
              >
                {descriptionText}
              </OverflownTextTooltip>
            </S.TableDescriptionText>
            <FbButton onClick={() => openModal()}>Add new email</FbButton>
          </S.SettingsTableHeader>
        }
        tableKey={tableKey}
        data={data?.results ?? []}
        columns={[
          columnHelper.accessor('email', {
            header: 'Email',
            size: 200,
          }),
          columnHelper.accessor('is_shared', {
            header: 'Shared',
            size: 150,
            cell: ({ row }) => {
              return row.original.is_shared ? 'Yes' : 'No'
            },
          }),
          columnHelper.accessor('status', {
            header: 'Status',
            size: 100,
            cell: (info) => {
              const value = info.getValue()
              const valueMap = {
                synced: 'Synced',
                syncing: 'Syncing',
                not_synced: 'Not Synced',
              }
              return (
                <span
                  className={`${
                    value === 'synced'
                      ? 'text-green-500'
                      : value === 'syncing'
                        ? 'text-yellow-500'
                        : 'text-red-500'
                  }`}
                >
                  {valueMap[value as keyof typeof valueMap]}
                </span>
              )
            },
          }),
          columnHelper.accessor('modified', {
            header: 'Modified',
            size: 100,
            cell: DateCell,
          }),
          columnHelper.accessor('created', {
            header: 'Created',
            size: 100,
            cell: DateCell,
          }),
          columnHelper.display({
            id: 'edit',
            header: 'Actions',
            meta: { rightAlign: true },
            cell: ({ row }) => {
              return (
                <div className="flex justify-end">
                  <FiRefreshCw
                    size={20}
                    color="#667085"
                    className="cursor-pointer mr-3 justify-end"
                    onClick={() => syncEmailIntegration(row.original.id)}
                  />
                  <div>
                    <ActionButtons
                      className="justify-end pr-0"
                      onDelete={() => handleRowDelete(row.index)}
                      onEdit={() => handleRowEdit(row.index)}
                    />
                  </div>
                </div>
              )
            },
            size: 100,
          }),
        ]}
        sortableFields={sortableFields}
        isPaginationEnabled={true}
        paginationOptions={{
          totalRows: data?.count ?? 0,
          pageSize: PAGE_SIZE,
          setPage: setPage,
          page: page,
          isPaginationLoading: isFetching,
        }}
        loading={isFetching}
      />
      <Modal
        open={showModal}
        title={selectedEmailID ? 'Edit Email' : 'Create Email'}
        description="Add a new email to the platform."
        onOpenChange={(open) => !open && hideModal()}
        onAccept={selectedEmailID ? onSubmitUpdate : undefined}
        acceptButtonText={selectedEmailID ? 'Update' : 'Create'}
        hideCancelButton={!selectedEmailID}
      >
        {selectedEmailID ? (
          <Input
            name="email"
            type="text"
            placeholder="Email"
            disabled={true}
            value={email}
          />
        ) : (
          <div className="flex flex-col gap-2 align-items-center mb-4">
            <FBGoogleButton
              className="w-min"
              onClick={() => onContinueButtonClick('google')}
            />
            <FBOutlookButton
              className="w-min"
              onClick={() => onContinueButtonClick('microsoft')}
            />
          </div>
        )}

        <div className="flex items-center">
          <CustomCheckbox
            id="is_shared"
            onChange={(e) => setIsShared(e.target.checked)}
            value={isShared ? 'true' : 'false'}
            checked={isShared ?? false}
          />
          <label htmlFor="is_shared" className="ml-2">
            Share email with team
          </label>
        </div>
      </Modal>
      <Modal
        open={showSuccessModal}
        title={'Success'}
        description="Email integrated successfully"
        onOpenChange={(open) => !open && setShowSuccessModal(false)}
        onAccept={() => setShowSuccessModal(false)}
        acceptButtonText={'Close'}
        hideCancelButton
      />
    </DataTableContainer>
  )
}

export function EmailIntegrationTable() {
  return (
    <ColumnsStoreProvider tableKey={tableKey}>
      <DataTableProvider tableKey={tableKey}>
        <EmailIntegrationTableComponent />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
