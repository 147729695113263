import { DataTableProvider } from 'components/DataTable'
import { IColumnSort, IColumnVisibility } from 'components/DataTable/types'
import { ContactResponse } from 'models/contacts'

import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { FilterIdentifier } from '../../../../models/saved_view'

import { DECommonTable } from 'components/DataExpansionComponents/Common/CommonTable/CommonTable'
import { K12DistrictExportModal } from 'components/Modals/ExportModal/K12DistrictExportModal'
import apiService from 'services/api'
import { dataTableSortingStoreRepo } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { K12Filterset } from './K12Filterset'
import { useK12TableColumns } from './K12TableColumns'
import { StandardSummaryResponse } from 'models/paginated-response'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'deal_count',
  'contact_count',
  'note_count',
  'sales_stages',
  'lea_name',
  'lcity',
  'lstate',
  'zipcode',
  'locale',
  'gslo',
  'gshi',
  'student_count',
  'teachers_count',
  'operational_schools',
  'revenue',
  'food_service_expenditures',
  'free_lunch',
  'reduced_lunch',
  'total_supplemented_lunch',
  'uncategorized_lunch',
  'free_lunch_perc',
  'reduced_lunch_perc',
  'total_supplemented_lunch_perc',
  'uncategorized_lunch_perc',
  'estimated_meals_per_day',
  'county',
]

interface K12TableProps {
  setTotalRowsCount?: (count: StandardSummaryResponse) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  hideCreateCompanyButton?: boolean
}

function K12TableComponent(props: K12TableProps) {
  const api = apiService()
  const { tableKey, ...rest } = props

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()

  const columns = useK12TableColumns({
    sorting,
    inCampaigns: props.baseFilters?.campaigns,
  })

  return (
    <DECommonTable
      {...rest}
      tableKey={tableKey}
      columns={columns}
      sortableFields={sortableFields}
      filterIdentifier="K12_TABLE"
      apiGetListAction={api.getK12DistrictsList}
      apiGetSummaryAction={api.getK12DistrictsSummary}
      companyTypeSlug="education-k-12"
      tableTypeSlug="education-k-12"
      defaultSort={[
        {
          id: 'k12district__estimated_meals_per_day',
          desc: true,
        } as IColumnSort<object>,
      ]}
      searchPlaceholder="Search schools by name"
      FilterSet={K12Filterset}
      ExportModal={K12DistrictExportModal}
    />
  )
}

export function K12Table(
  props: K12TableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      savedPresetsIdentifier="K12_TABLE"
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'School Insights',
        'Campaigns and Deals',
        'Dining Services',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <K12TableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
