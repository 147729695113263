import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { default as s } from 'styled-components'

import dayjs from 'dayjs'
import { Deal } from 'models/deals'
import React, { useMemo } from 'react'

import {
  formatInteger,
  formatTwoDecimal,
  formatUsd,
} from '../../../utils/formatting'
import { getCompanyUrl } from '../../../utils/getCompanyUrl'
import { FbLink } from '../../FbUI/FbLink'
import {
  AccountOwnerPill,
  CampaignsCell,
  ContactTagCell,
  DistributorsCell,
  StagePill,
  TaglistCell,
} from '../../FbUI/StagePill'
import ExpandedDealsRow from './ExpandedDealsRow'
import apiService from 'services/api'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { DEAL_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsColumnInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { featureFlagService } from 'utils/featureFlagService'

export function useDealsTableColumns({
  sorting,
  showCompanyColumn,
}: {
  sorting: any
  showCompanyColumn?: boolean
}) {
  const api = apiService()
  const { preferences } = usePreferences()
  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<Deal>()
  const dealColumnHelper = createTableColumnHelper({
    columns: DEAL_COLUMNS,
    tooltips,
  })
  const { enableEmailIntegration } = featureFlagService()
  let columns = useMemo(
    () => [
      {
        id: 'expanded-row',
        header: '',
        cell: (info: CellContext<Deal, unknown>) => {
          const row = info.row.original
          return <ExpandedDealsRow dealId={row.id} product={row.product} />
        },
      },
      ...(showCompanyColumn
        ? [
            columnHelper.accessor('contact_company', {
              ...dealColumnHelper.retrieveColumn('company', {
                meta: {
                  isEditable: false,
                  isFirstColumn: true,
                },
              }),
              header: () => null,
              cell: (info) => {
                const row = info.row.original

                return (
                  <CompanyContainer>
                    <FbLink
                      to={`${getCompanyUrl(row.contact_company)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.contact_company?.name}
                    </FbLink>
                  </CompanyContainer>
                )
              },
              size: 350,
            }),
          ]
        : []),
      columnHelper.accessor('product', {
        ...dealColumnHelper.retrieveColumn('product', {
          meta: {
            isEditable: showCompanyColumn,
            isFirstColumn: !showCompanyColumn,
          },
        }),
        header: () => (showCompanyColumn ? 'Product' : ''),
        size: 250,

        cell: (info) => {
          const row = info.row.original
          return row.product?.name
        },
      }),
      columnHelper.accessor('company_size', {
        ...dealColumnHelper.retrieveColumn('company_size'),
        size: 100,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('contact_company.category', {
        ...dealColumnHelper.retrieveColumn('category'),
        cell: (info) => {
          return (
            categories?.find(
              (a) => a.id === (info.getValue() as unknown as number)
            )?.name ?? '-'
          )
        },
      }),
      columnHelper.accessor(
        (row) => row.contact_company?.sub_category?.name ?? '-',
        {
          ...dealColumnHelper.retrieveColumn('sub_category'),
        }
      ),
      columnHelper.accessor('sales_stage', {
        ...dealColumnHelper.retrieveColumn('sales_stage'),
        size: 250,
        cell: (info) => {
          if (!info.row?.original?.sales_stage) return <>-</>
          return <StagePill stageId={info.row.original?.sales_stage} />
        },
      }),
      columnHelper.accessor('last_datetime_stage_changed', {
        ...dealColumnHelper.retrieveColumn('last_datetime_stage_changed'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${dayjs().diff(dayjs(value), 'day')} days`
        },
      }),
      columnHelper.accessor('expected_days_to_close', {
        ...dealColumnHelper.retrieveColumn('expected_days_to_close'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${dayjs().add(value, 'day').format('MMM DD, YYYY')}`
        },
      }),
      columnHelper.accessor('close_date_override', {
        ...dealColumnHelper.retrieveColumn('close_date_override'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return dayjs(value).format('MMM DD, YYYY')
        },
      }),
      columnHelper.accessor('account_owner', {
        ...dealColumnHelper.retrieveColumn('account_owner'),
        size: 250,
        cell: (info) => {
          return <AccountOwnerPill account_owner_id={info.getValue()} />
        },
      }),
      columnHelper.accessor('days_since_reply', {
        ...dealColumnHelper.retrieveColumn('days_since_reply'),
        cell: (info) => {
          return info.getValue() ?? '-'
        },
      }),
      columnHelper.accessor('days_since_sent', {
        ...dealColumnHelper.retrieveColumn('days_since_sent'),
        cell: (info) => {
          return info.getValue() ?? '-'
        },
      }),
      columnHelper.accessor('origin_campaign', {
        ...dealColumnHelper.retrieveColumn('origin_campaign'),
        size: 260,
        cell: (info) => {
          return (
            <CampaignsCell
              campaigns={
                info.row.original.origin_campaign
                  ? [info.row.original.origin_campaign]
                  : undefined
              }
            />
          )
        },
      }),

      columnHelper.accessor('annual_revenue', {
        ...dealColumnHelper.retrieveColumn('annual_revenue'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('monthly_revenue_predicted', {
        ...dealColumnHelper.retrieveColumn('monthly_revenue_predicted'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('monthly_revenue_override', {
        ...dealColumnHelper.retrieveColumn('monthly_revenue_override'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),

      columnHelper.accessor('annual_volume', {
        ...dealColumnHelper.retrieveColumn('annual_volume'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatTwoDecimal(value ?? 0)} lbs`
        },
      }),
      columnHelper.accessor('monthly_volume_predicted', {
        ...dealColumnHelper.retrieveColumn('monthly_volume_predicted'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatTwoDecimal(value ?? 0)} lbs`
        },
      }),
      columnHelper.accessor('annual_brand_opp', {
        ...dealColumnHelper.retrieveColumn('annual_brand_opp'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatUsd(value ?? 0)}`
        },
      }),
      columnHelper.accessor('annual_total', {
        ...dealColumnHelper.retrieveColumn('annual_total'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatUsd(value ?? 0)}`
        },
      }),
      columnHelper.accessor('monthly_volume_override', {
        ...dealColumnHelper.retrieveColumn('monthly_volume_override'),
        size: 175,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatTwoDecimal(value ?? 0)} lbs`
        },
      }),
      columnHelper.display({
        ...dealColumnHelper.retrieveColumn('taglist'),
        size: 260,
        cell: (info) => {
          return (
            <TaglistCell taglist={info.row.original?.contact_company.taglist} />
          )
        },
      }),
      columnHelper.accessor('contact_tags', {
        ...dealColumnHelper.retrieveColumn('contact_tags'),
        size: 260,
        cell: (info) => {
          return <ContactTagCell deals={[info.row.original]} />
        },
      }),
      columnHelper.accessor('contact_company.distributors', {
        ...dealColumnHelper.retrieveColumn('distributors'),
        size: 260,
        cell: (info) => {
          return (
            <DistributorsCell
              distributors={info.getValue()}
              asc={
                sorting?.length && sorting[0].id === 'distributors'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      }),
      columnHelper.accessor('contact_company.note_count', {
        ...dealColumnHelper.retrieveColumn('note_count'),
        size: 110,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('contact_company.contact_count', {
        ...dealColumnHelper.retrieveColumn('contact_count'),
        size: 110,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('days_since_last_modified', {
        ...dealColumnHelper.retrieveColumn('days_since_last_modified'),
        cell: (info) => {
          return info.getValue()
        },
      }),
      columnHelper.accessor('estimated_meals_per_day', {
        ...dealColumnHelper.retrieveColumn('estimated_meals_per_day'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('operational_schools', {
        ...dealColumnHelper.retrieveColumn('operational_schools'),
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('modified', {
        ...dealColumnHelper.retrieveColumn('modified'),
        size: 150,
        cell: (info) => {
          return dayjs(info.getValue()).format('MM/DD/YYYY')
        },
      }),
      columnHelper.accessor('created', {
        ...dealColumnHelper.retrieveColumn('created'),
        size: 150,
        cell: (info) => dayjs(info.getValue()).format('MM/DD/YYYY'),
      }),
    ],
    [categories, tooltips]
  )
  if (!enableEmailIntegration) {
    columns = columns.filter(
      (column) =>
        column.id !== 'days_since_reply' && column.id !== 'days_since_sent'
    )
  }
  return columns
}
const CompanyContainer = s.div`
display: flex;
align-items: center;
`
