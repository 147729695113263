const OPEN_HOURS_MAP = {
  open_7_days: 'Open All Week',
  open_weekends: 'Open Weekends',
  open_weekdays: 'Open Weekdays',
  open_24_7: 'Open 24/7',
}

export const OPEN_HOURS_OPTIONS = Object.entries(OPEN_HOURS_MAP).map(
  ([value, label]) => ({
    value,
    label,
  })
)
