import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import {
  notUndefined,
  useVirtualizer,
  VirtualItem,
} from '@tanstack/react-virtual'
import Checkbox from 'components/Checkbox/checkbox'
import { DataTableSelectAllDropdown } from 'components/DataTable/DataTableSelectAllDropdown/DataTableSelectAllDropdown'
import {
  ColumnModal,
  getColumnSelectorRecipientId,
} from 'components/Modals/ColumnModal/ColumnModal'
import { HelpTooltip } from 'components/Tooltip/HelpTooltip'
import React, {
  Fragment,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Spinner } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import {
  getColumnOrder,
  getColumnVisibility,
} from 'stores/ColumnsStore/ColumnsStore'
import { useColumnStoreContext } from 'stores/ColumnsStore/useColumnsStoreContext'
import styled from 'styled-components/macro'
import { ConditionalWrapper } from '../../ConditionalWrapper'
import { cn } from '../../UI/cn'
import { useDataTableContext } from '../DataTableContext'
import DataTablePagination from '../DataTablePagination/DataTablePagination'
import {
  DataTableColumnSort,
  dataTableSortingStoreRepo,
} from '../DataTableSorting/DataTableSortingStore'
import { SortAscIcon, SortDescIcon } from '../styles'
import { ITablePaginationOptions } from '../types'
import * as S from './styles'

export interface DataTableProps<T> {
  columns: ColumnDef<T, any>[]
  data: T[]
  extraRowAccessor?: keyof T
  expandColAccessor?: keyof T | string
  rowAttributes?: (
    row: Row<T>
  ) => { [key: string]: string } & HTMLAttributes<any>
  //
  children?: ReactNode
  sortableFields?: Array<string> | 'all'
  defaultSort?: DataTableColumnSort[]
  onTableBottomReached?: () => void
  virtualizeRows?: boolean
  enableRowSelection?: boolean
  localSort?: boolean
  isPaginationEnabled?: boolean
  paginationOptions?: ITablePaginationOptions
  stickyFirstColumn?: boolean
  stickyLastColumn?: boolean
  style?: React.CSSProperties
  disabledRowsIdx?: number[]
  autoHideScrollbar?: boolean
  height?: number
  tableKey: string
  footerControls?: React.JSX.Element
  selectAllText?: string
  loading?: boolean
  tableHeader?: React.JSX.Element
  emptyTableText?: {
    title: string
    subtitle?: string
  }
  onRowDoubleClick?: (row: Row<T>) => void
  onRowClick?: (row: Row<T>) => void
}

export default function DataTable<T extends object>(props: DataTableProps<T>) {
  const tableContainerRef = useRef<HTMLTableElement>(null)
  const columnVisibility = getColumnVisibility()
  const columnOrder = getColumnOrder()
  const initializeTableColumns = useColumnStoreContext(
    (state) => state.initializeTableColumns
  )
  const { sorting, setSorting, handleSort } =
    dataTableSortingStoreRepo.getStore(props.tableKey)()

  const {
    state: {
      expanded,
      rowSelection,
      totalSelectedRows,
      totalRowsInBackend,
      isAllRowsSelected,
    },
    methods: {
      setExpanded,
      setRowSelection,
      setIsAllRowsSelected,
      setTotalRowsInPage,
    },
  } = useDataTableContext()

  const tableOptions = {
    data: props.data,
    columns: props.columns,
    state: {
      columnVisibility: columnVisibility,
      columnOrder: columnOrder,
      rowSelection: rowSelection,
    },
    enableRowSelection: props.enableRowSelection,
    onSortingChange: (a: any) => {
      setSorting(a())
    },
    onRowSelectionChange: setRowSelection,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  } as TableOptions<T>

  if (props.localSort && tableOptions.state) {
    tableOptions.state.sorting = sorting as SortingState
  }

  const table = useReactTable(tableOptions)

  useEffect(() => {
    initializeTableColumns(table.getAllColumns() as any)
  }, [table.getAllColumns()])

  useEffect(() => {
    // Sets default sorting state
    if (props.defaultSort)
      setSorting([
        {
          id: props.defaultSort[0].id,
          desc: props.defaultSort[0].desc,
        },
      ])
    handleScroll()
  }, [])

  // Calling browser apis directly prevents stutters from react rerenders when using useState
  function handleScroll(event?: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (event?.currentTarget) {
      const {
        scrollHeight,
        scrollTop,
        clientHeight,
        scrollLeft,
        scrollWidth,
        clientWidth,
      } = event.currentTarget

      // called once the user has scrolled 50px left
      if (scrollLeft > 0) {
        document
          .querySelectorAll('.shadowable-table-cell')
          .forEach((el) => el?.classList.add('shadow-table-cell'))
      } else {
        document
          .querySelectorAll('.shadowable-table-cell')
          .forEach((el) => el?.classList.remove('shadow-table-cell'))
      }

      if (scrollLeft !== scrollWidth - clientWidth) {
        document
          .querySelectorAll('.shadowable-table-cell-right')
          .forEach((el) => el?.classList.add('shadow-table-cell-right'))
      } else {
        document
          .querySelectorAll('.shadowable-table-cell-right')
          .forEach((el) => el?.classList.remove('shadow-table-cell-right'))
      }

      // called once the user has scrolled within 300px of the bottom of the table
      if (scrollHeight - scrollTop - clientHeight < 300) {
        if (props.onTableBottomReached) props.onTableBottomReached()
      }
    }
  }

  const rowModel = useMemo(
    () => table.getRowModel(),
    [props.tableKey, props.data]
  )

  const virtualizer = useVirtualizer({
    count: rowModel.rows.length,
    getScrollElement: () => tableContainerRef.current!,
    estimateSize: () => 56,
    overscan: 2,
  })

  const virtualRows = virtualizer.getVirtualItems()

  const [before, after] = useMemo(() => {
    return virtualRows.length > 0
      ? [
          notUndefined(virtualRows[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(virtualRows[virtualRows.length - 1]).end,
        ]
      : [0, 0]
  }, [
    virtualRows,
    virtualizer.options.scrollMargin,
    virtualizer.getTotalSize(),
  ])

  const getSortIcon = (columnId: string) => {
    if (!sorting || sorting.length < 1) return null
    const sort = sorting?.[0]?.id === columnId
    if (sort) {
      return (
        <div className={'ml-3'}>
          {sorting[0]?.desc ? <SortDescIcon /> : <SortAscIcon />}
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    setTotalRowsInPage(table.getRowCount())
  }, [tableOptions, table])

  const toggleSelectAll = () => {
    const selected = !isAllRowsSelected
    setIsAllRowsSelected(selected)
    table.toggleAllRowsSelected(selected)
  }

  return (
    <div className={'flex flex-col w-full relative h-full'}>
      {props.tableHeader}

      <div
        style={{
          border: '1px solid #eaecf0',
          borderRadius: 8,
          overflow: 'hidden',
          height: props.height || '100%',
          position: 'relative',
        }}
      >
        <SimpleBar
          style={{
            height: props.height || '100%',
            ...props.style,
          }}
          autoHide={props.autoHideScrollbar}
          scrollableNodeProps={{
            ref: tableContainerRef,
            onScroll: handleScroll,
          }}
        >
          <S.Table ref={tableContainerRef}>
            <S.THead>
              {table.getHeaderGroups().map((headerGroup) => {
                const filteredHeaderGroup = headerGroup.headers.filter(
                  ({ id }) =>
                    id !== props.extraRowAccessor &&
                    id !== props.expandColAccessor
                )
                return (
                  <S.TRHead key={`headergroup_${headerGroup.id}`}>
                    {filteredHeaderGroup.map((header, headerIndx) => {
                      const sortingProps: Record<string, unknown> = {}

                      const isSortable =
                        (props.sortableFields === 'all' ||
                          props.sortableFields?.includes(header.column.id)) &&
                        header.column.id !== 'select'

                      if (isSortable) {
                        sortingProps['onClick'] = () =>
                          handleSort(header.column.id)
                        sortingProps['style'] = {
                          cursor: 'pointer',
                        }
                      }

                      const tooltip = (header?.column.columnDef?.meta as any)
                        ?.tooltip

                      return (
                        <S.TH
                          style={{ width: header.getSize() }}
                          key={header.id}
                          colSpan={header.colSpan}
                          className={cn({
                            'sticky-table-cell':
                              headerIndx === 0 && props.stickyFirstColumn,
                            'sticky-table-cell-right':
                              props.stickyLastColumn &&
                              headerIndx === filteredHeaderGroup.length - 1,
                            'shadowable-table-cell':
                              headerIndx === 0 && props.stickyFirstColumn,
                            'shadowable-table-cell-right':
                              props.stickyLastColumn &&
                              headerIndx === filteredHeaderGroup.length - 1,
                          })}
                        >
                          <ConditionalWrapper
                            condition={
                              !!(props.enableRowSelection && headerIndx === 0)
                            }
                            wrapper={(children) => (
                              <S.CheckboxRow>{children}</S.CheckboxRow>
                            )}
                          >
                            {props.enableRowSelection && headerIndx === 0 && (
                              <DataTableSelectAllDropdown
                                selectAllText={props.selectAllText}
                                listCount={totalRowsInBackend}
                                pageCount={table.getRowCount()}
                                isAllRowsSelected={
                                  isAllRowsSelected &&
                                  table.getIsAllRowsSelected()
                                }
                                totalSelected={
                                  isAllRowsSelected
                                    ? totalSelectedRows
                                    : Object.keys(rowSelection).length
                                }
                                onSelectAllInList={() => {
                                  // Select all items in the list
                                  toggleSelectAll()
                                }}
                                onSelectAllInPage={() => {
                                  // Select all items in the current page
                                  setIsAllRowsSelected(false)
                                  table.toggleAllRowsSelected(true)
                                }}
                                onSelectNone={() => {
                                  // Deselect all items
                                  table.setRowSelection({})
                                  setIsAllRowsSelected(false)
                                  table.toggleAllRowsSelected(false)
                                }}
                              />
                            )}
                            {!header.isPlaceholder && (
                              <div
                                className={cn(
                                  'flex items-center justify-start nowrap',
                                  {
                                    'justify-end': (
                                      header?.column.columnDef?.meta as any
                                    )?.rightAlign,
                                  }
                                )}
                              >
                                <S.HeadingText
                                  rightAlign={
                                    (header?.column.columnDef?.meta as any)
                                      ?.rightAlign
                                  }
                                  {...sortingProps}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </S.HeadingText>
                                {tooltip ? (
                                  <div className={'ml-2'}>
                                    <HelpTooltip
                                      title={tooltip?.title}
                                      content={tooltip?.content}
                                      link={{
                                        text: tooltip?.link_text,
                                        href: tooltip?.link,
                                      }}
                                    />
                                  </div>
                                ) : null}
                                <div {...sortingProps}>
                                  {getSortIcon(header.column.id)}
                                </div>
                              </div>
                            )}
                          </ConditionalWrapper>
                        </S.TH>
                      )
                    })}
                  </S.TRHead>
                )
              })}
            </S.THead>
            <S.TBody style={{ height: `${virtualizer.getTotalSize()}px` }}>
              {before > 0 && (
                <S.TR
                  style={{
                    height: `${before}px`,
                  }}
                />
              )}

              {(props.virtualizeRows ? virtualRows : rowModel.rows).map(
                (_row) => {
                  const row = (
                    props.virtualizeRows ? rowModel.rows[_row.index] : _row
                  ) as Row<T>
                  const cells = row.getVisibleCells()
                  const isLast = rowModel.rows.length === _row.index + 1

                  const indexOfExtraCell = cells.findIndex(
                    (c) => c.column.id === props.extraRowAccessor
                  )
                  const extraCell =
                    indexOfExtraCell !== -1
                      ? cells.splice(indexOfExtraCell, 1)[0]
                      : false

                  const indexOfExpandCell = props.expandColAccessor
                    ? cells.findIndex((c) =>
                        c.column.id.includes(
                          props.expandColAccessor?.toString() ?? ''
                        )
                      )
                    : -1
                  const expandCell =
                    indexOfExpandCell !== -1 ? cells[indexOfExpandCell] : false

                  const filteredCells = cells.filter((c) => {
                    let isExpand = false
                    if (props.expandColAccessor) {
                      isExpand = c.column.id.includes(
                        props.expandColAccessor.toString()
                      )
                    }
                    return !isExpand
                  })

                  const isExpanded = expandCell
                    ? expanded.includes(expandCell.id)
                    : false
                  const attrs = props.rowAttributes?.(row)

                  const rowProps = {
                    ...attrs,

                    onClick: () => {
                      if (row.getCanExpand()) {
                        const expandSubRowHandler =
                          row.getToggleExpandedHandler()
                        expandSubRowHandler()
                      } else if (expandCell && props.expandColAccessor) {
                        setExpanded((prevExpanded) => {
                          if (prevExpanded.includes(expandCell.id)) {
                            return prevExpanded.filter(
                              (id) => id !== expandCell.id
                            )
                          } else {
                            return [...prevExpanded, expandCell.id]
                          }
                        })
                      }
                      if (props.onRowClick) {
                        props.onRowClick(row)
                      }
                    },

                    style: {
                      cursor:
                        expandCell || props.onRowClick ? 'pointer' : 'default',
                    },
                  }

                  return (
                    <Fragment key={row.id}>
                      <S.TR
                        {...rowProps}
                        expandCell={!!expandCell || row.getCanExpand()}
                        isExpanded={!!extraCell || isExpanded}
                        isSelected={row.getIsSelected()}
                        isDisabled={
                          props.disabledRowsIdx?.includes(row.index) || false
                        }
                        key={
                          props.virtualizeRows
                            ? (_row as VirtualItem).key
                            : (_row as Row<T>).id
                        }
                        data-index={_row.index}
                        ref={virtualizer.measureElement}
                        style={{
                          height: `${56}px`,
                        }}
                        onDoubleClick={() => {
                          if (props.onRowDoubleClick) {
                            props.onRowDoubleClick(row)
                          }
                        }}
                      >
                        {filteredCells.map((cell, indx) => {
                          const meta = cell?.column.columnDef?.meta as any
                          const rightAlign = meta?.rightAlign
                          return (
                            <S.TD
                              className={cn({
                                'sticky-table-cell':
                                  indx === 0 && props.stickyFirstColumn,
                                'shadowable-table-cell':
                                  indx === 0 && props.stickyFirstColumn,
                                'sticky-table-cell-right':
                                  props.stickyLastColumn &&
                                  indx === filteredCells.length - 1,
                                'shadowable-table-cell-right':
                                  props.stickyLastColumn &&
                                  indx === filteredCells.length - 1,
                                'text-right': rightAlign,
                              })}
                              key={cell.id}
                              style={{
                                height: `${56}px`,
                              }}
                            >
                              <ConditionalWrapper
                                condition={
                                  !!(props.enableRowSelection && indx === 0)
                                }
                                wrapper={(children) => (
                                  <S.CheckboxRow>{children}</S.CheckboxRow>
                                )}
                              >
                                {props.enableRowSelection && indx === 0 && (
                                  <IndeterminateCheckbox
                                    {...{
                                      checked: row.getIsSelected(),
                                      disabled: !row.getCanSelect(),
                                      onChange: row.getToggleSelectedHandler(),
                                    }}
                                  />
                                )}

                                {props.expandColAccessor && indx === 0 && (
                                  <>
                                    {row?.getCanExpand() || expandCell ? (
                                      <RowExpandContainer>
                                        {row?.getIsExpanded() || isExpanded ? (
                                          <FaChevronDown size={14} />
                                        ) : (
                                          <FaChevronRight size={14} />
                                        )}
                                      </RowExpandContainer>
                                    ) : null}
                                  </>
                                )}

                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                ) || ' - '}
                              </ConditionalWrapper>
                            </S.TD>
                          )
                        })}
                      </S.TR>

                      {extraCell && (
                        <S.TRExtra>
                          <S.TDExtra
                            key={extraCell.id}
                            colSpan={props.columns.length}
                          >
                            {flexRender(
                              extraCell.column.columnDef.cell,
                              extraCell.getContext()
                            )}
                          </S.TDExtra>
                        </S.TRExtra>
                      )}

                      {expandCell && (
                        <S.TRExtra
                          key={`expanded_${row.id}`}
                          // ref={animationRef}
                          style={{
                            height: isExpanded ? 'auto' : 0,
                            ...(isLast && { border: 'none' }),
                          }}
                        >
                          {isExpanded && (
                            <>
                              <S.TDExtra
                                colSpan={4}
                                className="sticky-table-cell"
                              >
                                <span
                                  style={{
                                    width: 'max-content', // Hack to make the content overflow over the sticky cell
                                  }}
                                >
                                  {flexRender(
                                    expandCell.column.columnDef.cell,
                                    expandCell.getContext()
                                  )}
                                </span>
                              </S.TDExtra>
                              {/* -1 to account for the expand cell */}
                              <S.TDExtra
                                colSpan={props.columns.length - 1}
                              ></S.TDExtra>
                            </>
                          )}
                        </S.TRExtra>
                      )}
                    </Fragment>
                  )
                }
              )}

              {after > 0 && (
                <S.TR
                  style={{
                    height: `${after}px`,
                  }}
                />
              )}
            </S.TBody>
          </S.Table>
        </SimpleBar>
        {rowModel.rows.length === 0 && !props.loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
            <div className="text-center p-4 bg-gray-100 rounded-lg shadow-md">
              <p className="text-gray-600 font-medium">
                {props.emptyTableText?.title || 'No results found.'}
              </p>
              <p className="text-sm text-gray-500 mt-2">
                {props.emptyTableText?.subtitle ||
                  'Try adjusting your filters.'}
              </p>
            </div>
          </div>
        )}
        <LoadingOverlay show={props.loading}>
          <Spinner />
        </LoadingOverlay>
      </div>

      {/* Setting min-h-[72px] here for cases where pagination is disabled */}
      {(props.isPaginationEnabled || props.footerControls) && (
        <div className="min-h-[72px] flex justify-start md:justify-center items-center w-full relative">
          <DataTablePagination paginationOptions={props.paginationOptions} />
          {props.footerControls && (
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
              <FooterControlsContainer>
                {props.footerControls}
              </FooterControlsContainer>
            </div>
          )}
        </div>
      )}

      {document.getElementById(getColumnSelectorRecipientId(props.tableKey)) &&
        createPortal(
          <ColumnModal />,
          document.getElementById(getColumnSelectorRecipientId(props.tableKey))!
        )}
    </div>
  )
}

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)
  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return <Checkbox {...rest} ref={ref} />
}

const RowExpandContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #667085;
`
const FooterControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
`

const LoadingOverlay = styled.div<{
  show?: boolean
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 30;
`
