import React, { useState, useEffect } from 'react'
import { ClientDataTable } from '../../../ClientDataTable/ClientDataTable'
import CustomCheckbox from '../../../Checkbox/checkbox'
import { RowSelectionState } from '@tanstack/react-table'
import { format } from 'date-fns'

export interface Company {
  id: number
  name: string
  last_requested_date?: string
  last_found_date?: string
  selected?: boolean
}

interface SelectableCompaniesTableProps {
  companies: Company[]
  toggleCompanySelection: (companyId: number) => void
  selectAllCompanies: (selected: boolean) => void
  dateColumn: {
    header: string
    accessorKey: string
    dateField: 'last_requested_date' | 'last_found_date'
  }
  enableLogging?: boolean
}

export function SelectableCompaniesTable({
  companies,
  toggleCompanySelection,
  selectAllCompanies,
  dateColumn,
}: SelectableCompaniesTableProps) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  // Update row selection when companies changes
  useEffect(() => {
    const newSelection: RowSelectionState = {}
    companies.forEach((company, index) => {
      if (company.selected) {
        newSelection[index] = true
      }
    })

    setRowSelection(newSelection)
  }, [companies])

  // Handle row selection changes
  useEffect(() => {
    Object.entries(rowSelection).forEach(([index, isSelected]) => {
      const companyId = companies[parseInt(index)].id

      if (isSelected !== companies.find((c) => c.id === companyId)?.selected) {
        toggleCompanySelection(companyId)
      }
    })
  }, [rowSelection, companies, toggleCompanySelection])

  return (
    <ClientDataTable
      columns={[
        {
          header: 'Company',
          accessorKey: 'name',
        },
        {
          header: dateColumn.header,
          accessorKey: dateColumn.accessorKey,
        },
        {
          header: () => {
            return (
              <div className="flex justify-center">
                <CustomCheckbox
                  id={`select-all-companies-${dateColumn.accessorKey}`}
                  checked={
                    Object.keys(rowSelection).length === companies.length &&
                    companies.length > 0
                  }
                  onChange={(e) => {
                    selectAllCompanies(e.target.checked)

                    const newSelection: RowSelectionState = {}
                    if (e.target.checked) {
                      companies.forEach((_, index) => {
                        newSelection[index] = true
                      })
                    }

                    setRowSelection(newSelection)
                  }}
                />
                &nbsp;&nbsp;Include?
              </div>
            )
          },
          enableSorting: false,
          accessorKey: 'selected',
          cell: ({ row }) => {
            return (
              <div className="flex justify-center">
                <CustomCheckbox
                  id={`company-${row.original.id}`}
                  checked={row.original.selected}
                  onChange={() => {
                    toggleCompanySelection(row.original.id)
                  }}
                />
              </div>
            )
          },
        },
      ]}
      data={companies.map((company) => ({
        id: company.id,
        name: company.name,
        [dateColumn.accessorKey]: company[dateColumn.dateField]
          ? format(
              new Date(company[dateColumn.dateField] as string),
              'MMM d, yyyy'
            )
          : 'N/A',
        selected: company.selected,
      }))}
      enableRowSelection={false}
      loading={false}
      height={400}
      enableSorting={true}
    />
  )
}
