import React, { ReactNode } from 'react'
import FbButton from '../../FbUI/FbButton'
import { Text } from '../../FbUI/Text'
import { BiError } from 'react-icons/bi'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../UI/Modal/ModalPrimitive'

interface BaseStepProps {
  title: string
  subtitle?: ReactNode
  error?: string | null
  children: ReactNode
  onBack?: () => void
  onContinue?: () => void
  handleClose: () => void
  backButtonText?: string
  continueButtonText?: string
  cancelButtonText?: string
  canContinue?: boolean
  isContinueLoading?: boolean
  currentStep?: number
  totalSteps?: number
}

export const BaseStep: React.FC<BaseStepProps> = ({
  title,
  subtitle,
  error,
  children,
  onBack,
  onContinue,
  handleClose,
  backButtonText = 'Back',
  continueButtonText = 'Continue',
  cancelButtonText = 'Cancel',
  canContinue = true,
  isContinueLoading = false,
  currentStep = 0,
  totalSteps = 2,
}) => {
  // Determine the text for the back/cancel button
  const isFirstStep = currentStep === 1
  const backButtonLabel = isFirstStep ? cancelButtonText : backButtonText

  // Determine the action for the back/cancel button
  const handleBackButtonClick = () => {
    if (isFirstStep) {
      // On first step, close the modal
      handleClose()
    } else if (onBack) {
      // On other steps, go back if onBack is provided
      onBack()
    } else {
      // Fallback to close if no onBack provided
      handleClose()
    }
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        {subtitle && <DialogDescription>{subtitle}</DialogDescription>}
      </DialogHeader>

      {error && (
        <div className="flex items-center bg-red-100 rounded p-3 mb-4">
          <div className="mr-2 text-xl">
            <BiError className="text-red-600" />
          </div>
          <Text className="text-red-600">{error}</Text>
        </div>
      )}

      <div className="flex flex-col items-stretch w-full max-h-[70vh] overflow-y-auto overflow-x-hidden">
        {children}
      </div>

      {totalSteps > 1 && (
        <div className="flex justify-center gap-2 mt-4 mb-2">
          {Array.from({ length: totalSteps }).map((_, index) => {
            return (
              <div
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentStep - 1 ? 'bg-black' : 'bg-gray-200'
                }`}
              />
            )
          })}
        </div>
      )}

      <div className="flex gap-4 mt-6">
        <FbButton
          className="flex-1"
          variant="secondary"
          onClick={handleBackButtonClick}
        >
          {backButtonLabel}
        </FbButton>

        {onContinue && (
          <FbButton
            className="flex-1"
            variant="primary"
            onClick={onContinue}
            disabled={!canContinue || isContinueLoading}
            loading={isContinueLoading}
          >
            {continueButtonText}
          </FbButton>
        )}
      </div>
    </>
  )
}
