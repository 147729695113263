import { useCallback } from 'react'

export interface Selectable {
  id: number
  selected?: boolean
  [key: string]: any
}

export function useSelection<T extends Selectable>(
  items: T[],
  setItems: (items: T[]) => void
) {
  const selectAll = useCallback(
    (selected: boolean) => {
      setItems(
        items.map((item) => ({
          ...item,
          selected,
        }))
      )
    },
    [items, setItems]
  )

  const toggleSelection = useCallback(
    (itemId: number) => {
      setItems(
        items.map((item) =>
          item.id === itemId ? { ...item, selected: !item.selected } : item
        )
      )
    },
    [items, setItems]
  )

  const getSelected = useCallback(() => {
    return items.filter((item) => item.selected)
  }, [items])

  // Generate continue button text based on selection
  const getContinueButtonText = useCallback(
    (itemType: string, zeroLabel: string = 'Skip Companies') => {
      const selectedCount = getSelected().length
      return selectedCount === 0
        ? zeroLabel
        : `Include ${selectedCount} ${selectedCount === 1 ? 'company' : 'companies'} with ${itemType}`
    },
    [getSelected]
  )

  return {
    selectAll,
    toggleSelection,
    getSelected,
    getContinueButtonText,
  }
}
