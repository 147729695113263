import { FilterGroup } from 'components/Filters/components/FilterModal'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { FilterStore } from 'components/Filters/FilterStore'
import {
  RangeFilter,
  RangeFilterWithMinMax,
} from 'components/Filters/fields/RangeFilter'
import {
  createFilterColumnHelper,
  DataTypeColumn,
} from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { US_STATES } from 'utils/us_states'
import { US_CITIES } from 'utils/us_cities'
import { GooglePlaceDoorBase } from 'models/google_place_door'

export function CommonFiltersetGooglePlaceDoorGroup({
  filterStore,
  columns,
}: {
  filterStore: FilterStore
  columns: Record<keyof GooglePlaceDoorBase, DataTypeColumn>
}) {
  const commonGooglePlaceFilterHelper = createFilterColumnHelper({
    columns,
  })

  return (
    <FilterGroup title="Opportunity Attributes">
      <SelectFilter
        {...commonGooglePlaceFilterHelper.retrieveProps('city')}
        options={US_CITIES}
        filterStore={filterStore}
      />
      <SelectFilter
        {...commonGooglePlaceFilterHelper.retrieveProps('state')}
        options={US_STATES}
        filterStore={filterStore}
      />
      <RangeFilterWithMinMax
        {...commonGooglePlaceFilterHelper.retrieveProps('reviews_avg')}
        store={filterStore}
        min={0}
        max={5}
        decimalScale={2}
      />
      <RangeFilter
        {...commonGooglePlaceFilterHelper.retrieveProps('reviews_cnt')}
        store={filterStore}
      />
    </FilterGroup>
  )
}
