import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { getIdWithPrefix } from 'utils/pathUtils'
import CommonPage from 'components/DataExpansionComponents/Common/CommonPage'

export default function University() {
  const urlParams = useParams()
  const { preferences } = usePreferences()
  const k12ChainId = urlParams?.id
    ? getIdWithPrefix(
        urlParams?.id,
        preferences?.company_type_prefix_map[ContactCompanyType.K12District]
      )
    : undefined

  const api = apiService()
  const { data, isLoading, isError, error } = api.useGetK12District(k12ChainId)

  return (
    <CommonPage
      uniqueCompanyTypeId={k12ChainId}
      useGetCompany={{
        companyData: data,
        contactCompanyData: data,
        isLoading,
        isError,
        error,
      }}
      tableTypeSlug="education-k-12"
      companyTypeSlug="education-k-12"
      breadcrumbs={[
        {
          title: 'K12 District',
          breadcrumb: data?.k12district?.lea_name,
        },
      ]}
      getFieldsFromData={(data) => ({
        contactCompanyId: data?.id,
        companyName: data?.k12district?.lea_name,
        websiteAddress: data?.k12district?.website,
        address: {
          addressUrl: data?.k12district?.google_place_url,
          addressInfo: {
            fullAddress: data?.k12district?.address,
            city: data?.k12district?.lcity,
            state: data?.k12district?.lstate,
            zipcode: data?.k12district?.zipcode,
          },
          fullAddressInfo: data?.k12district?.address_info,
        },
      })}
    />
  )
}
