import React from 'react'
import { LucideIcon } from 'lucide-react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { cn } from 'components/UI/cn'

interface Option {
  key: string
  icon: LucideIcon
  label: string
}

interface RadioButtonSelectProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  options: Option[]
  label?: string
  optional?: boolean
  horizontal?: boolean
}

export function RadioButtonSelect<T extends FieldValues>({
  control,
  name,
  options,
  label,
  optional = false,
  horizontal = true,
}: RadioButtonSelectProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label
          htmlFor={name}
          className="text-base mt-3 font-medium text-gray-800 select-none mb-2"
        >
          {label}
          {optional && (
            <span className="text-sm italic font-normal"> - optional</span>
          )}
        </label>
      )}
      <div
        className={cn('flex flex-col gap-2', {
          'flex-row space-x-4': horizontal,
        })}
      >
        {options.map((option) => (
          <button
            key={option.key}
            type="button"
            onClick={() => onChange(option.key)}
            className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-all duration-200 ${
              value === option.key
                ? 'bg-emerald-100 border-2 border-emerald-500 text-emerald-700'
                : 'bg-gray-100 border-2 border-transparent text-gray-700 hover:bg-gray-200'
            }`}
          >
            <option.icon className="w-5 h-5" />
            <span>{option.label}</span>
          </button>
        ))}
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
    </div>
  )
}
