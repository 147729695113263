import React from 'react'
import { useContactRequestContext } from './ContactRequestContext'
import { StepContainer } from './components/StepContainer'

export const SuccessStep: React.FC = () => {
  const { handleClose } = useContactRequestContext()

  return (
    <StepContainer
      title="Success"
      subtitle="Your request has been processed"
      handleClose={handleClose}
      onContinue={handleClose}
      continueButtonText="Close"
      loading={false}
      currentStep={3}
      totalSteps={3}
    >
      <div className="text-center py-6">
        <p className="mb-4">
          Your contact request has been submitted successfully!
        </p>
        <p className="text-gray-600 mb-6">
          You can check the status of your request in the &quot;Contact
          Requests&quot; section.
        </p>
      </div>
    </StepContainer>
  )
}
