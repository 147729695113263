import styled from 'styled-components'

export const Page = styled.div`
  padding: 60px 80px;
  background-color: #f5f5f5;
  flex: 1;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  flex: 1;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  max-width: 1025px;
  margin: 0 auto;
`
