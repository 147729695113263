import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { getIdWithPrefix } from 'utils/pathUtils'
import CommonPage from 'components/DataExpansionComponents/Common/CommonPage'
import { formatInteger, formatUsd } from 'utils/formatting'

export default function HospitalPage() {
  const urlParams = useParams()
  const { preferences } = usePreferences()
  const hospitalID = urlParams?.hospital_id
    ? getIdWithPrefix(
        urlParams?.hospital_id,
        preferences?.company_type_prefix_map[ContactCompanyType.Hospital]
      )
    : undefined

  const api = apiService()
  const { data, isLoading, isError, error } = api.useGetHospital(hospitalID)

  return (
    <CommonPage
      uniqueCompanyTypeId={hospitalID}
      useGetCompany={{
        companyData: data,
        contactCompanyData: data,
        isLoading,
        isError,
        error,
      }}
      tableTypeSlug="healthcare-hospitals"
      companyTypeSlug="healthcare-hospitals"
      breadcrumbs={[
        {
          title: 'Hospital',
          breadcrumb: data?.hospital?.hospital_name,
        },
      ]}
      getFieldsFromData={(data) => ({
        contactCompanyId: data?.id,
        companyName: data?.hospital?.hospital_name,
        websiteAddress: '',
        address: {
          addressUrl: data?.hospital?.google_place_url || undefined,
          addressInfo: {
            fullAddress: data?.hospital?.street_addr,
            city: data?.hospital?.city,
            state: data?.hospital?.state,
            zipcode: data?.hospital?.zip_code,
            extra: data?.hospital?.po_box
              ? `PO Box: ${data?.hospital?.po_box}`
              : undefined,
          },
          fullAddressInfo: data?.hospital?.address_info,
        },
      })}
      customBottomLeftItems={[
        {
          header: 'Provider Number',
          text: data?.hospital?.provider_number || 'Not available',
        },
        {
          header: 'Hospital Beds',
          text: formatInteger(data?.hospital?.total_hospital_beds),
        },
        {
          header: 'Hospital Days',
          text: formatInteger(data?.hospital?.total_hospital_days),
        },
        {
          header: 'Cafeteria Expenditures',
          text: data?.hospital?.cafeteria
            ? formatUsd(parseInt(data?.hospital?.cafeteria))
            : 'N/A',
        },
        {
          header: 'Patient Meal Expenditures',
          text:
            // convert to integer and format
            data?.hospital?.dietary
              ? formatUsd(parseInt(data?.hospital?.dietary))
              : 'N/A',
        },
        {
          header: 'Contracted Patient Meal Expenditures',
          text: data?.hospital?.dietary_under_contract
            ? formatUsd(parseInt(data?.hospital?.dietary_under_contract))
            : 'N/A',
        },
      ]}
    />
  )
}
