import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'
import { CAMPAIGNS_COLUMNS } from 'features/campaigns/campaignsColumns'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export default function CampaignExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportCampaigns = async ({
    filter,
    selectedColumns,
    selectedProductId,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    selectedProductId?: number
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.downloadCampaignsCsv(
      {
        ...filter,
        product_id: selectedProductId,
      },
      selectedColumns,
      exportType,
      props.selectedIds,
      props.excludeIds,
      controller?.signal
    )

    downloadFile(
      res,
      `First Bite Export: Campaigns - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const campaignColumnHelper = createExportColumnHelper({
    columns: CAMPAIGNS_COLUMNS,
  })

  const columns = [
    {
      title: 'Campaign Attributes',
      elements: [
        campaignColumnHelper.retrieveColumn('name'),
        campaignColumnHelper.retrieveColumn('description'),
        campaignColumnHelper.retrieveColumn('company_type'),
        campaignColumnHelper.retrieveColumn('product'),
        campaignColumnHelper.retrieveColumn('created'),
        campaignColumnHelper.retrieveColumn('modified'),
        campaignColumnHelper.retrieveColumn('days_since_last_update'),
        campaignColumnHelper.retrieveColumn('contact_company_count'),
        campaignColumnHelper.retrieveColumn('door_count'),
      ],
    },
    {
      title: 'Deals',
      elements: [
        campaignColumnHelper.retrieveColumn('deal_count'),
        campaignColumnHelper.retrieveColumn('deal_count_won'),
        campaignColumnHelper.retrieveColumn('deal_perc'),
        campaignColumnHelper.retrieveColumn('deal_perc_won'),
      ],
    },
    {
      title: 'Contacts',
      elements: [
        campaignColumnHelper.retrieveColumn('contact_count'),
        campaignColumnHelper.retrieveColumn('companies_with_contacts'),
        campaignColumnHelper.retrieveColumn('companies_with_contacts_perc'),
      ],
    },
    {
      title: 'Forecasts',
      elements: [
        campaignColumnHelper.retrieveColumn('taro'),
        campaignColumnHelper.retrieveColumn('taro_closed'),
        campaignColumnHelper.retrieveColumn('est_meals_day'),
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Campaigns Download"
      subTitle="Download up to 10,000 campaigns at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Campaigns"
      columns={columns}
      exportAPIAction={exportCampaigns}
      {...props}
    />
  )
}
