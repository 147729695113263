import { format } from 'date-fns'
import { Spinner } from 'react-bootstrap'
import { RebateOffer, useDeleteRebateOffer } from './tradespend_api'
import {
  CheckCircle,
  AlertCircle,
  Clock,
  Calendar,
  Link,
  Clipboard,
  Tag,
  Info,
  DollarSign,
  Package,
  Share2,
  QrCode,
  BoxIcon,
  Eye,
  FileText,
  Trash2,
} from 'lucide-react'
import { toast } from 'react-toastify'
import { useState } from 'react'
import FbButton from '../../components/FbUI/FbButton'
import QRCodeModal from './components/QRCodeModal'
import PreviewModal from './components/PreviewModal'
import StatCard from './components/StatCard'
import ProductTermsCard from './components/ProductTermsCard'
import DiscountTierCard from './components/DiscountTierCard'
import { useSearchParams, useNavigate } from 'react-router-dom'
import SuccessBanner from '../../components/Banners/SuccessBanner'
import { Modal } from '../../components/UI/Modal/Modal'
import WarningBanner from '../../components/Banners/WarningBanner'
import { RebateOfferStatePill } from './RebateOfferStatePill'

export const VITE_TRADESPEND_APP_HOST = import.meta.env
  .VITE_TRADESPEND_APP_HOST as string

export const formatDiscount = (tier: any) => {
  if (tier.discount_type === 'dollars_off') {
    return `$${tier.discount} off`
  } else {
    return `${tier.discount}% off`
  }
}

export const formatOfferType = (type: string) => {
  switch (type) {
    case 'FIRST_OFFER':
      return 'First Time Offer'
    case 'TIME_OFFER':
      return 'Time Based Offer'
    default:
      return type
  }
}

export function RebateOfferDetailsTab({
  offer,
  isLoading,
}: {
  offer?: RebateOffer
  isLoading?: boolean
}) {
  const [showQRModal, setShowQRModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [searchParams] = useSearchParams()
  const isNewOffer = searchParams.get('new') === 'true'
  const navigate = useNavigate()
  const deleteOfferMutation = useDeleteRebateOffer()

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-12">
        <Spinner />
      </div>
    )
  }

  if (!offer) return null

  const validClaimsTotal = offer.unpaid_valid_claims + offer.paid_claims
  const validClaimsPercent =
    offer.total_claims === 0
      ? 0
      : Math.round((validClaimsTotal / offer.total_claims) * 100)

  const getPercentage = (value: number) => {
    if (offer.total_claims === 0) return '0% of total'
    const percent = Math.round((value / offer.total_claims) * 100)
    return `${isNaN(percent) ? 0 : percent}% of total`
  }

  const offerUrl = `${import.meta.env.VITE_TRADESPEND_APP_HOST || 'http://localhost:5174'}/offer/${offer.code}`

  const handleDeleteOffer = async () => {
    try {
      await deleteOfferMutation.mutateAsync(offer.id)
      toast.success('Offer deleted successfully')
      navigate('/rebates/offers')
    } catch (error) {
      toast.error('Failed to delete offer')
      console.error('Error deleting offer:', error)
    }
  }

  return (
    <div className="h-full overflow-y-auto">
      {isNewOffer && (
        <div className="mb-3">
          <SuccessBanner text="Congratulations! Your offer is now live." />
        </div>
      )}
      {offer.state === 'DELETED' && (
        <div className="mb-3">
          <WarningBanner text="This offer has been deleted and is no longer accepting enrollments or new claims." />
        </div>
      )}
      {/* QR Code Modal */}
      <QRCodeModal
        url={offerUrl}
        offerName={offer.name}
        isOpen={showQRModal}
        onClose={() => setShowQRModal(false)}
      />

      {/* Preview Modal */}
      <PreviewModal
        url={offerUrl}
        offerName={offer.name}
        isOpen={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        open={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        title="Delete Offer"
        description="Are you sure you want to delete this offer? This action cannot be undone. This offer will no longer accept enrollments or new claims. Existing claims will still be processed."
        onAccept={handleDeleteOffer}
        acceptButtonText="Delete"
        acceptButtonProps={{ variant: 'danger' }}
        cancelButtonText="Cancel"
      />

      {/* Header Section with Offer Details and Key Stats */}
      <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200 mb-6">
        <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center gap-4 mb-6">
          <div>
            <div className="flex items-center gap-2">
              <h1 className="text-2xl font-bold text-gray-900">{offer.name}</h1>
              <RebateOfferStatePill state={offer.state} />

              {offer.state === 'DELETED' && offer.deleted_at && (
                <span className="text-sm text-gray-500">
                  (Deleted on{' '}
                  {format(new Date(offer.deleted_at), 'MMM d, yyyy')})
                </span>
              )}
            </div>
            <div className="flex items-center gap-3 mt-2 text-gray-600">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-1" />
                <span className="text-sm">
                  {format(new Date(offer.valid_from), 'MMM d, yyyy')} -{' '}
                  {format(new Date(offer.valid_to), 'MMM d, yyyy')}
                </span>
              </div>
              <div className="flex items-center">
                <Tag className="h-4 w-4 mr-1" />
                <code className="text-sm bg-gray-100 px-2 py-0.5 rounded">
                  {offer.code}
                </code>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-2">
            <FbButton
              variant="secondary"
              onClick={() => {
                navigator.clipboard.writeText(offerUrl)
                toast.success('Offer link copied to clipboard!')
              }}
            >
              <Clipboard className="h-4 w-4" />
              Copy Link
            </FbButton>
            <FbButton variant="secondary" onClick={() => setShowQRModal(true)}>
              <QrCode className="h-4 w-4" />
              Show QR Code
            </FbButton>
            <FbButton
              variant="secondary"
              onClick={() => setShowPreviewModal(true)}
            >
              <Eye className="h-4 w-4" />
              Preview
            </FbButton>
            <FbButton onClick={() => window.open(offerUrl, '_blank')}>
              <Share2 className="h-4 w-4" />
              View Public Page
            </FbButton>
            <FbButton variant="danger" onClick={() => setShowDeleteModal(true)}>
              <Trash2 className="h-4 w-4" />
              Delete
            </FbButton>
          </div>
        </div>

        {/* Offer Details and Key Performance Indicators */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
          {/* About This Offer Section */}
          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <h4 className="text-base font-medium text-gray-700 mb-3">
              About This Offer
            </h4>
            <div className="space-y-3">
              <div className="flex items-start gap-2">
                <Calendar className="h-4 w-4 text-gray-500 mt-1" />
                <div>
                  <div className="text-sm text-gray-500">Valid Period</div>
                  <div className="text-base font-medium">
                    {format(new Date(offer.valid_from), 'MMMM d, yyyy')} -
                    {format(new Date(offer.valid_to), 'MMMM d, yyyy')}
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <Tag className="h-4 w-4 text-gray-500 mt-1" />
                <div>
                  <div className="text-sm text-gray-500">Offer Code</div>
                  <div className="text-base font-medium font-mono">
                    {offer.code}
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <Info className="h-4 w-4 text-gray-500 mt-1" />
                <div>
                  <div className="text-sm text-gray-500">Offer Type</div>
                  <div className="text-base font-medium">
                    {formatOfferType(offer.offer_type)}
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <Info className="h-4 w-4 text-gray-500 mt-1" />
                <div>
                  <div className="text-sm text-gray-500">Company</div>
                  <div className="text-base font-medium">
                    {offer.terms.company_name}
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <Link className="h-4 w-4 text-gray-500 mt-1" />
                <div>
                  <div className="text-sm text-gray-500">Public Link</div>
                  <a
                    href={offerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-fb-dark-green hover:text-fb-dark-green-hover truncate block"
                  >
                    View offer page
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* KPI Grid */}
          <div className="xl:col-span-2">
            {/* Offer Summary Section */}
            <div className="mb-6 border border-gray-200 rounded-xl p-4">
              <h4 className="text-lg font-semibold text-gray-900 mb-3">
                Offer Summary
              </h4>
              <div className="grid grid-cols-3 gap-4">
                <StatCard
                  label="Total Claims"
                  value={offer.total_claims.toLocaleString()}
                  color="gray"
                  icon={<FileText className="h-4 w-4" />}
                  tooltip="Total number of claims submitted for this offer"
                />
                <StatCard
                  label="Total Payout"
                  value={`$${
                    offer.total_validated_payout?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) ?? '0.00'
                  }`}
                  color="gray"
                  icon={<DollarSign className="h-4 w-4" />}
                  tooltip="Total payout amount for all valid claims"
                />
                <StatCard
                  label="Total Valid Units"
                  value={
                    offer.total_validated_units?.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }) ?? '0'
                  }
                  color="gray"
                  icon={<BoxIcon className="h-4 w-4" />}
                  tooltip="Total number of units from valid claims"
                />
              </div>
            </div>

            {/* Claim Status Section */}
            <div className="mb-6 border border-gray-200 rounded-xl p-4">
              <h4 className="text-lg font-semibold text-gray-900 mb-3">
                Claim Status
              </h4>
              <div className="grid grid-cols-3 gap-4">
                <StatCard
                  label="Pending Validation"
                  value={offer.pending_validation_claims.toLocaleString()}
                  subValue={`(${getPercentage(offer.pending_validation_claims).replace('% of total', '%')})`}
                  color="yellow"
                  icon={<Clock className="h-4 w-4" />}
                  tooltip="Claims that are awaiting validation"
                />
                <StatCard
                  label="Invalid Claims"
                  value={offer.invalid_claims.toLocaleString()}
                  subValue={`(${getPercentage(offer.invalid_claims).replace('% of total', '%')})`}
                  color="red"
                  icon={<AlertCircle className="h-4 w-4" />}
                  tooltip="Claims that failed validation"
                />
                <StatCard
                  label="Valid Claims"
                  value={validClaimsTotal.toLocaleString()}
                  subValue={`(${validClaimsPercent}%)`}
                  color="green"
                  icon={<CheckCircle className="h-4 w-4" />}
                  tooltip="Total number of claims that passed validation"
                />
              </div>
            </div>

            {/* Payment Status Section */}
            <div className="border border-gray-200 rounded-xl p-4">
              <h4 className="text-lg font-semibold text-gray-900 mb-3">
                Payment Status
              </h4>
              <div className="grid grid-cols-2 gap-4">
                <StatCard
                  label="Paid"
                  value={offer.paid_claims.toLocaleString()}
                  subValue={`(${getPercentage(offer.paid_claims).replace('% of total', '%')})`}
                  color="green"
                  icon={<CheckCircle className="h-4 w-4" />}
                  tooltip="Valid claims that have been paid"
                />
                <StatCard
                  label="Awaiting Mfg Payment"
                  value={offer.unpaid_valid_claims.toLocaleString()}
                  subValue={`(${getPercentage(offer.unpaid_valid_claims).replace('% of total', '%')})`}
                  color="blue"
                  icon={<Clock className="h-4 w-4" />}
                  tooltip="Valid claims that are pending payment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Products & Discounts */}
      <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900 mb-6">
          Products & Discounts
        </h3>

        {/* Discount Tiers Section */}
        <div className="mb-8">
          <h4 className="text-base font-medium text-gray-800 mb-3 flex items-center">
            <DollarSign className="h-5 w-5 mr-1 text-fb-dark-green" />
            Global Discount Tiers
          </h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {offer.terms.tiers.map((tier, index) => (
              <DiscountTierCard
                key={index}
                tier={tier}
                unit={offer.terms.unit}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Product Details Section */}
        <div>
          <h4 className="text-base font-medium text-gray-800 mb-3 flex items-center">
            <Package className="h-5 w-5 mr-1 text-fb-dark-green" />
            Products
          </h4>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
            {offer.terms.products.map((product, index) => (
              <ProductTermsCard
                key={index}
                product={product}
                unit={offer.terms.unit}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
