import { createColumnHelper } from '@tanstack/react-table'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { JobStatusPill } from 'components/FbUI/JobStatusPill'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactRequestJobResponse } from 'models/contact_request'
import { useMemo } from 'react'
import { MdPersonOutline } from 'react-icons/md'
import { CampaignsCell } from '../../FbUI/StagePill'
import { CONTACT_REQUESTS_COLUMNS } from './contactRequestsColumnInfo'

export const useContactRequestsTableColumns = () => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}

  const columnHelper = createColumnHelper<ContactRequestJobResponse>()
  const contactRequestColumnHelper = createTableColumnHelper({
    columns: CONTACT_REQUESTS_COLUMNS,
    tooltips,
  })

  const cols = useMemo(
    () => [
      // Campaign Details Group
      columnHelper.accessor('sub_category', {
        ...contactRequestColumnHelper.retrieveColumn('sub_category', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        size: 200,
        cell: (info) => <p>{info.row.original.sub_category.name}</p>,
      }),
      columnHelper.accessor('campaign', {
        ...contactRequestColumnHelper.retrieveColumn('campaign'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return '-'
          return <CampaignsCell campaigns={[value]} />
        },
      }),

      // Request Details Group
      columnHelper.accessor('user', {
        ...contactRequestColumnHelper.retrieveColumn('user'),
        size: 120,
        cell: (info) => {
          const user = info.row.original.user
          return (
            <div className="d-flex align-items-center gap-1">
              <MdPersonOutline size={20} />
              <div>
                {user.first_name} {user.last_name}
              </div>
            </div>
          )
        },
      }),
      columnHelper.display({
        ...contactRequestColumnHelper.retrieveColumn('contact_companies'),
        id: 'contact_companies',
        size: 200,
        cell: (info) => {
          const contactCompanies = info.row.original.contact_companies

          if (!contactCompanies || contactCompanies.length === 0) return '-'

          const companyNames = contactCompanies.map((company) => company.name)
          const companyNamesSorted = companyNames.sort()
          const display = companyNamesSorted.slice(0, 25).join(', ')
          const totalCompanies = companyNamesSorted.length

          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalCompanies})`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      }),
      columnHelper.display({
        ...contactRequestColumnHelper.retrieveColumn('unique_companies_count'),
        id: 'unique_companies_count',
        size: 100,
        cell: (info) => {
          const contactCompanies = info.row.original.contact_companies
          const uniqueCount = contactCompanies
            ? new Set(contactCompanies.map((company) => company.id)).size
            : 0
          return <p>{uniqueCount}</p>
        },
      }),
      columnHelper.display({
        ...contactRequestColumnHelper.retrieveColumn('credits_used'),
        id: 'credits_used',
        size: 120,
        cell: (info) => {
          // Since credits_used is not in the type, access it dynamically
          // or use a fallback if needed
          const creditsUsed = (info.row.original as any).credits_used || 0
          return <p>{creditsUsed}</p>
        },
      }),
      columnHelper.accessor('status', {
        ...contactRequestColumnHelper.retrieveColumn('status'),
        size: 150,
        cell: (info) => (
          <div>
            <JobStatusPill
              statusValue={info.row.original.status.id}
              statusLabel={info.row.original.status.label}
            />
          </div>
        ),
      }),
      columnHelper.accessor('created', {
        ...contactRequestColumnHelper.retrieveColumn('created'),
        size: 150,
        cell: (info) => {
          const date = new Date(info.row.original.created)
          const datePart = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
          return datePart
        },
        enableSorting: true,
      }),
      columnHelper.accessor('modified', {
        ...contactRequestColumnHelper.retrieveColumn('modified'),
        size: 150,
        cell: (info) => {
          const date = new Date(info.row.original.modified)
          const datePart = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
          return datePart
        },
        enableSorting: true,
      }),
    ],
    [preferences, tooltips]
  )

  return cols
}
