import { Controller, useForm } from 'react-hook-form'
import apiService from '../../services/api'
import to from 'await-to-js'
import { Modal } from '../../components/UI/Modal/Modal'
import { handleFormError } from '../../utils/handleFormError'
import { useEffect, useMemo } from 'react'
import Select from '../../components/FormUtils/Select'
import { Campaign } from '../../models/campaign'
import { CompanyTypeSlug } from '../../models/companies'
import { useDataTableContext } from '../../components/DataTable'
import lodash from 'lodash'
import { ColorPicker, ColorpickerColor } from '../../components/ColorPicker'
import { TAG_COLORS } from '../../utils/constants'
import { TextareaInput } from '../../components/FormUtils/TextareaInput'
import { TextInput } from '../../components/FormUtils/TextInput'
import confirm from '../../components/dialogConfirm'

interface CampaignModalProps {
  isOpen: boolean
  onClose: () => void
  editingCampaign?: Campaign
  companyTypeSlug?: CompanyTypeSlug
  pushToCampaignOptions?: {
    tableQueryKey: string[]
    requestParams: Record<string, any>
    accessorKey: string
    data: any[]
    totalSelectedRows: number
  }
}

type CampaignCreateForm = {
  name: string
  type_slug: string
  product_id: number | undefined
  color: ColorpickerColor
  description: string
}

export function CreateEditCampaignModal({
  isOpen,
  onClose,
  editingCampaign,
  companyTypeSlug,
  pushToCampaignOptions,
}: CampaignModalProps) {
  const api = apiService()
  const selectedCampaignID = editingCampaign?.id
  const createCampaignMutation = api.useCreateCampaign()
  const editCampaignMutation = api.useEditCampaign()
  const assignToCampaignMutation = api.useAssignToCampaignMutation(
    pushToCampaignOptions?.tableQueryKey ?? [],
    pushToCampaignOptions?.accessorKey,
    companyTypeSlug
  )
  const dataTableContext = useDataTableContext()
  const { data: products } = api.useGetProducts()
  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const defaultValuesCampaign: CampaignCreateForm = {
    name: '',
    type_slug: companyTypeSlug ?? '',
    product_id: undefined,
    color: TAG_COLORS[0],
    description: '',
  }

  const campaignTypes = useMemo(() => {
    const types: { label: string; value: string }[] = []
    categories?.forEach((c) => {
      c.sub_categories.forEach((s) => {
        types.push({
          label: `${c.name} - ${s.name}`,
          value: s.slug,
        })
      })
    })
    return types
  }, [categories])

  const productOptions = useMemo(() => {
    return products?.map((p) => {
      return {
        value: p.id,
        label: p.name,
      }
    })
  }, [products])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<CampaignCreateForm>({
    defaultValues: defaultValuesCampaign,
    mode: 'onSubmit',
  })

  const onSubmitCreate = handleSubmit(async (values) => {
    if (
      pushToCampaignOptions &&
      pushToCampaignOptions.totalSelectedRows > 1000
    ) {
      const confirmed = await confirm(
        `Confirm creating a new campaign and assigning ${pushToCampaignOptions.totalSelectedRows} items?`,
        'Create a new campaign and assign.'
      )

      if (!confirmed) {
        return
      }
    }

    const [err, data] = await to(
      createCampaignMutation.mutateAsync({
        name: values.name,
        type_slug: values.type_slug as CompanyTypeSlug,
        product_id: values.product_id,
        color: values.color.background,
        description: values.description,
      })
    )

    if (err) {
      handleFormError(err, setError)
      return
    }

    if (data && pushToCampaignOptions) {
      await handleAssign(data)
    }

    reset(defaultValuesCampaign)
    onClose()
  })

  const onSubmitUpdate = handleSubmit(async (values) => {
    if (!selectedCampaignID) return

    const selectedProduct = products?.find((p) => p.id === values.product_id)

    const [err] = await to(
      editCampaignMutation.mutateAsync({
        id: selectedCampaignID,
        name: values.name,
        description: values.description,
        color: values.color.background,
        product: selectedProduct
          ? {
              id: selectedProduct.id,
              name: selectedProduct.name,
            }
          : undefined,
        product_id: values.product_id,
      })
    )

    if (err) {
      handleFormError(err, setError)
      return
    }

    reset(defaultValuesCampaign)
    onClose()
  })

  async function handleAssign(campaign: Campaign) {
    if (!pushToCampaignOptions) {
      return
    }

    const selectedRows = pushToCampaignOptions.data?.filter((_, i) => {
      return Boolean(dataTableContext?.state?.rowSelection?.[i])
    })

    const selectedChainProxyIds = selectedRows?.map((r: any) =>
      lodash.get(r, pushToCampaignOptions.accessorKey)
    ) as number[]

    if (!selectedChainProxyIds) {
      return
    }

    const body = {
      campaign_name: campaign.name,
      campaign_id: campaign.id,
      include_ids: dataTableContext?.state?.isAllRowsSelected
        ? []
        : selectedChainProxyIds,
      exclude_ids: [],
      campaign_color: campaign.color,
      filter_params: pushToCampaignOptions.requestParams,
    }

    await assignToCampaignMutation.mutateAsync(body)
  }

  useEffect(() => {
    if (editingCampaign) {
      reset({
        name: editingCampaign.name,
        type_slug: editingCampaign.type.slug,
        product_id: editingCampaign?.product?.id,
        color: TAG_COLORS.find((it) => it.background === editingCampaign.color),
        description: editingCampaign?.description,
      })
    }
  }, [editingCampaign])

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValuesCampaign)
    }
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      title={selectedCampaignID ? 'Edit campaign' : `Create new campaign`}
      description={
        selectedCampaignID
          ? 'Edit your campaign details.'
          : pushToCampaignOptions
            ? `Create a new campaign and assign ${pushToCampaignOptions.totalSelectedRows} items.`
            : 'Create a new campaign to track your marketing efforts.'
      }
      onOpenChange={(open) => !open && onClose()}
      loading={isSubmitting}
      blockAccept={!isValid}
      onAccept={selectedCampaignID ? onSubmitUpdate : onSubmitCreate}
      acceptButtonText={
        selectedCampaignID
          ? 'Update'
          : pushToCampaignOptions
            ? 'Create and Assign'
            : 'Create'
      }
    >
      <div className={'h-full'}>
        <TextInput
          name="name"
          control={control}
          type="text"
          label="Campaign Name"
          placeholder="Enter campaign name"
        />
        {!companyTypeSlug && (
          <Select
            name="type_slug"
            control={control}
            type="text"
            label="Campaign Type"
            placeholder="Select campaign type"
            options={campaignTypes}
            disabled={!!editingCampaign}
          />
        )}
        <Select
          name="product_id"
          control={control}
          type="text"
          label="Product"
          placeholder="Select product"
          options={productOptions}
        />
        <TextareaInput
          name="description"
          control={control}
          type="text"
          label="Description"
          placeholder="Description"
        />
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <ColorPicker
                label={'Color'}
                colors={TAG_COLORS}
                value={field.value}
                onChange={field.onChange}
              />
            )
          }}
          name={'color'}
        />
      </div>
    </Modal>
  )
}
