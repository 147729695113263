import { DECommonTable } from 'components/DataExpansionComponents/Common/CommonTable/CommonTable'
import { DataTableProvider } from 'components/DataTable'
import HospitalExportModal from 'components/Modals/ExportModal/HospitalExportModal'
import { ContactResponse } from 'models/contacts'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { FilterIdentifier } from '../../../../models/saved_view'
import { dataTableSortingStoreRepo } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { IColumnVisibility } from '../../../DataTable/types'
import { HospitalsFilterset } from './HospitalsFilterset'
import { useHospitalsTableCols } from './hospitalsTableColumns'
import { StandardSummaryResponse } from 'models/paginated-response'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'hospital_name',
  'total_hospital_beds',
  'total_hospital_days',
  'note_count',
  'contact_count',
  'deal_count',
  'street_addr',
  'city',
  'state',
  'zip_code',
  'provider_number',
  'cafeteria',
  'dietary',
  'dietary_under_contract',
  'sales_stages',
  'meals_per_day',
]

interface HospitalsTableProps {
  setTotalRowsCount?: (count: StandardSummaryResponse) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  hideCreateCompanyButton?: boolean
}

function HospitalsTableComponent(props: HospitalsTableProps) {
  const api = apiService()
  const { tableKey, ...rest } = props
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const columns = useHospitalsTableCols({
    sorting,
    inCampaigns: props.baseFilters?.campaigns,
  })

  return (
    <DECommonTable
      {...rest}
      tableKey={tableKey}
      columns={columns}
      sortableFields={sortableFields}
      filterIdentifier="HOSPITAL_TABLE"
      apiGetListAction={api.getHospitalsList}
      apiGetSummaryAction={api.getHospitalsSummary}
      companyTypeSlug="healthcare-hospitals"
      tableTypeSlug="healthcare-hospitals"
      searchPlaceholder="Search hospitals by name"
      FilterSet={HospitalsFilterset}
      ExportModal={HospitalExportModal}
    />
  )
}

export function HospitalsTable(
  props: HospitalsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      savedPresetsIdentifier="HOSPITAL_TABLE"
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Dining Services',
        'Hospital Insights',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <HospitalsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
