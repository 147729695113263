import React, { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useContactRequestContext } from './ContactRequestContext'
import { StepContainer } from './components/StepContainer'
import { NumericInput } from '../../FormUtils/NumericInput'
import { ClientDataTable } from '../../ClientDataTable/ClientDataTable'
import { LoadingStep } from './components/LoadingStep'

// Form schema for contact request
const formSchema = z.object({
  max_contacts: z
    .number()
    .min(1, { message: 'Please enter a number between 1 and 5.' })
    .max(5, { message: 'Please enter a number between 1 and 5.' }),
})

type FormValues = z.infer<typeof formSchema>

export const ConfirmStep: React.FC = () => {
  const {
    createJob,
    loading,
    error,
    getSelectedCompaniesInProgress,
    getSelectedCompaniesWithContacts,
    companiesInProgress,
    companiesWithContacts,
    campaignCompanies,
    handleClose,
    prevStep,
  } = useContactRequestContext()

  const [finalCompanies, setFinalCompanies] = useState<any[]>([])
  const [loadingCampaignCompanies, setLoadingCampaignCompanies] =
    useState(false)

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    defaultValues: {
      max_contacts: 1,
    },
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  // Get selected companies from previous steps - memoize to prevent recalculation on every render
  const selectedCompaniesInProgress = useMemo(
    () => getSelectedCompaniesInProgress(),
    [getSelectedCompaniesInProgress, companiesInProgress]
  )

  const selectedCompaniesWithContacts = useMemo(
    () => getSelectedCompaniesWithContacts(),
    [getSelectedCompaniesWithContacts, companiesWithContacts]
  )

  // Get IDs of companies that were NOT selected in previous steps - memoize to prevent recalculation
  const unselectedCompanyIds = useMemo(() => {
    const unselectedIds = new Set([
      ...companiesInProgress
        .filter((company) => !company.selected)
        .map((company) => company.id),
      ...companiesWithContacts
        .filter((company) => !company.selected)
        .map((company) => company.id),
    ])

    return unselectedIds
  }, [companiesInProgress, companiesWithContacts])

  // Process campaign companies and filter out unselected companies
  useEffect(() => {
    if (campaignCompanies.length > 0) {
      setLoadingCampaignCompanies(true)

      // Filter out companies that were explicitly unselected in previous steps
      const filteredCompanies = campaignCompanies.filter(
        (company) => !unselectedCompanyIds.has(company.id)
      )

      // Create a map of all companies from previous steps for quick lookup
      const previousCompaniesMap = new Map()
      selectedCompaniesInProgress.forEach((company) =>
        previousCompaniesMap.set(company.id, company)
      )
      selectedCompaniesWithContacts.forEach((company) =>
        previousCompaniesMap.set(company.id, company)
      )

      // Combine selected companies with filtered campaign companies
      const combinedCompanies = [
        ...selectedCompaniesInProgress,
        ...selectedCompaniesWithContacts,
        // Add campaign companies that weren't in previous steps
        ...filteredCompanies.filter(
          (company) => !previousCompaniesMap.has(company.id)
        ),
      ]

      // Remove duplicates by ID
      const uniqueCompanies = Array.from(
        new Map(
          combinedCompanies.map((company) => [company.id, company])
        ).values()
      )

      setFinalCompanies(uniqueCompanies)
      setLoadingCampaignCompanies(false)
    }
  }, [
    campaignCompanies,
    selectedCompaniesInProgress,
    selectedCompaniesWithContacts,
    unselectedCompanyIds,
  ])

  const onSubmit = (data: FormValues) => {
    // Get the IDs of final companies
    const companyIds = finalCompanies.map((company) => company.id)
    createJob(data.max_contacts, companyIds)
  }

  // Show loading spinner if we're still loading campaign companies
  if (loadingCampaignCompanies) {
    return <LoadingStep title="Request Contacts" currentStep={3} />
  }

  return (
    <StepContainer
      title="Request Contacts"
      subtitle={`You're requesting contacts for ${finalCompanies.length} companies. Please specify how many contacts you'd like to request per company.`}
      error={error}
      handleClose={handleClose}
      onBack={prevStep}
      onContinue={handleSubmit(onSubmit)}
      continueButtonText={loading ? 'Requesting...' : 'Request Contacts'}
      canContinue={isValid && !loading && finalCompanies.length > 0}
      loading={loading}
      currentStep={3}
      totalSteps={3}
    >
      {finalCompanies.length > 0 ? (
        <>
          <div className="mb-4">
            <h3 className="text-sm font-medium mb-2">
              Included Companies ({finalCompanies.length})
            </h3>
            <ClientDataTable
              columns={[
                {
                  header: 'Company',
                  accessorKey: 'name',
                },
                {
                  header: 'Contact Request Status',
                  accessorKey: 'status',
                },
              ]}
              data={finalCompanies.map((company) => ({
                id: company.id,
                name: company.name,
                status:
                  company.status ||
                  (company.last_requested_date
                    ? 'In Progress'
                    : company.last_found_date
                      ? 'Has Contacts'
                      : 'New Request'),
              }))}
            />
          </div>

          <form>
            <NumericInput
              label="How many contacts per company?"
              control={control}
              fieldName="max_contacts"
            />
          </form>
        </>
      ) : (
        <div className="text-center py-6">
          <p className="text-gray-500">
            No companies selected. Please go back and select companies.
          </p>
        </div>
      )}
    </StepContainer>
  )
}
