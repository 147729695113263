import { format } from 'date-fns'
import { RebateClaimDetail, RebateOfferProduct } from './tradespend_api'
import { BiArrowBack, BiChevronDown, BiLinkExternal } from 'react-icons/bi'
import styled from 'styled-components'
import { useState } from 'react'
import { ProductApprovalSection } from './components/ProductApprovalSection'

interface ClaimInvoicesViewProps {
  claim: RebateClaimDetail
  onBack: () => void
  productMap: Record<string, RebateOfferProduct>
  variantMap: Record<string, RebateOfferProduct['product_variants'][0]>
}

export function ClaimInvoicesView({
  claim,
  onBack,
  productMap,
  variantMap,
}: ClaimInvoicesViewProps) {
  const [expandedInvoices, setExpandedInvoices] = useState<number[]>([])

  const toggleExpand = (invoiceId: number) => {
    setExpandedInvoices((prev) =>
      prev.includes(invoiceId)
        ? prev.filter((id) => id !== invoiceId)
        : [...prev, invoiceId]
    )
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
        >
          <BiArrowBack />
          Back to Claim #{claim.id} Details
        </button>
      </div>

      <div className="space-y-4">
        {claim.invoices.map((invoice) => {
          const isExpanded = expandedInvoices.includes(invoice.id)
          return (
            <InvoiceCard key={invoice.id}>
              <InvoiceHeader onClick={() => toggleExpand(invoice.id)}>
                <div className="flex-1">
                  <InvoiceTitle>
                    {invoice.invoice_reference_id || `Invoice #${invoice.id}`}
                  </InvoiceTitle>
                  <InvoiceDate>
                    Uploaded on{' '}
                    {invoice.created
                      ? format(
                          new Date(invoice.created.replace(/-/g, '/')),
                          'MMM d, yyyy h:mm a'
                        )
                      : 'Date not available'}
                  </InvoiceDate>
                </div>
                <div className="flex items-center gap-4">
                  {invoice.files && invoice.files.length > 0 && (
                    <div className="flex gap-2">
                      {invoice.files.map((file, index) => (
                        <DownloadButton
                          key={file.id}
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(file.file, '_blank')
                          }}
                        >
                          <BiLinkExternal size={16} />
                          View File {invoice.files.length > 1 ? index + 1 : ''}
                        </DownloadButton>
                      ))}
                    </div>
                  )}
                  <ExpandIcon $isExpanded={isExpanded}>
                    <BiChevronDown size={24} />
                  </ExpandIcon>
                </div>
              </InvoiceHeader>

              {isExpanded && (
                <InvoiceContent>
                  <ContentSection>
                    <SectionTitle>Products</SectionTitle>
                    <ProductApprovalSection
                      productApprovalInfo={invoice.product_approval_info || {}}
                      productMap={productMap}
                      variantMap={variantMap}
                    />
                  </ContentSection>
                </InvoiceContent>
              )}
            </InvoiceCard>
          )
        })}
      </div>
    </div>
  )
}

const InvoiceCard = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: white;
  overflow: hidden;
`

const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background: #f9fafb;
  }
`

const ExpandIcon = styled.div<{ $isExpanded: boolean }>`
  transform: rotate(${(props) => (props.$isExpanded ? '180deg' : '0deg')});
  transition: transform 0.2s ease;
  color: #6b7280;
`

const InvoiceContent = styled.div`
  border-top: 1px solid #e5e7eb;
  padding: 16px;
`

const ContentSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 12px;
`

const InvoiceTitle = styled.div`
  font-weight: 600;
  color: #171c26;
`

const InvoiceDate = styled.div`
  font-size: 14px;
  color: #687182;
`

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: #374151;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: #111827;
  }
`
