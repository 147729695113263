import React from 'react'
import { useContactRequestContext } from './ContactRequestContext'
import { StepContainer } from './components/StepContainer'
import { SelectableCompaniesTable } from './components/SelectableCompaniesTable'
import { useSelection } from './hooks/useSelection'

export function FoundContactsStep() {
  const {
    companiesWithContacts,
    setCompaniesWithContacts,
    toggleCompanyWithContactsSelection,
    selectAllCompaniesWithContacts,
    loading,
    error,
    nextStep,
    prevStep,
    handleClose,
  } = useContactRequestContext()

  // Use the selection hook for more consistent behavior
  const { getContinueButtonText } = useSelection(
    companiesWithContacts,
    setCompaniesWithContacts
  )

  // Get the button text based on selection
  const continueButtonText = getContinueButtonText('existing contacts')

  return (
    <StepContainer
      title="Companies with Existing Contacts"
      subtitle={
        <>
          {companiesWithContacts.length} companies already have contacts found
          by First Bite. You can <strong>skip</strong> the companies and remove
          them from the request, or <strong>select</strong> the companies to
          include in the request and find additional contacts.
        </>
      }
      onBack={prevStep}
      onContinue={nextStep}
      handleClose={handleClose}
      continueButtonText={continueButtonText}
      loading={loading}
      error={error}
      currentStep={2}
      totalSteps={3}
    >
      <SelectableCompaniesTable
        companies={companiesWithContacts}
        toggleCompanySelection={toggleCompanyWithContactsSelection}
        selectAllCompanies={selectAllCompaniesWithContacts}
        dateColumn={{
          header: 'Last Found',
          accessorKey: 'lastFound',
          dateField: 'last_found_date',
        }}
        enableLogging={false}
      />
    </StepContainer>
  )
}
