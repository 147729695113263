import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function StatCard({
  label,
  value,
  color = 'gray',
  subValue,
  icon,
  tooltip,
}: {
  label: string
  value: number | string
  color?: 'gray' | 'green' | 'yellow' | 'red' | 'blue' | 'purple'
  subValue?: string
  icon?: React.ReactNode
  tooltip?: string
}) {
  const colorClasses = {
    gray: 'text-gray-600 bg-gray-100',
    green: 'text-green-600 bg-green-100',
    yellow: 'text-amber-600 bg-amber-100',
    red: 'text-red-600 bg-red-100',
    blue: 'text-blue-600 bg-blue-100',
    purple: 'text-purple-600 bg-purple-100',
  }

  const card = (
    <div className="bg-white rounded-xl border border-gray-200 p-4 hover:shadow-sm transition-all duration-200 ease-in-out">
      <div className="flex items-center">
        {/* Icon in circular background */}
        {icon && (
          <div
            className={`${colorClasses[color]} p-3 rounded-full mr-4 flex-shrink-0`}
          >
            {icon}
          </div>
        )}

        {/* Main content */}
        <div className="flex-grow">
          {/* Value */}
          <div
            className="text-3xl font-bold text-gray-900 truncate"
            title={String(value)}
          >
            {value}
          </div>

          {/* Label */}
          <div className="text-sm text-gray-500 truncate" title={label}>
            {label}
          </div>
        </div>

        {/* Percentage on right side */}
        {subValue && (
          <div
            className="text-sm text-gray-500 ml-2 self-start mt-2"
            title={subValue}
          >
            {subValue}
          </div>
        )}
      </div>
    </div>
  )

  if (tooltip) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${label}`}>{tooltip}</Tooltip>}
      >
        <div>{card}</div>
      </OverlayTrigger>
    )
  }

  return card
}

export default StatCard
