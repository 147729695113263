import { DataTypeColumn } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { RebateOffer } from './tradespend_api'

export const REBATE_OFFERS_COLUMNS: Record<keyof RebateOffer, DataTypeColumn> =
  {
    id: {
      header: 'ID',
      tooltipName: 'Offer ID',
      group: 'Offer Details',
    },
    name: {
      header: 'Name',
      tooltipName: 'Offer Name',
      group: 'Offer Details',
    },
    code: {
      header: 'Code',
      tooltipName: 'Offer Code',
      group: 'Offer Details',
    },
    state: {
      header: 'State',
      tooltipName: 'Offer State',
      group: 'Offer Details',
    },
    scope: {
      header: 'Scope',
      tooltipName: 'Offer Scope',
      group: 'Offer Details',
    },
    offer_type: {
      header: 'Type',
      tooltipName: 'Offer Type',
      group: 'Offer Details',
    },
    valid_from: {
      header: 'Valid From',
      tooltipName: 'Valid From Date',
      group: 'Offer Details',
    },
    valid_to: {
      header: 'Valid To',
      tooltipName: 'Valid To Date',
      group: 'Offer Details',
    },
    products: {
      header: 'Product IDs',
      tooltipName: 'Product IDs',
      group: 'Offer Details',
    },
    terms: {
      header: 'Terms',
      tooltipName: 'Offer Terms',
      group: 'Offer Details',
    },
    product_names: {
      header: 'Products',
      tooltipName: 'Product Names',
      group: 'Products',
    },
    total_enrollments: {
      header: 'Total Enrollments',
      tooltipName: 'Total Enrollments',
      group: 'Enrollment Stats',
    },
    total_claims: {
      header: 'Total Claims',
      tooltipName: 'Total Claims',
      group: 'Claims Stats',
    },
    pending_validation_claims: {
      header: 'Pending Claims',
      tooltipName: 'Pending Validation Claims',
      group: 'Claims Stats',
    },
    invalid_claims: {
      header: 'Invalid Claims',
      tooltipName: 'Invalid Claims',
      group: 'Claims Stats',
    },
    paid_claims: {
      header: 'Paid Claims',
      tooltipName: 'Paid Claims',
      group: 'Claims Stats',
    },
    unpaid_valid_claims: {
      header: 'Unpaid Valid Claims',
      tooltipName: 'Unpaid Valid Claims',
      group: 'Claims Stats',
    },
    total_validated_units: {
      header: 'Total Validated Units',
      tooltipName: 'Total Validated Units',
      group: 'Claims Stats',
    },
    total_validated_payout: {
      header: 'Total Validated Payout',
      tooltipName: 'Total Validated Payout',
      group: 'Claims Stats',
    },
    include_deleted: {
      header: 'Deleted',
      tooltipName: 'Deleted',
      group: 'Offer Details',
    },
    deleted_at: {
      header: 'Deleted At',
      tooltipName: 'Deleted At',
      group: 'Offer Details',
    },
  }
