const ATMOSPHERES_MAP = {
  has_outdoor: 'Outdoor Seating',
  has_happy_hour: 'Happy Hour',
  has_kids_menu: 'Kids Menu',
  // Temporary disabled
  // is_kid_friendly: 'Kid Friendly',
  // has_wifi: 'Wifi',
}

export const ATMOSPHERES_OPTIONS = Object.entries(ATMOSPHERES_MAP).map(
  ([value, label]) => ({
    value,
    label,
  })
)
