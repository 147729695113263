import { DataTypeColumn } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { ContactRequestJobResponse } from 'models/contact_request'

type CustomFields =
  | 'contact_companies'
  | 'unique_companies_count'
  | 'credits_used'

export const CONTACT_REQUESTS_COLUMNS = {
  // Campaign Details Group
  sub_category: {
    header: 'Type',
    group: 'Campaign Details',
    tooltipName: 'contact_requests_type',
  },
  campaign: {
    header: 'Campaign',
    group: 'Campaign Details',
    tooltipName: 'contact_requests_campaign',
  },

  // Request Details Group
  user: {
    header: 'Created By',
    group: 'Request Details',
    tooltipName: 'contact_requests_created_by',
  },
  contact_companies: {
    header: 'Companies',
    group: 'Request Details',
    tooltipName: 'contact_requests_companies',
  },
  unique_companies_count: {
    header: 'Unique Companies',
    group: 'Request Details',
    tooltipName: 'contact_requests_unique_companies',
  },
  credits_used: {
    header: 'Credits Used',
    group: 'Request Details',
    tooltipName: 'contact_requests_credits_used',
  },
  status: {
    header: 'Status',
    group: 'Request Details',
    tooltipName: 'contact_requests_status',
  },
  created: {
    header: 'Requested',
    group: 'Request Details',
    tooltipName: 'contact_requests_created',
  },
  modified: {
    header: 'Last Updated',
    group: 'Request Details',
    tooltipName: 'contact_requests_modified',
  },
} satisfies Partial<
  Record<keyof ContactRequestJobResponse | CustomFields, DataTypeColumn>
>
