import { useState } from 'react'
import { Badge } from 'react-bootstrap'
import { Layers } from 'lucide-react'

function ProductTermsCard({ product }: { product: any; unit: string }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm hover:shadow-md transition-all duration-200 ease-in-out">
      <div className="flex justify-between items-start gap-2 mb-3">
        <div className="min-w-0 flex-1">
          <h4
            className="text-lg font-semibold text-gray-900 truncate"
            title={product.product_name}
          >
            {product.product_name}
          </h4>
          {product.product_desc && (
            <p
              className="text-sm text-gray-600 mt-1 truncate"
              title={product.product_desc}
            >
              {product.product_desc}
            </p>
          )}
        </div>
      </div>

      {/* Variants Section */}
      <div className="mt-3">
        <button
          onClick={() => setExpanded(!expanded)}
          className="flex items-center text-sm font-medium text-fb-dark-green hover:text-fb-dark-green-hover mb-2"
        >
          <Layers className="h-4 w-4 mr-1" />
          Product Variants ({product.product_variants.length})
          <span className="ml-1">{expanded ? '▼' : '►'}</span>
        </button>

        {expanded && (
          <div className="space-y-2 mt-2 pl-2 border-l-2 border-fb-dark-green/20">
            {product.product_variants.map((variant: any) => (
              <div
                key={variant.variant_id}
                className="flex items-center justify-between bg-fb-dark-green/10 rounded-lg p-3"
              >
                <span className="text-sm text-gray-800 font-medium truncate">
                  {variant.variant_name}
                </span>
                <Badge bg="success" className="text-xs font-normal">
                  {variant.variant_format}
                </Badge>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductTermsCard
