import { DataTypeColumn } from '../../Common/commonColumnHelpers'
import { LodgingChain } from 'models/google_place_chain'
import { GooglePlaceDoorBase } from 'models/google_place_door'
import { getGooglePlaceDoorBaseColumns } from '../GooglePlaceBase/GooglePlaceDoorsBaseColumnsInfo'

export const LODGING_DOORS_COLUMNS = getGooglePlaceDoorBaseColumns(
  'Lodging'
) satisfies Partial<
  Record<keyof GooglePlaceDoorBase | keyof LodgingChain, DataTypeColumn>
>
