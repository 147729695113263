import { DataTableProvider } from 'components/DataTable'
import FbButton from 'components/FbUI/FbButton'
import { FBPage } from 'components/FbUI/Page/FBPage'
import {
  FBPageHeader,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from 'components/FbUI/Page/styles'
import { ALL_CAMPAIGNS_TABLE_KEY } from 'constants/tableQueryKeys'
import { CampaignsTable } from 'features/campaigns/CampaignsTable'
import { CreateEditCampaignModal } from 'features/campaigns/CreateEditCampaignModal'
import { Campaign } from 'models/campaign'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { featureFlagService } from 'utils/featureFlagService'

export function CampaignsPage() {
  const featureFlag = featureFlagService()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()

  function handleRowEdit(campaign: Campaign) {
    setShowModal(true)
    setSelectedCampaign(campaign)
  }

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    setSelectedCampaign(undefined)
  }

  if (!featureFlag.isLoading && !featureFlag.enableCampaigns) {
    navigate('/')
    return null
  }

  return (
    <DataTableProvider tableKey="campaign-overview-table">
      <FBPage>
        <FBPageHeader>
          <FBPageTitleRow>
            <FBPageTitle>
              <span className={'font-normal'}>Campaigns: </span> All
            </FBPageTitle>
          </FBPageTitleRow>
          <FbButton style={{ width: 200 }} onClick={() => openModal()}>
            Create Campaign
          </FbButton>
        </FBPageHeader>
        <FBPageTableContainer
          style={{
            height: 'calc(var(--base-page-table-height) - 47px)',
          }}
        >
          <CampaignsTable
            onEditCampaign={handleRowEdit}
            tableKey={ALL_CAMPAIGNS_TABLE_KEY}
          />
        </FBPageTableContainer>
      </FBPage>
      <CreateEditCampaignModal
        isOpen={showModal}
        onClose={hideModal}
        editingCampaign={selectedCampaign}
      />
    </DataTableProvider>
  )
}
