import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@tanstack/react-table'
import { useDataTableContext } from 'components/DataTable'
import { Checkbox } from 'components/FormUtils/Checkbox'
import { TextInput } from 'components/FormUtils/TextInput'
import dayjs from 'dayjs'
import { CompanyTypeSlug, TableTypeSlug } from 'models/companies'
import { DealCreate } from 'models/deals'
import { Fragment, useEffect, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { AiOutlineWarning } from 'react-icons/ai'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { featureFlagService } from 'utils/featureFlagService'
import { z } from 'zod'
import {
  DEAL_TABLE_KEY,
  getCampaignTableKey,
} from '../../../constants/tableQueryKeys'
import { formatInteger, formatUsdDecimal } from '../../../utils/formatting'
import { NumericInput } from '../../FormUtils/NumericInput'
import Select from '../../FormUtils/Select'
import { cn } from '../../UI/cn'
import { Modal } from '../../UI/Modal/Modal'
import confirm from 'components/dialogConfirm'

function getCreateDealFormSchema() {
  const dealSchema = {
    create: z.boolean(),
    product: z.number(),
    monthly_volume_override: z.number().min(0).optional().nullable(),
    monthly_revenue_override: z.number().min(0).optional().nullable(),
    close_date_override: z.string().optional().nullable(),
    account_owner: z.number().optional().nullable(),
    sales_stage: z.number().optional().nullable(),
    origin_campaign_id: z.number().optional().nullable(),
  }

  const dealsFormSchema = z
    .object({
      deals: z.array(z.object(dealSchema)),
    })
    .superRefine((v, ctx) => {
      const dealsToCreate = v.deals.filter((deal) => deal.create)

      if (dealsToCreate.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one deal must be selected',
          path: ['custom'],
        })
      }

      v.deals.forEach((deal) => {
        if (!deal.create) return

        if (!deal.monthly_revenue_override && !deal.monthly_volume_override) {
          return
        }

        if (!deal.monthly_volume_override || !deal.monthly_revenue_override) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'If any of the override fields are set, all must be set',
            path: ['custom'],
          })
        }
      })
    })

  return dealsFormSchema
}

export interface CreateDealModalForTableProps {
  data?: any[]
  idAccessor?: string
  targetCompanyId?: undefined
  handleClose: () => void
  onDealCreated?: () => void
  show: boolean
  submitButtonText?: string
  cancelButtonText?: string
  // These relate to optimistic UI updates
  queryKey?: string[]
  optimisticUiTarget:
    | 'companies'
    | 'chains'
    | 'create-company'
    | 'deals-section'
    | 'k12'
    | 'none'
  filterAndSortParams?: Record<string, any>
  originCampaignId?: number
  companyTypeSlug: CompanyTypeSlug
  tableTypeSlug: TableTypeSlug
}

export interface CreateDealModalProps
  extends Omit<CreateDealModalForTableProps, 'data' | 'targetCompanyId'> {
  targetCompanyId: number | undefined
  totalSelectedRows: number
  existingDealsByProduct?: Record<number, number>
  selectedRows?: any[]
  unselectedRows?: any[]
  rowSelection?: RowSelectionState
  isUserCreated?: boolean
  isAllRowsSelected?: boolean
}

export function CreateDealModalForTable(props: CreateDealModalForTableProps) {
  const {
    state: { rowSelection, isAllRowsSelected, totalSelectedRows },
  } = useDataTableContext()
  const unselectedRows = props?.data?.filter((_, i) => !rowSelection[i])
  const selectedRows = props?.data?.filter((_, i) => rowSelection[i])

  // {product_id: amount_of_deals}
  const existingDealsByProduct = useMemo(() => {
    const out: Record<number, number> = {}
    selectedRows?.forEach((i) =>
      i?.deals?.forEach((j: any) => {
        out[j.product] = (out?.[j.product] ?? 0) + 1
      })
    )
    return out
  }, [selectedRows])

  return (
    <CreateDealModal
      {...props}
      targetCompanyId={undefined}
      selectedRows={selectedRows}
      unselectedRows={unselectedRows}
      rowSelection={rowSelection}
      existingDealsByProduct={existingDealsByProduct}
      totalSelectedRows={totalSelectedRows}
      isAllRowsSelected={isAllRowsSelected}
    />
  )
}

export default function CreateDealModal(props: CreateDealModalProps) {
  const queryClient = useQueryClient()
  const api = apiService()
  const featureFlag = featureFlagService()
  const { data: stageOptions } = api.useGetSalesStageOptions()
  const { data: userOptions } = api.useGetUserOptions({ onlyActive: true })
  const { data: campaignOptions } = api.useGetCampaignsOptions(
    props.companyTypeSlug
  )

  const dealsFormSchema = getCreateDealFormSchema()
  type DealsFormValues = z.infer<typeof dealsFormSchema>

  const { data: predictions, isLoading: isLoading } =
    api.useGetProductPredictions({
      chainProxyId: props.targetCompanyId,
    })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm({
    resolver: zodResolver(dealsFormSchema),
    defaultValues: { deals: [] } as DealsFormValues,
    mode: 'onChange',
  })

  useEffect(() => {
    if (!predictions) return
    const deals = predictions.map((prediction) => {
      return {
        create: false,
        product: prediction.product.id,
        monthly_volume_override: prediction.deal?.monthly_volume_override,
        monthly_revenue_override: prediction.deal?.monthly_revenue_override,
        close_date_override: prediction.deal?.close_date_override
          ? dayjs(prediction.deal?.close_date_override).format('YYYY-MM-DD')
          : undefined,
        origin_campaign_id: props.originCampaignId,
      }
    })
    reset({ deals })
  }, [predictions])

  const mutation = useMutation({
    mutationFn: async ({
      deals,
      excludeIds,
      selectedIds,
    }: {
      deals: Partial<DealCreate>[]
      excludeIds: string[]
      selectedIds: string[]
      affectedRowsIndexes: number[]
    }) => {
      const body: any = {}

      if (props.targetCompanyId) {
        body['include_ids'] = [props.targetCompanyId]
      } else {
        body['include_ids'] = selectedIds
        body['exclude_ids'] = excludeIds

        // If all rows are selected, we don't need to send the chain_ids or contact_company_ids
        if (props.isAllRowsSelected) {
          body['include_ids'] = []
        }
      }

      return await api.commonCreateDeals(props.tableTypeSlug, {
        deals,
        filter_params: props.filterAndSortParams ?? {},
        include_ids: body.include_ids,
        exclude_ids: body.exclude_ids,
      })
    },
    // *** Disabling Optimistic UI for now, since it's not 100% ***
    // onMutate: async ({ affectedRowsIndexes, deals }) => {
    // await queryClient.cancelQueries({ queryKey: props.queryKey })
    // let prev: any
    // switch (props.optimisticUiTarget) {
    //   case 'chains':
    //     {
    //       prev = queryClient.getQueryData(props.queryKey ?? [])
    //       queryClient.setQueryData(props.queryKey ?? [], (old: any) => {
    //         if (affectedRowsIndexes) {
    //           affectedRowsIndexes.forEach((idx) => {
    //             const row = old.results?.[idx]
    //             deals.forEach((deal) => {
    //               const existingDeal = row?.deals?.findIndex(
    //                 (d: any) => d.product === deal.product_id
    //               )
    //               if (row.deals) {
    //                 if (existingDeal != -1) {
    //                   row.deals[existingDeal] = deal
    //                 } else {
    //                   row.deals.push(deal)
    //                 }
    //               } else {
    //                 row.deals = [deal]
    //               }
    //               row.deal_count = row.deals?.length
    //             })
    //           })
    //         }
    //         return old
    //       })
    //     }
    //     break
    // }
    // return { prev }
    // },
    onError: (err, newTodo, context) => {
      if (!context) return
      // queryClient.setQueryData(props.queryKey ?? [], context.prev)
      toast.error('Error creating deals')
    },
    onSuccess: (data) => {
      if (data.status === 202) {
        toast.info(
          data.data?.message ??
            'We are processing your request, you will be notified when it is done'
        )
      } else {
        toast.success('Deals created successfully')
      }
    },
    onSettled: () => {
      props.onDealCreated?.()
      props.handleClose()
      reset()
      queryClient.invalidateQueries({ queryKey: props.queryKey })
      queryClient.invalidateQueries({ queryKey: [DEAL_TABLE_KEY] })
      if (props.originCampaignId) {
        queryClient.invalidateQueries({
          queryKey: [
            getCampaignTableKey(DEAL_TABLE_KEY, props.originCampaignId),
          ],
        })
      }
    },
  })

  const onSubmit = handleSubmit(
    async (values) => {
      const dealsToCreate: Partial<DealCreate>[] = values.deals
        .filter((deal) => deal.create)
        .map((deal) => {
          return {
            product_id: deal.product,
            monthly_volume_override: deal.monthly_volume_override ?? undefined,
            monthly_revenue_override:
              deal.monthly_revenue_override ?? undefined,
            close_date_override: deal.close_date_override ?? undefined,
            sales_stage_id: deal.sales_stage ?? undefined,
            account_owner_id: deal.account_owner ?? undefined,
            origin_campaign_id: deal.origin_campaign_id ?? undefined,
          }
        })

      const excludeIds =
        props.unselectedRows?.map((r) => {
          return props.idAccessor?.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []

      const selectedIds =
        props.selectedRows?.map((r) => {
          return props.idAccessor?.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []

      const affectedRowsIndexes = props.rowSelection
        ? Object.keys(props.rowSelection).map((key) => parseInt(key))
        : []

      if (props.totalSelectedRows > 1000) {
        const confirmed = await confirm(
          `You are about to create ${props.totalSelectedRows} deals. This is a large number and may take a while to complete. Are you sure you want to proceed?`,
          'Create Deals in Bulk'
        )
        if (!confirmed) return
      }

      await mutation.mutateAsync({
        deals: dealsToCreate,
        excludeIds,
        selectedIds,
        affectedRowsIndexes,
      })
    },
    (err) => console.warn(err)
  )

  return (
    <Modal
      open={props.show}
      onOpenChange={(open) => !open && props.handleClose()}
      size="lg"
      blockAccept={isLoading}
      blockCancel={isLoading}
      onAccept={onSubmit}
      loading={isSubmitting}
      title="Add Deals"
      acceptButtonText={props.submitButtonText ?? 'Create Deals'}
      cancelButtonText={props.cancelButtonText}
      description="Deals allow you to track specific revenue opportunities throughout your sales pipeline. Each deal is done on a per product basis. For deals with unmatched companies, you can provide your own volume, revenue, and close date estimates."
    >
      {isLoading ? (
        <div className="d-flex justify-content-center overflow-hidden">
          <Spinner animation="border" />
        </div>
      ) : (
        <Fragment>
          <div className="gap-4">
            {predictions?.map((item, index) => {
              const active = watch(`deals.${index}.create`)
              const stage = watch(`deals.${index}.sales_stage`)
              const avgDaysToClose = stageOptions?.find(
                (it) => it.value === stage
              )?.original?.avg_days_to_close

              const date = new Date()
              if (avgDaysToClose) {
                date.setDate(date.getDate() + avgDaysToClose)
              }

              const existingDeals =
                props?.existingDealsByProduct?.[item.product?.id ?? -1] ?? 0

              return (
                <div
                  key={index}
                  className="w-full bg-white rounded-lg shadow-md p-4 border border-gray-200 mb-4"
                >
                  <div className="mb-4 flex flex-row gap-2 items-center">
                    <Checkbox
                      control={control}
                      name={`deals.${index}.create`}
                      wrapperStyle={{ width: 'unset' }}
                    />
                    <h3 className="text-lg text-gray-800 flex-1">
                      Product: <b>{item.product.name}</b>
                    </h3>
                  </div>

                  <div
                    className={cn('opacity-30', {
                      'opacity-100': active,
                    })}
                  >
                    <div
                      className={cn(
                        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 '
                      )}
                    >
                      <div>
                        <Select
                          options={stageOptions}
                          control={control}
                          name={`deals.${index}.sales_stage`}
                          label={'Sales Stage'}
                          disabled={!active}
                        />
                        <span className="text-sm text-gray-600 mt-2">
                          &nbsp;
                        </span>
                        <TextInput
                          label={'Close Date Override'}
                          control={control}
                          name={`deals.${index}.close_date_override`}
                          type="date"
                          disabled={!active}
                        />
                        <span className="text-sm text-gray-600 mt-2">
                          {stage ? (
                            <Fragment>
                              <b>Estimated Close Date:</b>{' '}
                              {date.toLocaleDateString('en-US')}
                            </Fragment>
                          ) : (
                            ' '
                          )}
                        </span>
                      </div>
                      <div>
                        <Select
                          options={userOptions}
                          control={control}
                          name={`deals.${index}.account_owner`}
                          label="Account Owner"
                          disabled={!active}
                        />
                        <span className="text-sm text-gray-600 mt-2">
                          &nbsp;
                        </span>
                        <NumericInput
                          control={control}
                          fieldName={`deals.${index}.monthly_revenue_override`}
                          label={'Monthly Revenue ($)'}
                          disabled={!active}
                        />
                        {item.predicted_revenue ? (
                          <span className="text-sm text-gray-600 mt-2">
                            <b>Predicted Monthly Revenue:</b>{' '}
                            {formatUsdDecimal(item.predicted_revenue)}
                          </span>
                        ) : null}
                      </div>
                      <div>
                        {featureFlag.enableCampaigns ? (
                          <Fragment>
                            <Select
                              options={campaignOptions}
                              control={control}
                              name={`deals.${index}.origin_campaign_id`}
                              label="Origin Campaign"
                              disabled={
                                !active || props.originCampaignId != undefined
                              }
                            />
                            <span className="text-sm text-gray-600 mt-2">
                              &nbsp;
                            </span>
                          </Fragment>
                        ) : null}
                        <NumericInput
                          control={control}
                          fieldName={`deals.${index}.monthly_volume_override`}
                          label={'Monthly Volume'}
                          disabled={!active}
                        />
                        {item.predicted_volume ? (
                          <span className="text-sm text-gray-600 mt-2">
                            <b>Predicted Monthly Volume:</b>{' '}
                            {formatInteger(item.predicted_volume ?? 0)} lbs
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {existingDeals ? (
                      <div className="pt-3 flex items-center gap-2">
                        <AiOutlineWarning size={14} color="#FCBD00" />
                        {existingDeals > 1 ? (
                          <span>
                            Some companies in your selection already have a deal
                            for this product.
                          </span>
                        ) : (
                          <span>
                            The company you selected already has a deal for this
                            product.
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
          {/*// @ts-expect-error ignore */}
          {errors?.['custom']?.message && (
            // @ts-expect-error ignore
            <div className="text-red-500 mt-4">{errors.custom.message}</div>
          )}
        </Fragment>
      )}
    </Modal>
  )
}
