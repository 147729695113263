import { Modal } from 'components/UI/Modal/Modal'
import { useRef } from 'react'
import QRCode from 'react-qr-code'
import { toast } from 'react-toastify'

function QRCodeModal({
  url,
  offerName,
  isOpen,
  onClose,
}: {
  url: string
  offerName: string
  isOpen: boolean
  onClose: () => void
}) {
  const qrRef = useRef<HTMLDivElement>(null)

  // Function to download QR code as PNG
  const downloadQRCode = () => {
    if (!qrRef.current) return

    // Get the SVG element
    const svg = qrRef.current.querySelector('svg')
    if (!svg) return

    // Create a canvas element
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Set canvas dimensions
    const scaleFactor = 4 // Make the downloaded image larger and clearer
    canvas.width = svg.clientWidth * scaleFactor
    canvas.height = svg.clientHeight * scaleFactor

    // Create an image from the SVG
    const svgData = new XMLSerializer().serializeToString(svg)
    const img = new Image()

    img.onload = () => {
      // Fill with white background
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw the image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      // Create download link
      const downloadLink = document.createElement('a')
      downloadLink.download = `${offerName.replace(/\s+/g, '-').toLowerCase()}-qrcode.png`
      downloadLink.href = canvas.toDataURL('image/png')
      downloadLink.click()

      toast.success('QR Code downloaded successfully!')
    }

    img.src =
      'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)))
  }

  return (
    <Modal
      open={isOpen}
      onOpenChange={(open: boolean) => !open && onClose()}
      title="Offer QR Code"
      acceptButtonText="Download QR Code"
      onAccept={downloadQRCode}
    >
      <div className="p-4">
        <p className="text-gray-600 mb-4">
          Use this QR code in your marketing materials to let customers easily
          access this offer by scanning with their phone.
        </p>
        <div
          ref={qrRef}
          className="bg-white p-6 rounded-lg flex flex-col items-center justify-center"
        >
          <div className="mb-4 text-center">
            <div className="text-sm text-gray-500 mb-1">Scan to view</div>
            <div className="font-medium text-gray-900">{offerName}</div>
          </div>
          <QRCode
            value={url}
            size={200}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          />
          <div className="mt-4 text-xs text-gray-400">{url}</div>
        </div>
      </div>
    </Modal>
  )
}

export default QRCodeModal
