import FbButton, { FbButtonVariants } from 'components/FbUI/FbButton'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '../BaseDropdown/dropdown-base'

export interface IDropdownItem {
  label: string | React.JSX.Element
  icon?: React.JSX.Element
  callback?: () => void
  disabled?: boolean
  disabledTooltipText?: string
  subContent?: React.JSX.Element
  hidden?: boolean
}

export interface IDropdownProps {
  items: IDropdownItem[]
  buttonLabel?: string | React.JSX.Element
  buttonIcon?: React.JSX.Element
  buttonVariant?: FbButtonVariants
  menuLabel?: string
  disabled?: boolean
  className?: string
  buttonComponent?: React.JSX.Element
}

export function Dropdown(props: IDropdownProps): React.JSX.Element {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={props.disabled} asChild>
        {props.buttonComponent ?? (
          <FbButton className={props.className} variant={props.buttonVariant}>
            <div className={'flex items-center justify-between gap-2'}>
              <div className={'flex items-center gap-2'}>
                {props.buttonIcon}
                <span className={'hidden xl:inline'}>{props.buttonLabel}</span>
              </div>
              <div>
                <BsChevronDown />
              </div>
            </div>
          </FbButton>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {props.menuLabel ? (
          <>
            <DropdownMenuLabel>{props.menuLabel}</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        ) : null}
        <DropdownMenuGroup>
          {props.items
            .filter((item) => !item.hidden)
            .map((item, idx) => {
              if (item.subContent) {
                return (
                  <DropdownMenuSub key={item.label.toString()}>
                    <DropdownMenuSubTrigger>
                      {item.icon}
                      {item.label}
                    </DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      {item.subContent}
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                )
              }

              const menuItem = (
                <DropdownMenuItem
                  key={idx}
                  onClick={() => !item.disabled && item.callback?.()}
                  disabled={item.disabled}
                >
                  {item.icon}
                  {item.label}
                </DropdownMenuItem>
              )

              return item.disabled && item.disabledTooltipText ? (
                <OverlayTrigger
                  key={item.label.toString()}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${item.label}`}>
                      {item.disabledTooltipText}
                    </Tooltip>
                  }
                >
                  <div>{menuItem}</div>
                </OverlayTrigger>
              ) : (
                menuItem
              )
            })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
