import {
  SelectFilter,
  SelectFilterProps,
} from 'components/Filters/fields/SelectFilter'
import { useEffect, useState } from 'react'
import { US_CITIES } from 'utils/us_cities'
import { US_STATES } from '../../../utils/us_states'

import { FilterStore } from '../FilterStore'

export function CityAndStateFilter({
  filterStore,
  stateSelectProps,
  citySelectProps,
  cityKey = 'city',
  stateKey = 'state',
}: {
  filterStore: FilterStore
  stateSelectProps: Omit<SelectFilterProps, 'filterStore'>
  citySelectProps: Omit<SelectFilterProps, 'filterStore'>
  cityKey?: string
  stateKey?: string
}) {
  const { pendingValueFilters, setPendingValueFilters } = filterStore()

  const [usCities, setUsCities] = useState<{ value: string; label: string }[]>()

  useEffect(() => {
    const selectedStates = pendingValueFilters[stateKey]?.map((f) => f.value)
    if (!selectedStates?.length) {
      setUsCities(US_CITIES)
      return
    }

    const availableCities = US_CITIES.filter((c) =>
      selectedStates.some((s) => s === c.value.split('_')[1])
    )

    // Use cityKey to get the selected cities
    const selectedCities = pendingValueFilters[cityKey]?.map((f) => f.value)

    if (selectedCities) {
      const citiesToRemove: string[] = []
      for (const city of selectedCities) {
        if (!availableCities.some((c) => c.value === city)) {
          citiesToRemove.push(city as string)
        }
      }
      if (citiesToRemove.length) {
        setPendingValueFilters(
          'city',
          pendingValueFilters[cityKey]?.filter(
            (f) => !citiesToRemove.some((c) => c === f.value)
          )
        )
      }
    }

    setUsCities(availableCities)
  }, [pendingValueFilters])

  return (
    <>
      <SelectFilter
        {...stateSelectProps}
        options={US_STATES}
        filterStore={filterStore}
      />
      <SelectFilter
        {...citySelectProps}
        options={usCities}
        filterStore={filterStore}
      />
    </>
  )
}
