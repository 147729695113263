import { Modal } from 'components/UI/Modal/Modal'
import { ColumnTable } from './ColumnTable'
import { ColumnDragAndDropItem } from './ColumnDragAndDropItem'
import React, { useEffect, useState } from 'react'
import { MdOutlineClearAll } from 'react-icons/md'
import TableButton from 'components/Buttons/TableButtons/TableButton'
import { HiOutlineViewColumns } from 'react-icons/hi2'
import _ from 'lodash'
import { ColumnPresetSelector } from './ColumnPresets'
import {
  Column,
  getAllColumns,
  getDefaultSelectedColumns,
  getHiddenColumns,
  getReadOnlyColumns,
} from '../../../stores/ColumnsStore/ColumnsStore'
import { useColumnStoreContext } from '../../../stores/ColumnsStore/useColumnsStoreContext'
import {
  DataTableColumnSort,
  dataTableSortingStoreRepo,
} from 'components/DataTable/DataTableSorting/DataTableSortingStore'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { cn } from 'components/UI/cn'

function prepareSelectedColumnsWithOrder(
  allColumns: Column[],
  orderedColumns: Column[],
  readOnlyColumns: Column[],
  hiddenColumns: Column[]
) {
  // Keep the order from readOnlyColumns and hiddenColumns, just adding the orderedColumns
  const selectedColumns = []
  for (const originalColumn of allColumns) {
    if (readOnlyColumns.some((column) => column.id === originalColumn.id)) {
      selectedColumns.push(originalColumn)
    } else if (
      hiddenColumns.some((column) => column.id === originalColumn.id)
    ) {
      selectedColumns.push(originalColumn)
    } else if (
      orderedColumns.some((column) => column.id === originalColumn.id)
    ) {
      selectedColumns.push(originalColumn)
    }
  }

  const selectedOrderedColumns: Column[] = []
  let sortedIndex = 0

  // Iterate through selectedColumns and insert elements from orderedColumns in the correct order
  for (const col of selectedColumns) {
    if (orderedColumns.some((orderedCol) => _.isEqual(orderedCol, col))) {
      // Find the correct element from the sorted order based on the orderedColumns
      selectedOrderedColumns.push(orderedColumns[sortedIndex])
      sortedIndex++
    } else {
      // Keep the columns that aren't part of orderedColumns in their original place
      selectedOrderedColumns.push(col)
    }
  }

  return selectedOrderedColumns
}

export const ColumnModal = () => {
  const [open, setOpen] = useState(false)

  const defaultSelectedColumns = getDefaultSelectedColumns()
  const allColumns = getAllColumns()
  const readOnlyColumns = getReadOnlyColumns()
  const hiddenColumns = getHiddenColumns()

  const [selectedColumns, setSelectedColumns] = useState<Column[]>([])
  const [orderedColumns, setOrderedColumns] = useState<Column[]>([])
  const setSelectedColumnsStore = useColumnStoreContext(
    (state) => state.setSelectedColumns
  )
  const tableKey = useColumnStoreContext((state) => state.tableKey)
  const { sorting, setSorting } = dataTableSortingStoreRepo.getStore(tableKey)()
  const [internalSorting, setInternalSorting] =
    useState<DataTableColumnSort[]>(sorting)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // Load initial columns
  useEffect(() => {
    if (
      defaultSelectedColumns &&
      !selectedColumns.length &&
      !orderedColumns.length
    ) {
      const filteredColumns = defaultSelectedColumns.filter(
        (column) => column.isEditable
      )
      setOrderedColumns(filteredColumns)
      setSelectedColumns(filteredColumns)
    }
  }, [])

  useEffect(() => {
    // We want to keep the order and just add/remove columns
    setOrderedColumns((columns) => {
      const newColumns = selectedColumns.filter(
        (column) => !columns.some((c) => c.id === column.id)
      )
      const existingFilteredColumns = columns.filter((column) =>
        selectedColumns.some((c) => c.id === column.id)
      )
      const orderedColumns = [...existingFilteredColumns, ...newColumns]

      // Sometimes, when the table columns
      // are changed, there could be a localstorage
      // leftover, so deduping just to be safe.
      const seen = new Set()
      const dedupedColumns = orderedColumns
        .reverse()
        .filter((column) => {
          const duplicate = seen.has(column.id)
          seen.add(column.id)
          return !duplicate
        })
        .reverse()
      return dedupedColumns
    })
  }, [selectedColumns])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = orderedColumns.findIndex((col) => col.id === active.id)
      const newIndex = orderedColumns.findIndex((col) => col.id === over.id)
      const newOrderedColumns = [...orderedColumns]
      const [movedItem] = newOrderedColumns.splice(oldIndex, 1)
      newOrderedColumns.splice(newIndex, 0, movedItem)
      setOrderedColumns(newOrderedColumns)
    }
  }

  const moveColumnUp = (index: number) => {
    if (index > 0) {
      const newOrderedColumns = [...orderedColumns]
      const temp = newOrderedColumns[index]
      newOrderedColumns[index] = newOrderedColumns[index - 1]
      newOrderedColumns[index - 1] = temp
      setOrderedColumns(newOrderedColumns)
    }
  }

  const moveColumnDown = (index: number) => {
    if (index < orderedColumns.length - 1) {
      const newOrderedColumns = [...orderedColumns]
      const temp = newOrderedColumns[index]
      newOrderedColumns[index] = newOrderedColumns[index + 1]
      newOrderedColumns[index + 1] = temp
      setOrderedColumns(newOrderedColumns)
    }
  }

  const handleApply = () => {
    const selectedOrderedColumns = prepareSelectedColumnsWithOrder(
      allColumns,
      orderedColumns,
      readOnlyColumns,
      hiddenColumns
    )
    setSelectedColumnsStore([...selectedOrderedColumns])
    setSorting(internalSorting)
    setOpen(false)
  }

  const clearAll = () => {
    setSelectedColumns([])
  }

  return (
    <Modal
      open={open}
      onOpenChange={(open) => !open && setOpen(false)}
      title={'Table Columns'}
      onAccept={handleApply}
      acceptButtonText={'Apply'}
      cancelButtonText="Cancel"
      size="lg"
      trigger={
        <TableButton
          text="Columns"
          icon={HiOutlineViewColumns}
          onClick={() => setOpen(true)}
        />
      }
      noScroll
    >
      <div>
        <ColumnPresetSelector
          currentCols={orderedColumns}
          setCurrentCols={(cols) => {
            setOrderedColumns(cols)
            setSelectedColumns(cols)
          }}
        />

        <hr className="my-3" />

        <div className="divide-gray-300 flex gap-2">
          <div className="flex-1 h-full flex flex-col">
            <p className="mb-2">
              <span className="bg-gray-300 px-2 py-1 rounded-md mr-2">1</span>
              <strong>All Columns</strong>
            </p>
            <p className="mb-2">Select columns to display</p>
            <ColumnTable
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
          </div>

          <div className="flex-1 h-full flex flex-col">
            <p className="mb-2">
              <span className="bg-gray-300 px-2 py-1 rounded-md mr-2">2</span>
              <strong>Selected Columns</strong>
            </p>
            <p className="mb-2">Adjust the display order of selected columns</p>
            <div className=" border border-gray-300 p-2">
              {readOnlyColumns.map((column) => (
                <ColumnDragAndDropItem
                  id={column.id}
                  key={column.id}
                  label={column.title}
                  readOnly
                  setSorting={setInternalSorting}
                  sorting={internalSorting}
                />
              ))}
              <div className="h-[300px] overflow-y-auto">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                  autoScroll={{
                    threshold: {
                      x: 0,
                      y: 0.2,
                    },
                    acceleration: 15,
                    interval: 5,
                  }}
                >
                  <SortableContext
                    items={orderedColumns.map((col) => col.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="flex-1 overflow-y-auto">
                      {orderedColumns.map((column, index) => (
                        <ColumnDragAndDropItem
                          id={column.id}
                          key={column.id}
                          label={column.title}
                          onRemove={() =>
                            setSelectedColumns((columns) =>
                              columns.filter((c) => c.id !== column.id)
                            )
                          }
                          setSorting={setInternalSorting}
                          sorting={internalSorting}
                          onMoveUp={() => moveColumnUp(index)}
                          onMoveDown={() => moveColumnDown(index)}
                          isFirst={index === 0}
                          isLast={index === orderedColumns.length - 1}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
              <div
                className={cn('flex items-center mt-2 justify-end', {
                  invisible: selectedColumns.length === 0,
                })}
              >
                <div
                  onClick={clearAll}
                  className="cursor-pointer flex items-center text-[#005A31]"
                >
                  Clear All{' '}
                  <MdOutlineClearAll
                    className="ml-1"
                    size={15}
                    color="#005A31"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export function getColumnSelectorRecipientId(tableKey: string) {
  return `${tableKey}-columns-modal`
}

export function ColumnSelectorRecipient(props: { tableKey: string }) {
  return <div id={getColumnSelectorRecipientId(props.tableKey)} />
}
