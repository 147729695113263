import { DoorProxyList } from 'models/restaurant'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

// Fields that are calculated, or not present in the model
type CustomFields =
  | 'zip'
  | 'total_ltv'
  | 'brand_ltv'
  | 'revenue_ltv'
  | 'pounds_per_year'
  | 'instagram_url'
  | 'instagram_followers'
  | 'instagram_following'
  | 'instagram_posts'
  | 'instagram_text_percentile'
  | 'reputation_data'
  | 'delivery_platforms'
  | 'menu'
  | 'dmanamecbsa'
  | 'income_medianhouseholdincome_hh_cnt'
  | 'pop_sqmi'
  | 'hh_gt100k'
  | 'employment_unemployed_rate_county_level_only_pct'
  | 'menu_matches'
  | 'company_tags'

export const DOORS_COLUMNS = {
  restaurant_name: {
    header: 'Restaurant Name',
    group: 'Opportunity Attributes',
  },
  chain_name: {
    header: 'Chain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_chain',
  },
  chain_count: {
    header: 'Chain Size',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_chain_count',
  },
  cuisine_50: {
    header: 'Cuisine',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_cuisine_50',
  },
  country: {
    header: 'Country',
    group: 'Location Data',
    tooltipName: 'opps_country',
  },
  address: {
    header: 'Address',
    group: 'Location Data',
    tooltipName: 'opps_full_address',
  },
  city: {
    header: 'City',
    group: 'Location Data',
    tooltipName: 'opps_city',
  },
  state: {
    header: 'State',
    group: 'Location Data',
    tooltipName: 'opps_state',
  },
  zip: {
    header: 'ZIP Code',
    group: 'Location Data',
    tooltipName: 'opps_zipcode',
  },
  total_ltv: {
    header: 'Lifetime Total Value',
    group: 'Predicted Values',
    tooltipName: 'opps_total_ltv',
  },
  brand_ltv: {
    header: 'Lifetime Brand Value',
    group: 'Predicted Values',
    tooltipName: 'opps_brand_ltv',
  },
  revenue_ltv: {
    header: 'Lifetime Revenue Value',
    group: 'Predicted Values',
    tooltipName: 'opps_revenue_ltv',
  },
  one_year_total_value: {
    header: '1yr Total Value',
    group: 'Predicted Values',
    tooltipName: 'opps_taro_plus_tabo',
  },
  taro: {
    header: '1yr Revenue Value',
    group: 'Predicted Values',
    tooltipName: 'opps_taro',
  },
  tabo: {
    header: '1yr Brand Value',
    group: 'Predicted Values',
    tooltipName: 'opps_tabo',
  },
  pounds_per_year: {
    header: '1yr Volume',
    group: 'Predicted Values',
    tooltipName: 'opps_pounds_per_year',
  },
  avg_rating: {
    header: 'Avg rating',
    group: 'Reputation Data',
    tooltipName: 'opps_avg_rating',
  },
  total_reviews_count: {
    header: 'Total Reviews',
    group: 'Reputation Data',
    tooltipName: 'opps_reviews_count',
  },
  expense_category: {
    header: 'Expense Cat.',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_expense_category',
  },
  velocity_group: {
    header: 'Predicted Traffic',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_velocity_group',
  },
  instagram_url: {
    header: 'Instagram Handle',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_url',
  },
  instagram_followers: {
    header: 'Instagram Followers',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_followers',
  },
  instagram_following: {
    header: 'Instagram Following',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_following',
  },
  instagram_posts: {
    header: 'Instagram Posts',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_posts',
  },
  instagram_text_percentile: {
    header: 'Instagram Ranking',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_text_percentile',
  },
  reputation_data: {
    header: 'Featured On',
    group: 'Reputation Data',
    tooltipName: 'opps_door_featured_on',
  },
  michelin_stars: {
    header: 'Michelin Stars',
    group: 'Reputation Data',
    tooltipName: 'opps_door_michelin_stars',
  },
  delivery_platforms: {
    header: 'Delivery Platforms',
    group: 'Reputation Data',
    tooltipName: 'opps_Delivery',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_domain',
  },
  website: {
    header: 'Website',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_website',
  },
  phone: {
    header: 'Phone',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_phone',
  },
  menu: {
    header: 'Menu',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu',
  },
  menu_size: {
    header: 'Menu Size',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu_size',
  },
  menu_matches: {
    header: 'Menu Matches',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu_matches',
  },
  dmanamecbsa: {
    header: 'DMA',
    group: 'Location Data',
    tooltipName: 'opps_dma',
  },
  menu_url: {
    header: 'Menu URL',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu_url',
  },
  google_place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_google_place_url',
  },
  ingredients_matrix: {
    header: 'Menu Characteristics',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_ingredients_matrix',
  },
  income_medianhouseholdincome_hh_cnt: {
    header: 'Median Household Income',
    group: 'Location Data',
    tooltipName: 'opps_median_hhi',
  },
  pop_sqmi: {
    header: 'Population Density',
    group: 'Location Data',
    tooltipName: 'opps_pop_density',
  },
  hh_gt100k: {
    header: 'High Household Income',
    group: 'Location Data',
    tooltipName: 'opps_hh_gt100k',
  },
  employment_unemployed_rate_county_level_only_pct: {
    header: 'Unemployment Rate',
    group: 'Location Data',
    tooltipName: 'opps_unemployment_rate',
  },
  company_tags: {
    header: 'Company Tags',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_tags',
  },
} satisfies Partial<
  Record<
    CustomFields | keyof DoorProxyList | keyof DoorProxyList['restaurant'],
    DataTypeColumn
  >
>
