import { Badge } from 'react-bootstrap'
import { RebateOfferState } from './tradespend_api'

const OFFER_STATE_LABELS: Record<RebateOfferState, string> = {
  PUBLISHED: 'Published',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
  PAUSED: 'Paused',
  DELETED: 'Deleted',
}

const OFFER_STATE_COLORS: Record<RebateOfferState, string> = {
  PUBLISHED: 'success',
  PENDING: 'warning',
  EXPIRED: 'info',
  PAUSED: 'secondary',
  DELETED: 'danger',
}

interface RebateOfferStatePillProps {
  state: RebateOfferState
}

export function RebateOfferStatePill({ state }: RebateOfferStatePillProps) {
  const label = OFFER_STATE_LABELS[state]
  const bg = OFFER_STATE_COLORS[state]

  return <Badge bg={bg}>{label}</Badge>
}
