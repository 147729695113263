import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { DEAL_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsColumnInfo'

export default function DealExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportDeals = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportDeals(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Deals - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const dealColumnHelper = createExportColumnHelper({
    columns: DEAL_COLUMNS,
  })

  const columns = [
    {
      title: 'Company Attributes',
      elements: [
        dealColumnHelper.retrieveColumn('company'),
        dealColumnHelper.retrieveColumn('category'),
        dealColumnHelper.retrieveColumn('sub_category'),
        dealColumnHelper.retrieveColumn('company_size'),
        dealColumnHelper.retrieveColumn('taglist'),
        dealColumnHelper.retrieveColumn('contact_tags'),
        dealColumnHelper.retrieveColumn('distributors'),
        dealColumnHelper.retrieveColumn('note_count'),
        dealColumnHelper.retrieveColumn('contact_count'),
        dealColumnHelper.retrieveColumn('days_since_last_modified'),
        dealColumnHelper.retrieveColumn('estimated_meals_per_day'),
        dealColumnHelper.retrieveColumn('operational_schools'),
      ],
    },
    {
      title: 'Campaigns and Deals',
      elements: [
        dealColumnHelper.retrieveColumn('product'),
        dealColumnHelper.retrieveColumn('account_owner'),
        dealColumnHelper.retrieveColumn('sales_stage'),
        dealColumnHelper.retrieveColumn('last_datetime_stage_changed'),
        dealColumnHelper.retrieveColumn('expected_days_to_close'),
        dealColumnHelper.retrieveColumn('close_date_override'),
        dealColumnHelper.retrieveColumn('modified'),
        dealColumnHelper.retrieveColumn('created'),
      ],
    },
    {
      title: 'Forecast',
      elements: [
        dealColumnHelper.retrieveColumn('monthly_volume_predicted'),
        dealColumnHelper.retrieveColumn('monthly_volume_override'),
        dealColumnHelper.retrieveColumn('monthly_revenue_predicted'),
        dealColumnHelper.retrieveColumn('monthly_revenue_override'),
        dealColumnHelper.retrieveColumn('annual_volume'),
        dealColumnHelper.retrieveColumn('annual_revenue'),
        dealColumnHelper.retrieveColumn('annual_brand_opp'),
        dealColumnHelper.retrieveColumn('annual_total'),
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Deals Download"
      subTitle="Download up to 10,000 deals at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Deals"
      columns={columns}
      exportAPIAction={exportDeals}
      {...props}
    />
  )
}
