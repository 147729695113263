import { Campaign } from './campaign'
import { SalesStage } from './chains'
import { CompanyDistributor, SubCategory } from './companies'
import { Deal } from './deals'
import { Tag } from './tags'
import { User } from './user'

export interface IContactCompany {
  contact_count: number
  note_count: number
  deal_count: number
  taglist: Tag[] | undefined
  id: number
  chain: any
  university_id: number
  hospital_id: number
  nursing_home_id: number
  k12district_id: number
  domain: string
  name: string
  count: number
  category: string
  category_id: number
  sub_category: SubCategory
  sub_category_id: number
  created: string
  note: IContactCompanyNote
  distributors?: CompanyDistributor[]
  campaigns: Campaign[]
  address: string
  city: string
  state: string
  country: string
  zipcode: string
  phone_number: string
  extension: string
  deals?: Deal[]
  google_place_url?: string
}

export interface IContactCompanyNote {
  sales_stage: SalesStage
  taglist: Tag[]
  account_owner: User
  notes: string
}

export interface IContactCompanyNoteCreateRequest
  extends Omit<
    IContactCompanyNote,
    'account_owner' | 'taglist' | 'sales_stage'
  > {
  sales_stage: number
  taglist?: number[] | 'REMOVE'
  account_owner: number
}

export interface IContactCompanyCreateRequest {
  domain: string
  name: string
  category: number
  sub_category: number
  taglist?: number[] | 'REMOVE'
  distributors?: number[]
  address?: string
  country: string
  city?: string
  state?: string
  zipcode?: string
  phone_number?: string
  extension?: string
  google_place_url?: string
}

export interface IContactCompanyUpdateRequest
  extends Omit<IContactCompanyCreateRequest, 'name'> {}

export interface NoteAttachment {
  id: number
  file: string
  original_filename: string
}

export interface INote {
  id: number
  content: string
  user: User
  attachments?: NoteAttachment[]
  modified: string
  created: string
}

export interface IDoorProxyWrite {
  name: string
  domain: string
  address: string
  country: string
  city: string
  state: string
  zipcode: string
  phone_number?: string
  extension?: string
}

export interface IDoorProxy extends IDoorProxyWrite {
  id: number
  chain_proxy: number
}

export type IDoorProxyCreateRequest = IDoorProxyWrite
export type IDoorProxyEditRequest = IDoorProxyWrite

export interface IDoorProxyListResponse {
  count: number
  results: IDoorProxy[]
}

// UI should have knowledge of available contact company types
// without needing to fetch from server
export enum ContactCompanyType {
  Chain = 'restaurant',
  University = 'university',
  K12District = 'k12district',
  UserGenerated = 'user_generated',
  Hospital = 'hospital',
  NursingHome = 'nursinghome',
  Lodging = 'lodging',
}
