import { zodResolver } from '@hookform/resolvers/zod'
import { ColumnDef } from '@tanstack/react-table'
import to from 'await-to-js'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import dialogConfirm from 'components/dialogConfirm'
import { Cuisine50Options } from 'models/cuisine_50'
import { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import { z } from 'zod'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'
import FbButton from '../../../components/FbUI/FbButton'
import * as S from './styles'

import { DataTableContainer } from 'components/DataTable/UI'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { ProductModal } from 'components/ProductFlow/ProductModal'
import {
  formatPercentage,
  formatTwoDecimal,
  formatUsdDecimal,
} from 'utils/formatting'
import { Product, Variant } from '../../../models/product'
import { handleFormError } from '../../../utils/handleFormError'

export interface ProductFormValues {
  name: string
  format: string
  product_format: string
  price_per_case: number
  mfg_avg_list_price_per_lb: number
  avg_serving_size_per_lb: number
  est_monthly_churn_rate: number
  value_per_consumer_new_trial: number
  lbs_per_case: number
  current_aided_awareness: number
  current_national_trial_perc: number
  value_per_consumer_new_informed: number
  estimated_sales_potential: Record<string, number>
  unit: string
  variants?: Variant[]
}

export const productShape = z.object({
  name: z.string().min(1, 'Name is required').max(60, 'Name is too long'),
  format: z.string().optional(),
  product_format: z.string().min(1, 'Product format is required'),
  avg_serving_size_per_lb: z
    .number({ invalid_type_error: 'Average serving size is required' })
    .gt(0, 'Average serving size must be greater than 0'),
  mfg_avg_list_price_per_lb: z
    .number({ invalid_type_error: 'Average revenue per unit is required' })
    .gt(0, 'Average revenue must be greater than 0'),
  est_monthly_churn_rate: z
    .number({ invalid_type_error: 'Monthly churn rate is required' })
    .min(1, 'Churn rate must be greater than 0')
    .max(100, 'Churn rate cannot exceed 100%')
    .default(1),
  unit: z.enum(['lbs', 'kg', 'oz']),
  value_per_consumer_new_trial: z
    .number()
    .min(0, 'Value cannot be negative')
    .nullable()
    .optional(),
  lbs_per_case: z
    .number()
    .min(0, 'Serving size cannot be negative')
    .nullable()
    .optional(),
  current_aided_awareness: z
    .number()
    .min(0, 'Awareness cannot be negative')
    .max(100, 'Awareness cannot exceed 100%')
    .nullable()
    .optional(),
  current_national_trial_perc: z
    .number()
    .min(0, 'Trial percentage cannot be negative')
    .max(100, 'Trial percentage cannot exceed 100%')
    .nullable()
    .optional(),
  variants: z
    .array(
      z
        .object({
          id: z.number().optional().nullable(),
          name: z.string().min(1, 'Name is required'),
          description: z.string().optional().nullable(),
          gtin: z.string().nullable(),
          sku: z.string().nullable(),
          units_per_case: z
            .union([z.number(), z.literal('')])
            .optional()
            .nullable(),
          case_weight: z
            .union([z.number(), z.literal('')])
            .optional()
            .nullable(),
          case_weight_unit: z.string().default('lbs'),
          unit_weight: z
            .union([z.number(), z.literal('')])
            .optional()
            .nullable(),
          unit_weight_unit: z.string().default('lbs'),
          format_pack_size: z.string().nullable(),
          distributor_codes: z
            .array(
              z.object({
                id: z.number().optional(),
                distributor_name: z
                  .string()
                  .min(1, 'Distributor name is required'),
                distributor_code: z
                  .string()
                  .min(1, 'Distributor code is required'),
              })
            )
            .optional(),
        })
        .partial()
    )
    .optional()
    .nullable(),
})

const espValidationSchema = z.object({
  default_sales: z
    .number({ invalid_type_error: 'Default sales value is required' })
    .min(0, 'Default sales cannot be negative'),
  cuisine_sales: z.record(
    z.string(),
    z.number().min(0, 'Sales estimates cannot be negative')
  ),
})

const defaultValues: ProductFormValues = {
  name: '',
  format: '',
  product_format: '',
  price_per_case: 0,
  mfg_avg_list_price_per_lb: 0,
  avg_serving_size_per_lb: 0,
  est_monthly_churn_rate: 1,
  value_per_consumer_new_trial: 0,
  lbs_per_case: 0,
  current_aided_awareness: 0,
  current_national_trial_perc: 0,
  value_per_consumer_new_informed: 0,
  estimated_sales_potential: {},
  unit: 'lbs',
  variants: [],
}

const defaultESPValues: Product['estimated_sales_potential'] = {}
Cuisine50Options.forEach((cuisine) => {
  defaultESPValues[cuisine.value] = 0
})

export function ProductTable() {
  const [step, setStep] = useState(0)
  const api = apiService()

  const { data, isLoading, refetch } = api.useProducts()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedProductID, setSelectedProductID] = useState<number>()

  const espForm = useForm({
    defaultValues: defaultESPValues,
    resolver: zodResolver(espValidationSchema),
    mode: 'onChange',
  })

  const productForm = useForm<ProductFormValues>({
    resolver: zodResolver(productShape),
    mode: 'all',
    defaultValues,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
  })

  const onErrorGoToRightStep = (error: Record<string, any>) => {
    const firstStepFields = [
      'name',
      'avg_serving_size_per_lb',
      'mfg_avg_list_price_per_lb',
      'est_monthly_churn_rate',
      'value_per_consumer_new_trial',
      'lbs_per_case',
      'current_aided_awareness',
      'current_national_trial_perc',
    ]
    const secondStepFields = ['default_sales', 'cuisine_sales']
    const errorKeys = Object.keys(error)
    if (errorKeys.some((key) => firstStepFields.includes(key))) {
      setStep(0)
    } else if (errorKeys.some((key) => secondStepFields.includes(key))) {
      setStep(1)
    }
  }

  const onSubmitCreate = productForm.handleSubmit(async (values) => {
    let esp = {} as Product['estimated_sales_potential']
    await espForm.handleSubmit((data) => (esp = data), console.warn)()

    const [err] = await to(
      api.postProduct({
        ...values,
        format: values.product_format,
        estimated_sales_potential: esp,
        variants: values.variants || [],
      })
    )
    if (err) {
      handleFormError(err, productForm.setError)
      onErrorGoToRightStep(err)
      return
    }

    await refetch()
    hideModal()
  })

  const onSubmitUpdate = async () => {
    const values = productForm.getValues()
    let esp = {} as Product['estimated_sales_potential']
    await espForm.handleSubmit((data) => (esp = data), console.warn)()

    if (!selectedProductID) return

    Object.keys(esp).forEach((key) => {
      const curValue = esp[key]
      esp[key] = curValue ? curValue : 0
    })

    const [err] = await to(
      api.putProduct(selectedProductID, {
        ...values,
        format: values.product_format,
        estimated_sales_potential: esp,
        variants: values.variants || [],
      })
    )
    if (err) {
      handleFormError(err, productForm.setError)
      onErrorGoToRightStep(err)
      return
    }

    await refetch()
    hideModal()
  }

  // TODO format these correctly
  const columns = useMemo<ColumnDef<Product, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Product Name',
        size: 200,
      },
      {
        accessorKey: 'avg_serving_size_per_lb',
        header: 'Avg. Quantity per Unit',
        size: 150,
        cell: (info) => {
          const unit = info.row.original.unit || 'lbs'
          const value = info.getValue()
          return value ? `${formatTwoDecimal(value)} ${unit}` : '-'
        },
      },
      {
        accessorKey: 'mfg_avg_list_price_per_lb',
        header: 'Avg. Revenue per Unit',
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatUsdDecimal(info.getValue()) : '-'
        },
      },
      {
        accessorKey: 'est_monthly_churn_rate',
        header: 'Est. Monthly Churn Rate',
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          return value ? `${formatPercentage(info.getValue())} %` : '-'
        },
      },
      {
        accessorKey: 'estimated_sales_potential.default_sales',
        header: 'Avg. Units Sold per Month',
        size: 150,
        cell: (info) => {
          const unit = info.row.original.unit || 'lbs'
          const value = info.getValue()
          return value
            ? `${formatTwoDecimal(value)} (${formatTwoDecimal(info.row.original.avg_serving_size_per_lb * value)} ${unit})`
            : '-'
        },
      },
      {
        header: 'Variants',
        size: 150,
        cell: (info) => {
          const variants = info.row.original.variants
          return variants ? variants.length : 0
        },
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 100,
        meta: { rightAlign: true },
        cell: ({ row }) => (
          <ActionButtons
            className={'justify-end pr-0'}
            onDelete={() => handleRowDelete(row.index)}
            onEdit={() => handleRowEdit(row.index)}
          />
        ),
      },
    ],
    [data]
  )

  function hideModal() {
    setShowModal(false)
    productForm.reset(defaultValues)
    espForm.reset(defaultESPValues)
    setSelectedProductID(undefined)
  }

  async function handleRowDelete(index: number) {
    const id = data?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this product? This action will also delete any deals associated with the product.',
        'Delete Product'
      )
    ) {
      try {
        await api.deleteProduct(id)
      } catch {
        /* empty */
      }
      await refetch()
    }
  }

  function handleRowEdit(index: number) {
    const selectedProduct = data?.find(
      (product) => product.id === data?.[index]?.id
    )

    if (!selectedProduct) return

    setShowModal(true)
    setSelectedProductID(data?.[index]?.id)

    // Structure ESP form values
    const espInitialValues = {
      default_sales: selectedProduct.estimated_sales_potential.default_sales,
      cuisine_sales: selectedProduct.estimated_sales_potential.cuisine_sales,
    }

    espForm.reset(espInitialValues)
    if (data) productForm.reset(selectedProduct)
  }

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
          width: '100%',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  const productDescriptionText = `
  Current LTV Drivers: Cuisine, review composition, cross-platform ratings, menu dynamics,
  expense quartiles, chain size, demographics, population density, repeater rate, awareness effect,
  user inputs (churn, servings/lb, etc)
  Allow up to an hour for LTV to calculate when updated.
  `

  return (
    <DataTableContainer>
      <ClientDataTable
        tableHeader={
          <S.SettingsTableHeader>
            <S.TableDescriptionText>
              <strong>First Bite LTV Version 1.4</strong>
              <OverflownTextTooltip
                tooltipText={productDescriptionText}
                maxLines={4}
                placement="bottom"
              >
                {productDescriptionText}
              </OverflownTextTooltip>
            </S.TableDescriptionText>
            <FbButton onClick={() => setShowModal(true)}>
              Create Product
            </FbButton>
          </S.SettingsTableHeader>
        }
        height={'100%'}
        data={data}
        columns={columns}
      />

      <ProductModal
        show={showModal}
        handleClose={hideModal}
        selectedProductID={selectedProductID}
        defaultValues={defaultValues}
        productForm={productForm}
        espForm={espForm}
        onSubmit={async () =>
          selectedProductID ? onSubmitUpdate() : onSubmitCreate()
        }
        step={step}
        setStep={setStep}
      />
    </DataTableContainer>
  )
}
