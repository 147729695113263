import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { DataTable, DataTableProvider } from 'components/DataTable'
import { EmailValidityPill } from 'components/FbUI/EmailValidityPill'
import { FbLink } from 'components/FbUI/FbLink'
import { JobStatusPill } from 'components/FbUI/JobStatusPill'
import { RequestedContactResponse } from 'models/contact_request'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { default as styled } from 'styled-components'
import { getCompanyUrl } from 'utils/getCompanyUrl'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'

const TABLE_KEY = 'requested-contacts-table'
const PAGE_SIZE = 100

type SortableFields = (keyof RequestedContactResponse | string)[]

const sortableFields: SortableFields = ['chain_proxy__name', 'status']

interface RequestedContactsTableProps {
  jobId: number
  height?: number
}

function RequestedContactsTableComponent({
  jobId,
  height,
}: RequestedContactsTableProps) {
  const api = apiService()
  const columnHelper = createColumnHelper<RequestedContactResponse>()

  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
  }, [jobId])

  const { sorting } = dataTableSortingStoreRepo.getStore(
    'requested-contacts-table'
  )()

  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: `${TABLE_KEY}-${jobId}`,
    filterParams: sortParams,
    page: page,
  })

  const { isLoading, data, isFetching } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      const response = await api.fetchRequestedContacts(
        jobId,
        {
          ...sortParams,
          limit: PAGE_SIZE,
          page: page,
        },
        signal
      )
      return response
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
  })

  const columns: ColumnDef<RequestedContactResponse, any>[] = useMemo(
    () => [
      columnHelper.accessor('chain_proxy', {
        header: 'Company',
        cell: (info) => (
          <FbLink
            to={`${getCompanyUrl(info.getValue())}`}
            target="_blank"
            rel="noreferrer"
          >
            {info.getValue()?.name}
          </FbLink>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => (
          <JobStatusPill
            statusValue={info.getValue().id}
            statusLabel={info.getValue().label}
          />
        ),
      }),
      columnHelper.accessor('title', {
        header: 'Title',
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('seniority', {
        header: 'Seniority',
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('department', {
        header: 'Department',
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('first_name_found', {
        header: 'First Name Found',
        cell: (info) => (info.getValue() ? <FaCheck /> : '-'),
      }),
      columnHelper.accessor('last_name_found', {
        header: 'Last Name Found',
        cell: (info) => (info.getValue() ? <FaCheck /> : '-'),
      }),
      columnHelper.accessor(
        (row) => ({
          found: row.email_work_found,
          validity: row.email_work_validity,
        }),
        {
          id: 'work_email',
          header: 'Work Email Found',
          cell: (info) => {
            const { found, validity } = info.getValue()
            return (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {found ? <FaCheck /> : '-'}
                {validity !== null && validity !== undefined && (
                  <EmailValidityPill emailValidity={validity} />
                )}
              </div>
            )
          },
        }
      ),
      columnHelper.accessor(
        (row) => ({
          found: row.email_personal_found,
          validity: row.email_personal_validity,
        }),
        {
          id: 'personal_email',
          header: 'Personal Email Found',
          cell: (info) => {
            const { found, validity } = info.getValue()
            return (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {found ? <FaCheck /> : '-'}
                {validity !== null && validity !== undefined && (
                  <EmailValidityPill emailValidity={validity} />
                )}
              </div>
            )
          },
        }
      ),
      columnHelper.accessor(
        (row) => ({
          found: row.email_generic_found,
          validity: row.email_generic_validity,
        }),
        {
          id: 'generic_email',
          header: 'Other Email Found',
          cell: (info) => {
            const { found, validity } = info.getValue()
            return (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {found ? <FaCheck /> : '-'}
                {validity !== null && validity !== undefined && (
                  <EmailValidityPill emailValidity={validity} />
                )}
              </div>
            )
          },
        }
      ),
      columnHelper.accessor('phone_number_found', {
        header: 'Phone Number Found',
        cell: (info) => (info.getValue() ? <FaCheck /> : '-'),
      }),
      columnHelper.accessor('linkedin_url_found', {
        header: 'LinkedIn Found',
        cell: (info) => (info.getValue() ? <FaCheck /> : '-'),
      }),
    ],
    [columnHelper]
  )

  const handlePaginationClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  return (
    <TableContainer>
      <div onClick={handlePaginationClick}>
        <DataTable
          height={height}
          tableKey={TABLE_KEY}
          data={data?.results ?? []}
          columns={columns}
          virtualizeRows={false}
          isPaginationEnabled={true}
          sortableFields={sortableFields}
          defaultSort={[{ id: 'chain_proxy__name' as any, desc: false }]}
          paginationOptions={{
            totalRows: data?.count ?? 0,
            pageSize: PAGE_SIZE,
            setPage: setPage,
            page: page,
            isPaginationLoading: isLoading,
          }}
        />
      </div>
      <LoadingOverlay show={isFetching}>
        <Spinner />
      </LoadingOverlay>
    </TableContainer>
  )
}

export function RequestedContactsTable({
  jobId,
  height,
}: RequestedContactsTableProps) {
  return (
    <ColumnsStoreProvider tableKey={TABLE_KEY}>
      <DataTableProvider tableKey={TABLE_KEY}>
        <RequestedContactsTableComponent jobId={jobId} height={height} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`

const LoadingOverlay = styled.div<{
  show: boolean
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 30;
`
