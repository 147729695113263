import FbButton from 'components/FbUI/FbButton'
import * as S from './styles'
import apiService from 'services/api'
import { useState } from 'react'

export function Billing() {
  const api = apiService()
  const [isLoading, setIsLoading] = useState(false)

  async function openStripePortal() {
    setIsLoading(true)
    try {
      const url = await api.getStripePortalRedirect(window.location.href)
      window.location.href = url
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <S.SettingsTableHeader>
        <S.TableDescriptionText>
          Manage your billing information and subscription details here.
        </S.TableDescriptionText>
        <FbButton
          onClick={() => openStripePortal()}
          loading={isLoading}
          size="md"
        >
          Manage Billing
        </FbButton>
      </S.SettingsTableHeader>
    </div>
  )
}
