import { ExpandableCompanies } from 'components/ExpandableCompanies/ExpandableCompanies'
import { JobStatusPill } from 'components/FbUI/JobStatusPill'
import { RequestedContactsTable } from 'components/Tables/RequestedContactsTable/RequestedContactsTable'
import { Modal } from 'components/UI/Modal/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import apiService from 'services/api'
import { ContactRequestJobDetailResponse } from 'services/contactRequestApi'

interface ContactRequestJobDetailsModalProps {
  open: boolean
  onClose: () => void
  jobId: number | null
}

export const ContactRequestJobDetailsModal: React.FC<
  ContactRequestJobDetailsModalProps
> = ({ open, onClose, jobId }) => {
  const api = apiService()
  const [jobDetails, setJobDetails] =
    useState<ContactRequestJobDetailResponse | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (jobId !== null && open) {
      const fetchJobDetails = async () => {
        setIsLoading(true)
        try {
          const response = await api.fetchContactRequestJobDetails(jobId)
          setJobDetails(response)
        } catch (error) {
          console.error('Error fetching job details:', error)
        } finally {
          setIsLoading(false)
        }
      }
      fetchJobDetails()
    }
  }, [jobId, open])

  const uniqueCompaniesCount = useMemo(() => {
    const uniqueCompanies = new Set(
      jobDetails?.contact_companies.map((company) => company.id)
    )
    return uniqueCompanies.size
  }, [jobDetails])

  return (
    <Modal
      size="2xl"
      open={open}
      onOpenChange={onClose}
      title={
        <p className="flex items-center gap-2">
          Requested Contacts:
          {jobDetails?.campaign?.name && (
            <span className="font-light">{jobDetails.campaign.name}</span>
          )}
          {jobDetails?.status && (
            <JobStatusPill
              statusValue={jobDetails.status.id}
              statusLabel={jobDetails.status.label}
            />
          )}
        </p>
      }
      childrenContainerClassName="max-h-[70vh] overflow-y-auto overflow-x-hidden max-w-full w-full"
      dialogFooterClassName="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-48">
          <Spinner animation="border" />
        </div>
      ) : jobDetails ? (
        <div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <h3 className="text-lg font-semibold mb-2">Details:</h3>
              <p>
                <span className="font-bold">Requested by:</span>{' '}
                {jobDetails.user.first_name} {jobDetails.user.last_name}
              </p>
              <p>
                <span className="font-bold">Created:</span>{' '}
                {new Date(jobDetails.created).toLocaleString()}
              </p>
              <p>
                <span className="font-bold">Last Updated:</span>{' '}
                {new Date(jobDetails.modified).toLocaleString()}
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Unique Companies:</h3>
              <p>
                <span className="font-bold">Requested:</span>{' '}
                {uniqueCompaniesCount}
              </p>
              <p>
                <span className="font-bold">Found:</span>{' '}
                {jobDetails.found_companies_count}
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Contacts:</h3>
              <p>
                <span className="font-bold">Max per company:</span>{' '}
                {jobDetails.max_contacts}
              </p>
              <p>
                <span className="font-bold">Requested:</span>{' '}
                {jobDetails.max_contacts * uniqueCompaniesCount}
              </p>
              <p>
                <span className="font-bold">Found:</span>{' '}
                {jobDetails.found_active_count || 0}
              </p>
            </div>
          </div>
          <hr className="h-px my-1 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div>
            <ExpandableCompanies
              companies={jobDetails.contact_companies}
              title="Companies"
            />
          </div>
          <div className="mt-2">
            <p className="font-bold">
              Requested Contacts (
              {jobDetails.max_contacts * uniqueCompaniesCount})
            </p>
            {jobId && <RequestedContactsTable jobId={jobId} height={370} />}
          </div>
        </div>
      ) : (
        <p>No job details available.</p>
      )}
    </Modal>
  )
}
