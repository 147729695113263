import { AgeCard } from 'components/Cards/AgeCard'
import ChainHeaderCard from 'components/Cards/ChainHeaderCard'
import { EducationCard } from 'components/Cards/EducationCard'
import { RaceCard } from 'components/Cards/RaceCard'
import { ZipCodeDemographics } from 'components/Cards/ZipCodeDemographics'
import { CompanyAttributesManager } from 'components/CompanyAttrsManager/CompanyAttrsManager'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import useDocumentTitle from 'components/useDocumentTitle'
import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import * as S from './styles'
import ContactsTableSection from '../../../../components/Cards/ContactsTableSection'
import DealsSection from '../../../../components/Deals/DealsSection/DealsSection'
import NotesSection from '../../../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../../UI/Block'
import { useIsQueryResultNotFound } from 'utils/useIsQueryResultNotFound'
import { AddressInfo } from 'models/restaurant'
import { FBPage } from '../../../FbUI/Page/FBPage'
import apiService from 'services/api'
import EmailsTableSection from 'components/Cards/EmailsTableSection'
import { featureFlagService } from 'utils/featureFlagService'
import { CompanyTypeSlug, ContactCompany } from 'models/companies'
import { TableTypeSlug } from 'models/companies'

interface CommonPageProps<T> {
  uniqueCompanyTypeId?: number
  useGetCompany: {
    companyData?: T
    contactCompanyData?: ContactCompany
    isLoading: boolean
    isError: boolean
    error: Error | null
  }
  tableTypeSlug: TableTypeSlug
  companyTypeSlug: CompanyTypeSlug
  getFieldsFromData?: (data: T) => Partial<{
    contactCompanyId: number
    companyName: string
    websiteAddress: string
    address: {
      addressUrl?: string
      addressInfo: {
        fullAddress?: string
        city?: string
        state?: string
        zipcode?: string
        extra?: string
      }
      fullAddressInfo?: AddressInfo
    }
  }>
  breadcrumbs?: { title: string; breadcrumb?: string }[]
  customBottomLeftItems?: { header: string; text: string }[]
  customSectionItems?: React.ReactNode
}

export default function CommonPage<T>({
  uniqueCompanyTypeId,
  tableTypeSlug,
  companyTypeSlug,
  useGetCompany: { companyData, contactCompanyData, isLoading, isError, error },
  getFieldsFromData,
  breadcrumbs,
  customBottomLeftItems,
  customSectionItems,
}: CommonPageProps<T>) {
  const api = apiService()
  const notFound = useIsQueryResultNotFound({ error, isError })
  const { data: categories } = api.useGetCompanyCategories()
  const { enableEmailIntegration } = featureFlagService()

  const { category, subCategory } = useMemo(() => {
    if (categories) {
      for (const category of categories.company_categories) {
        for (const subCategory of category.sub_categories) {
          if (subCategory.slug === companyTypeSlug) {
            return { category: category.name, subCategory: subCategory.name }
          }
        }
      }
    }
    return { category: '', subCategory: '' }
  }, [categories, companyTypeSlug])

  if (notFound || !uniqueCompanyTypeId) {
    return <NotFoundCard />
  }

  const companyMappedFields = useMemo(() => {
    if (companyData && getFieldsFromData) {
      return getFieldsFromData(companyData)
    }
  }, [companyData, getFieldsFromData])

  const getCompanyTypeNameFromSlug = (slug: TableTypeSlug) => {
    const companyTypeSlugMap: Partial<Record<TableTypeSlug, string>> = {
      'education-cu': 'University',
      'education-k-12': 'K-12',
      'healthcare-hospitals': 'Hospital',
      'healthcare-nursinghomes': 'Nursing Home',
      'restaurants-bars': 'Restaurant',
      'lodging-hospitality': 'Lodging & Hospitality',
      'lodging-hospitality-doors': 'Lodging & Hospitality Door',
    }
    return companyTypeSlugMap[slug]
  }

  useDocumentTitle(
    `${companyMappedFields?.companyName || 'Not Found'} (${getCompanyTypeNameFromSlug(companyTypeSlug)}) - First Bite`
  )

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center h-96'}>
        <Spinner animation="border" />
      </div>
    )
  }

  const columns = { default: 1, lg: 2, xl: 2, xxl: 4 }

  return (
    <FBPage className="gap-y-4" noContainer>
      {companyMappedFields ? (
        <>
          <ChainHeaderCard
            tableTypeSlug={companyTypeSlug}
            contactCompanyId={companyMappedFields.contactCompanyId}
            isLoading={isLoading}
            category={category}
            subCategory={subCategory}
            domainField={companyMappedFields?.websiteAddress?.trim()}
            name={companyMappedFields?.companyName}
            breadcrumbs={breadcrumbs}
            address={{
              url: companyMappedFields?.address?.addressUrl,
              addressInfo: companyMappedFields?.address?.addressInfo,
            }}
            rightColumnHeader={
              <CompanyAttributesManager
                contactCompanyID={companyMappedFields.contactCompanyId}
              />
            }
            customBottomLeftItems={customBottomLeftItems}
          />

          {companyMappedFields?.address && (
            <Block className="mb-3">
              <S.Section columns={columns}>
                <S.SectionItem columns={columns}>
                  <ZipCodeDemographics
                    addressInfo={companyMappedFields?.address?.fullAddressInfo}
                  />
                </S.SectionItem>

                <S.SectionItem columns={columns}>
                  <RaceCard
                    addressInfo={companyMappedFields?.address?.fullAddressInfo}
                  />
                </S.SectionItem>

                <S.SectionItem columns={columns}>
                  <AgeCard
                    addressInfo={companyMappedFields?.address?.fullAddressInfo}
                  />
                </S.SectionItem>

                <S.SectionItem columns={columns}>
                  <EducationCard
                    addressInfo={companyMappedFields?.address?.fullAddressInfo}
                  />
                </S.SectionItem>
              </S.Section>
            </Block>
          )}
        </>
      ) : (
        <div className={'flex items-center justify-center h-96'}>
          <Spinner animation="border" />
        </div>
      )}

      {companyMappedFields && (
        <NotesSection
          contactCompanyId={companyMappedFields.contactCompanyId}
          pagination={false}
        />
      )}

      {contactCompanyData && (
        <DealsSection
          forCompany={contactCompanyData}
          pagination={false}
          companyTypeSlug={companyTypeSlug}
          tableTypeSlug={tableTypeSlug}
        />
      )}

      {contactCompanyData && <ContactsTableSection data={contactCompanyData} />}

      {contactCompanyData && enableEmailIntegration && (
        <EmailsTableSection forChainProxyId={contactCompanyData.id} />
      )}

      {customSectionItems && customSectionItems}
    </FBPage>
  )
}
