import {
  LODGING_DOOR_TABLE_KEY,
  LODGING_TABLE_KEY,
} from 'constants/tableQueryKeys'
import { LodgingTable } from './LodgingTable/LodgingTable'
import { useState } from 'react'
import { LodgingDoorTable } from '../LodgingDoors/LodgingDoorsTable/LodgingDoorsTable'
import { Switch } from 'components/UI/Switch/Switch'
import { StandardSummaryResponse } from 'models/paginated-response'

export function LodgingChainDoorTable({
  pageParamName,
  setLodgingCount,
}: {
  pageParamName: string
  setLodgingCount: (count: StandardSummaryResponse) => void
}) {
  const [showChainTable, setShowChainTable] = useState(true)
  return (
    <>
      {showChainTable ? (
        <LodgingTable
          pageParamName={`${pageParamName}-chain-page`}
          tableKey={LODGING_TABLE_KEY}
          setTotalRowsCount={setLodgingCount}
          AfterSearchComponent={
            <Switch
              type={'checkbox'}
              checked={showChainTable}
              onChange={(e) => {
                if (e.target.checked) {
                  setShowChainTable(true)
                } else {
                  setShowChainTable(false)
                }
              }}
              labelRight={showChainTable ? 'Chain View' : 'Door View'}
              className={'min-w-[120px]'}
            />
          }
        />
      ) : (
        <LodgingDoorTable
          pageParamName={`${pageParamName}-door-page`}
          tableKey={LODGING_DOOR_TABLE_KEY}
          setTotalRowsCount={(count) =>
            setLodgingCount({
              // Since they share the same component for showing doors and chains count
              // the value is swapped for the doors table, where the alt_count is the chains count
              count: count.alt_count,
              alt_count: count.count,
            })
          }
          AfterSearchComponent={
            <Switch
              type={'checkbox'}
              checked={showChainTable}
              onChange={(e) => {
                if (e.target.checked) {
                  setShowChainTable(true)
                } else {
                  setShowChainTable(false)
                }
              }}
              labelRight={showChainTable ? 'Chain View' : 'Door View'}
              className={'min-w-[120px]'}
            />
          }
        />
      )}
    </>
  )
}
