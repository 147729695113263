import FirstbiteLogo from './icons/fblogo.svg'
import TagIcon from './icons/tag.svg'
import CartIcon from './icons/cart.svg'
import InvoiceIcon from './icons/invoice.svg'
import CashIcon from './icons/cash.svg'
import AccountIcon from './icons/account.svg'
import BuildingsIcon from './icons/buildings.svg'

import { useMemo } from 'react'

export interface OfferPageProps {
  data: any // TODO: get the correct type
  onClaimOffer: () => void
  isExpired?: boolean
  isPaused?: boolean
  isPreview?: boolean
  containerClassName?: string
}

enum RebateOfferType {
  FIRST_OFFER = 'FIRST_OFFER',
  TIME_OFFER = 'TIME_OFFER',
}

export function OfferPage({
  data,
  onClaimOffer,
  isExpired,
  isPaused,
  containerClassName,
}: OfferPageProps) {
  console.log(data.offer)
  const tier = data.offer?.terms.tiers?.[0]
  const products = data.offer?.terms.products

  // TODO: make this editable before onboarding next client
  const headline = useMemo(() => {
    if (tier?.discount_type === 'dollars_off') {
      if (data.offer?.type === RebateOfferType.FIRST_OFFER) {
        return (
          <>
            <span className="text-[#28B643]">${tier?.discount} off </span> your
            first{' '}
            {tier?.max === 1 ? (
              'case'
            ) : (
              <>
                <span className="text-[#28B643]">{tier?.max}</span> cases
              </>
            )}{' '}
            of {data.offer?.manufacturer_details?.name} at every one of your
            locations
          </>
        )
      }

      return (
        <>
          Get{' '}
          <span className="text-[#28B643]">${tier?.discount} off per case</span>{' '}
          of {data.offer?.manufacturer_details?.name} at every one of your
          locations
        </>
      )
    }
  }, [data])

  const subheadline = useMemo(() => {
    const date = new Date(data.offer?.valid_to)
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
    return `Purchase by ${formattedDate} and get reimbursed*`
  }, [data.offer?.valid_to])

  const termsString = useMemo(() => {
    const min = tier?.min ?? 0
    const max = tier?.max ?? 0
    const discount = tier?.discount ?? 0
    const maxValue = (max * discount).toFixed(2)

    if (min === max) {
      return `Buy ${min} case of qualifying products. Maximum rebate value is $${maxValue}`
    }

    const minValue = (min * discount).toFixed(2)
    return `Buy ${min} to ${max} cases of qualifying products. Minimum rebate value is $${minValue}, maximum rebate value is $${maxValue}`
  }, [tier?.min, tier?.max, tier?.discount])

  function OfferStatusButton() {
    if (isExpired) {
      return (
        <div
          className={`bg-red-50 border-l-4 border-red-400 p-4 rounded ${containerClassName}`}
        >
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Offer Expired
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>
                  This offer has expired and is no longer accepting new
                  enrollments. Please check back later for new offers from{' '}
                  {data.offer?.manufacturer_details.name}.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (isPaused) {
      return (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Offer Temporarily Paused
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  This offer is temporarily paused and not accepting new
                  enrollments at this time. Please check back soon as the offer
                  may become available again.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <button
        className="bg-[#07502E] text-white px-5 py-3 rounded-full flex-shrink-0"
        onClick={onClaimOffer}
      >
        Claim Offer
      </button>
    )
  }

  return (
    <div
      style={{ fontFamily: 'Inter' }}
      className="w-full bg-[#E8ECE7] text-[#002110] h-full"
    >
      <div className="flex bg-[#002110] flex-col items-center justify-center pt-10 pb-20">
        <img src={FirstbiteLogo} alt="Firstbite Logo" />
      </div>

      <div className="-mt-10 px-3 md:px-10 max-w-[1000px] mx-auto">
        <div className="flex bg-[#F9FAF6] p-10 rounded shadow mb-10 justify-between items-center gap-10">
          <div className="flex flex-col gap-5 items-center md:items-start flex-grow">
            <span className="flex items-center gap-2 mb-3">
              <img src={TagIcon} alt="Tag Icon" />
              You&apos;ve received an offer
            </span>
            <div className="font-normal mb-5 flex flex-col md:flex-row items-center md:items-start gap-5 text-center md:text-left">
              <img
                src={data.offer?.manufacturer_details?.logo}
                alt={data.offer?.manufacturer_details?.name}
                className="w-32 h-32 bg-transparent rounded-lg object-contain md:hidden mb-3"
              />
              <div>
                <h1 className="text-4xl">{headline}</h1>
                {subheadline && (
                  <p className="text-gray-600 text-md mt-6">{subheadline}</p>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center md:justify-start">
              <OfferStatusButton />
            </div>
          </div>
          <img
            src={data.offer?.manufacturer_details?.logo}
            alt={data.offer?.manufacturer_details?.name}
            className="w-32 h-32 bg-transparent rounded-lg object-contain hidden md:block"
          />
        </div>

        <div className="flex flex-col bg-[#F9FAF6] p-10 rounded shadow mb-10">
          <div className="flex flex-col items-start lg:flex-row lg:justify-between lg:gap-20">
            <h2 className="text-2xl mb-5 flex-shrink-0 ">How it works</h2>
            <ul className="text-lg ">
              <li
                className={
                  'border-b border-[#002110] border-opacity-20 pb-2 flex items-center gap-2'
                }
              >
                <img src={AccountIcon} alt="Account Icon" />
                Create an account in 30 seconds
              </li>
              <li
                className={
                  'border-b border-[#002110] border-opacity-20 py-2 flex items-center gap-2'
                }
              >
                <img src={CartIcon} alt="Cart Icon" />
                Purchase qualifying products
              </li>
              <li
                className={
                  'border-b border-[#002110] border-opacity-20 py-2 flex items-center gap-2'
                }
              >
                <img src={InvoiceIcon} alt="Invoice Icon" />
                Submit your invoice online
              </li>
              <li
                className={
                  'border-b border-[#002110] border-opacity-20 py-2 flex items-center gap-2'
                }
              >
                <img src={BuildingsIcon} alt="Buildings Icon" />
                {tier?.max === 1
                  ? 'Chain operators can redeem once per location'
                  : `Chain operators can redeem up to ${tier?.max} cases per location`}
              </li>
              <li className={'py-2 flex items-center gap-2'}>
                <img src={CashIcon} alt="Cash Icon" />
                Receive your payment within 30 days via mailed check or digital
                check
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col bg-[#F9FAF6] p-10 rounded shadow mb-10 w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between items-start mb-5">
              <h2 className="text-2xl mb-5">Qualifying Products</h2>
              <OfferStatusButton />
            </div>
            <div className="flex flex-col lg:grid lg:grid-cols-2 flex-wrap gap-2 w-full">
              {products?.flatMap((product: any) =>
                product.product_variants.map((variant: any) => (
                  <div
                    className="flex-1 flex-shrink-0 bg-white px-5 py-4"
                    key={`${product.product_id}-${variant.variant_id}`}
                  >
                    <h3 className="text-xl mb-5">{variant.variant_name}</h3>
                    <div className="flex flex-row gap-2 items-center text-sm">
                      <div className="flex-shrink-0 rounded-full bg-[#E5EAE2] px-4 py-2 flex items-center justify-center">
                        {variant.variant_format}
                      </div>
                      <div className="font-mono text-xs flex-shrink-0">
                        {variant.variant_sku && (
                          <p>SKU: {variant.variant_sku}</p>
                        )}
                        {variant.variant_gtin && (
                          <p>GTIN: {variant.variant_gtin}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="p-10">
          <h2 className="text-2xl mb-5">Offer Terms*</h2>
          <ul className="mb-20 list-none">
            <li className="mb-2 relative pl-6">
              <span className="absolute left-0">•</span>
              {termsString}
            </li>
            <li className="mb-2 relative pl-6">
              <span className="absolute left-0">•</span>
              Offer valid on cases purchased between{' '}
              {new Date(data.offer?.valid_from ?? '').toLocaleDateString(
                'en-US',
                {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }
              )}{' '}
              and{' '}
              {new Date(data.offer?.valid_to ?? '').toLocaleDateString(
                'en-US',
                {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }
              )}
            </li>
            <li className="mb-2 relative pl-6">
              <span className="absolute left-0">•</span>
              Invoices must be submitted by{' '}
              {new Date(
                new Date(data.offer?.valid_to ?? '').setMonth(
                  new Date(data.offer?.valid_to ?? '').getMonth() + 1
                )
              ).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}{' '}
              - Submit early and often to get your check sooner
            </li>
            <li className="mb-2 relative pl-6">
              <span className="absolute left-0">•</span>
              Invoices must show restaurant name, address, date, product name,
              product number, and volume. Velocity reports or distributor recaps
              are not accepted
            </li>
          </ul>
          <a
            href="mailto:support@firstbite.io"
            className="bg-[#28B643] text-white rounded p-2 font-mono text-sm block mx-auto text-center"
          >
            Questions? Contact support@firstbite.io
          </a>
        </div>
      </div>
    </div>
  )
}
