import { format } from 'date-fns'
import { Modal } from 'components/UI/Modal/Modal'
import { DataTable } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import apiService from '../../services/api'
import { RebateClaimDetail, RebateOfferProduct } from './tradespend_api'
import { ActionButtons } from '../../components/Buttons/ActionButtons'
import { BiReceipt } from 'react-icons/bi'
import { ClaimInvoicesView } from './ClaimInvoicesView'

interface RebateEnrollmentDetailsModalProps {
  open: boolean
  onClose: () => void
  enrollmentId: number
}

export function RebateEnrollmentDetailsModal({
  open,
  onClose,
  enrollmentId,
}: RebateEnrollmentDetailsModalProps) {
  const api = apiService()
  const { data: enrollmentDetails, isFetching } =
    api.useGetRebateEnrollmentDetails(enrollmentId)

  const [activeView, setActiveView] = useState<{
    type: 'claims' | 'invoices'
    claim?: RebateClaimDetail
  }>({ type: 'claims' })

  const { productMap, variantMap } = useMemo(() => {
    if (!enrollmentDetails) return { productMap: {}, variantMap: {} }

    const productMap: Record<string, RebateOfferProduct> = {}
    const variantMap: Record<
      string,
      RebateOfferProduct['product_variants'][0]
    > = {}

    enrollmentDetails.terms.products.forEach((product) => {
      productMap[product.product_id.toString()] = product
      product.product_variants.forEach((variant) => {
        variantMap[variant.variant_id.toString()] = variant
      })
    })

    return { productMap, variantMap }
  }, [enrollmentDetails])

  const claimColumns = useMemo<ColumnDef<RebateClaimDetail, any>[]>(
    () => [
      {
        accessorKey: 'created',
        header: 'Created',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
      },
      {
        accessorKey: 'total_units',
        header: 'Total Units',
        size: 120,
        cell: (info) => {
          const units = info.getValue()
          return units !== null
            ? units.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-'
        },
      },
      {
        accessorKey: 'payout_amount',
        header: 'Payout Amount',
        size: 120,
        cell: (info) => {
          const amount = info.getValue()
          return amount !== null
            ? `$${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            : '-'
        },
      },
      {
        id: 'invoices',
        header: 'View Invoices',
        size: 100,
        meta: {
          isLastColumn: true,
          rightAlign: true,
        },
        cell: (info) => {
          const claim = info.row.original
          return (
            <ActionButtons
              className="justify-end"
              onSee={() => setActiveView({ type: 'invoices', claim })}
              seeIcon={BiReceipt}
            />
          )
        },
      },
    ],
    []
  )

  const renderContent = () => {
    if (!enrollmentDetails) {
      return (
        <div className="flex justify-center items-center h-40">
          <span className="text-gray-500">Loading...</span>
        </div>
      )
    }

    if (activeView.type === 'invoices' && activeView.claim) {
      return (
        <ClaimInvoicesView
          claim={activeView.claim}
          onBack={() => setActiveView({ type: 'claims' })}
          productMap={productMap}
          variantMap={variantMap}
        />
      )
    }

    return (
      <div className="space-y-4">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">
              Enrollment Information
            </h3>
            <p>
              <span className="font-bold">User:</span>{' '}
              {enrollmentDetails.tradespend_user_name}
            </p>
            <p>
              <span className="font-bold">Company:</span>{' '}
              {enrollmentDetails.company_name}
            </p>
            <p>
              <span className="font-bold">Enrolled On:</span>{' '}
              {format(new Date(enrollmentDetails.created), 'MMM d, yyyy')}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Claims Summary</h3>
            <p>
              <span className="font-bold">Total Claims:</span>{' '}
              {enrollmentDetails.total_claims}
            </p>
            <p>
              <span className="font-bold">Pending Validation:</span>{' '}
              {enrollmentDetails.pending_validation_claims}
            </p>
            <p>
              <span className="font-bold">Invalid Claims:</span>{' '}
              {enrollmentDetails.invalid_claims}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Payment Status</h3>
            <p>
              <span className="font-bold">Paid Claims:</span>{' '}
              {enrollmentDetails.paid_claims}
            </p>
            <p>
              <span className="font-bold">Unpaid Valid Claims:</span>{' '}
              {enrollmentDetails.unpaid_valid_claims}
            </p>
          </div>
        </div>

        <hr className="h-px my-4 bg-gray-300 border-0 dark:bg-gray-700" />

        <div>
          <h3 className="text-lg font-semibold mb-2">Claims History</h3>
          <DataTableContainer>
            <DataTable
              loading={isFetching}
              height={400}
              data={enrollmentDetails.claims}
              columns={claimColumns}
              tableKey="enrollment-claims"
              expandColAccessor="expanded-row"
            />
          </DataTableContainer>
        </div>
      </div>
    )
  }

  return (
    <Modal
      size="2xl"
      open={open}
      onOpenChange={onClose}
      title={
        <p className="flex items-center gap-2">
          Enrollment Details
          {enrollmentDetails && (
            <span className="font-light">{enrollmentDetails.company_name}</span>
          )}
        </p>
      }
      childrenContainerClassName="max-h-[70vh] overflow-y-auto max-w-full w-full"
      dialogFooterClassName="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
    >
      {renderContent()}
    </Modal>
  )
}
