import { RebateOfferProduct } from '../tradespend_api'
import styled from 'styled-components'

interface ProductVariantInfoProps {
  variant: RebateOfferProduct['product_variants'][0] | undefined
  variantId: string
  info: {
    num_units: number
    product_identifiers: Array<{
      product_identifier_type: string
      product_identifier: string
    }>
  }
}

export function ProductVariantInfo({
  variant,
  variantId,
  info,
}: ProductVariantInfoProps) {
  return (
    <div className="mb-4 last:mb-0">
      <div className="text-sm font-medium text-gray-700 mb-2">
        {variant ? (
          <>
            {variant.variant_name} - {variant.variant_format}
            <br />
            <span className="text-gray-500">{info.num_units} units</span>
          </>
        ) : (
          `Variant #${variantId} - ${info.num_units} units`
        )}
      </div>
      <IdentifiersList>
        {info.product_identifiers.map((identifier, idx) => (
          <IdentifierTag key={idx}>
            {identifier.product_identifier_type}:{' '}
            {identifier.product_identifier}
          </IdentifierTag>
        ))}
      </IdentifiersList>
    </div>
  )
}

const IdentifiersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const IdentifierTag = styled.span`
  background: #e5e7eb;
  color: #374151;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`
