import { Package } from 'lucide-react'
import { formatDiscount } from '../RebateOfferDetailsTab'

// Enhanced tier card
function DiscountTierCard({
  tier,
  unit,
  index,
}: {
  tier: any
  unit: string
  index: number
}) {
  const colors = [
    'bg-fb-dark-green/10',
    'bg-fb-dark-green/20',
    'bg-fb-dark-green/10',
    'bg-fb-dark-green/20',
  ]
  const bgColor = colors[index % colors.length]

  return (
    <div
      className={`${bgColor} rounded-lg p-3 border border-gray-200 shadow-sm`}
    >
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium text-gray-800">
          Tier {index + 1}
        </span>
        <span className="font-bold text-lg text-fb-dark-green bg-white px-3 py-1 rounded-full shadow-sm">
          {formatDiscount(tier)}
        </span>
      </div>
      <div className="text-sm text-gray-600 flex items-center mt-2">
        <Package className="h-4 w-4 mr-2" />
        <span>
          {tier.min} - {tier.max || '∞'} {unit === 'unit' ? 'cases' : 'dollars'}
        </span>
      </div>
    </div>
  )
}

export default DiscountTierCard
