import { Modal } from '../../UI/Modal/Modal'
import { z } from 'zod'
import apiService from '../../../services/api'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import { TextInput } from '../../FormUtils/TextInput'
import Select, { SelectOption } from '../../FormUtils/Select'
import { PhoneInput } from '../../FormUtils/PhoneInput'
import CreateDealModal from '../../Deals/CreateDealModal/CreateDealModal'
import { useQueryClient } from '@tanstack/react-query'
import { US_STATES } from 'utils/us_states'
import { US_CITIES } from 'utils/us_cities'
import { AxiosError } from 'axios'
import CreatableSelect from 'components/FormUtils/CreatableSelect'
import { ALL_COUNTRIES } from 'utils/countries'
import {
  extensionZodValidator,
  phoneNumberZodValidor,
} from 'utils/zodValidators'
import { useDebounce } from 'use-debounce'
import { BsCheck } from 'react-icons/bs'
import { Spinner } from 'react-bootstrap'

const companyFormShapeItems = {
  name: z.string().refine((val) => val.length > 0, {
    message: 'Company name is required',
  }),
  domain: z
    .string()
    .trim()
    .refine(
      (v) => {
        if (!v) return false
        // Match regex for domain https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd
        const domainRegex = new RegExp(
          /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/
        )
        // Test if domain is valid and has at least one dot
        return domainRegex.test(v) && v.includes('.')
      },
      {
        message: 'Enter domain as: domain.com',
      }
    ),
  unique_domain: z.boolean(),
  country: z
    .string({ invalid_type_error: 'Please select a country' })
    .default('US'),
  address: z.string().min(1),
  city: z.string().min(1),
  state: z
    .string({
      invalid_type_error: 'Please select a state',
    })
    .optional(),
  zipcode: z.string().min(1),
  extension: extensionZodValidator,
  phone_number: phoneNumberZodValidor,
  distributors: z.array(z.number()).optional(),
  taglist: z.array(z.number()).optional(),
  category: z.number({
    required_error: 'Please select a category to continue.',
    invalid_type_error: 'Please select a category to continue.',
  }),
  sub_category: z.number({
    required_error: 'Please select a type to continue.',
    invalid_type_error: 'Please select a type to continue.',
  }),
  google_place_url: z
    .string()
    .url({
      message:
        'Enter a valid URL: https://google.com/maps... or https://maps.app.goo.gl/...',
    })
    .optional()
    .refine(
      (val) => {
        if (!val) return true
        return (
          val.includes('google.com/maps') || val.includes('maps.app.goo.gl')
        )
      },
      {
        message:
          'Must be a valid Google Maps URL, e.g. https://google.com/maps... or https://maps.app.goo.gl/...',
      }
    )
    .or(z.literal('')),
}
const companyFormZod = z
  .object(companyFormShapeItems)
  .superRefine((val, ctx) => {
    // State is only required if country is US
    if (val.country === 'US' && !val.state) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        message: 'State is required for US',
        minimum: 1,
        inclusive: true,
        type: 'string',
      })
    }
  })
export type CompanyFormShape = z.infer<typeof companyFormZod>

export function CompanyModal({
  show,
  setShow,
  title,
  description,
  acceptButtonText,
  defaultValues,
  disableFields,
  hideCategoryAndType,
  hideKnownDistributorsAndTags,
  skipDealCreation,
  apiAction,
  type = 'chain',
  checkUniqueDomain = false,
}: {
  show: boolean
  setShow: (show: boolean) => void
  title: string
  description: string
  acceptButtonText: string
  defaultValues?: Partial<CompanyFormShape>
  disableFields?: (keyof CompanyFormShape)[]
  hideCategoryAndType?: boolean
  hideKnownDistributorsAndTags?: boolean
  skipDealCreation?: boolean
  apiAction: (
    values: CompanyFormShape
  ) => Promise<Record<string, any> | undefined>
  type?: 'chain' | 'door'
  checkUniqueDomain?: boolean
}) {
  const contextTexts = {
    chain: {
      nameLabel: 'Company',
      namePlaceholder: 'Enter company name',
      domainLabel: 'Company Domain',
      addressHelperText: '',
    },
    door: {
      nameLabel: 'Door',
      namePlaceholder: 'Enter door name',
      domainLabel: 'Door Domain',
      addressHelperText: '',
    },
  }

  const texts = contextTexts[type]

  const companyFormShape = z.object({
    ...companyFormShapeItems,
    category: hideCategoryAndType
      ? z.number().optional()
      : z.number({
          required_error: 'Please select a category to continue.',
          invalid_type_error: 'Please select a category to continue.',
        }),
    sub_category: hideCategoryAndType
      ? z.number().optional()
      : z.number({
          required_error: 'Please select a type to continue.',
          invalid_type_error: 'Please select a type to continue.',
        }),
  })

  const api = apiService()

  const queryClient = useQueryClient()

  const { data: categories } = api.useGetCompanyCategories()
  const { data: tagsOptions } = api.useGetTagsOptions()
  const { data: distributorsOptions } = api.useGetCompanyDistributorsOptions()

  const companyCategories = useMemo(
    () => categories?.company_categories || [],
    [categories]
  )

  const categoryOptions = useMemo(
    () =>
      companyCategories?.map((category) => ({
        label: category.name,
        value: category.id,
      })) || [],
    [companyCategories]
  )

  const {
    control,
    handleSubmit,
    formState,
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm<CompanyFormShape>({
    mode: 'onChange',
    defaultValues: {
      country: 'US',
      unique_domain: !checkUniqueDomain,
      ...defaultValues,
    },
    resolver: zodResolver(companyFormShape),
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const watchCountry = watch('country')

  // Validate Domain Uniqueness
  const watchDomain = watch('domain')
  const watchUniqueDomain = watch('unique_domain')
  const [dDomain] = useDebounce(watchDomain, 500)
  const [controller, setController] = useState(new AbortController())
  const [checkingDomain, setCheckingDomain] = useState(false)

  async function checkDomainUnique(
    domain: string,
    signal: AbortController['signal']
  ) {
    setCheckingDomain(true)
    const res = await api.searchCompaniesUniqueDomain(domain, signal)

    if (res) {
      setValue('unique_domain', false, { shouldValidate: true })
      setError('domain', { type: 'manual', message: 'Domain in use' })
    } else {
      setValue('unique_domain', true, { shouldValidate: true })
      clearErrors('domain')
    }

    setCheckingDomain(false)
  }

  useEffect(() => {
    if (!checkUniqueDomain) {
      setValue('unique_domain', true, { shouldValidate: true })
      return
    }

    if (controller) {
      controller.abort()
      setCheckingDomain(false)
      setValue('unique_domain', false, { shouldValidate: true })
    }
    const newController = new AbortController()
    setController(newController)

    if (dDomain && !formState.errors.domain) {
      void checkDomainUnique(dDomain, newController.signal)
    }
  }, [dDomain])

  // Change city options based on state.
  const watchState = watch('state')
  const stateOptions = US_STATES
  const cityOptions = useMemo(() => {
    const state = watchState
    if (!state) return []
    return US_CITIES.filter((city) => city.value.endsWith(state)).map(
      (city) => ({
        label: city.label.split(',')[0],
        value: city.label.split(',')[0],
      })
    )
  }, [watchState])

  // Change subcategory options based on category.
  const watchCategory = watch('category')
  const typeOptions = useMemo(() => {
    const category = watchCategory
    if (!category) return []
    const categoryObj = companyCategories?.find((c) => c.id === category)
    if (!categoryObj) return []
    return categoryObj.sub_categories
      .map((subCategory) => ({
        label: subCategory.name,
        value: subCategory.id,
        slug: subCategory.slug,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [watchCategory, show])

  const [dealsModal, setDealsModal] = useState(false)
  const [createdCompanyId, setCreatedCompanyId] = useState<number | null>(null)
  const _handleClose = (openDealsModal?: boolean) => {
    setShow(false)
    if (openDealsModal) {
      setDealsModal(true)
    }
  }

  const onSubmit = handleSubmit(
    async (values) => {
      const [err, res] = await to<Record<string, any> | undefined, AxiosError>(
        apiAction(values)
      )
      if (err?.response?.data) {
        const errors = err.response.data as { [key: string]: string[] }
        for (const [key, value] of Object.entries(errors)) {
          if (key === 'non_field_errors') {
            toast.error(value)
          } else {
            setError(key as 'name', { message: value[0] as any })
          }
        }
      }
      if (!err) {
        // Invalidate header companies query
        await queryClient.invalidateQueries({
          queryKey: ['companyCategoriesAndSub'],
        })
        // Invalidate companies table query
        const subCategoryName = companyCategories
          ?.find((c) => c.id === values.category)
          ?.sub_categories.find((s) => s.id === values.sub_category)?.name
        await queryClient.invalidateQueries({
          queryKey: [`${subCategoryName}-table`],
        })
        if (!skipDealCreation) {
          setCreatedCompanyId(res?.id)
        }
        reset({
          ...formState.defaultValues,
        })
        _handleClose(true)
      }
    },
    (errors) => {
      for (const [key, value] of Object.entries(errors)) {
        if (key !== 'non_field_errors') {
          return
        }
        if (value?.message) {
          toast.error(value.message)
        }
      }
    }
  )

  const customCountryFilter = (option: SelectOption, rawInput: string) => {
    if (!rawInput) return true
    return (option.label as string)
      .toLowerCase()
      .startsWith(rawInput.toLowerCase())
  }

  const sortedCountries = useMemo(() => {
    return ALL_COUNTRIES.sort((a, b) =>
      a.value === 'US' ? -1 : a.label.localeCompare(b.label)
    )
  }, [])

  return (
    <>
      <Modal
        open={show}
        onOpenChange={setShow}
        title={title}
        description={description}
        acceptButtonText={acceptButtonText}
        onAccept={onSubmit}
        size={'lg'}
        loading={formState.isSubmitting}
        blockAccept={!formState.isValid}
      >
        <TextInput
          noMargin={true}
          label={texts['nameLabel']}
          name="name"
          control={control}
          placeholder={texts['namePlaceholder']}
          disabled={disableFields?.includes('name')}
        />
        <div className={'relative'}>
          <TextInput
            label={texts['domainLabel']}
            name="domain"
            control={control}
            placeholder="company.com"
            disabled={disableFields?.includes('domain')}
          />
          {checkingDomain ? (
            <Spinner
              size={'sm'}
              className={'absolute bottom-2.5 right-2.5 z-10'}
            />
          ) : watchUniqueDomain ? (
            <BsCheck size={22} className={'absolute bottom-1.5 right-2 z-10'} />
          ) : (
            <></>
          )}
        </div>
        {!hideCategoryAndType && (
          <div className={'flex gap-2 w-full max-w-full mt-3'}>
            <Select
              control={control}
              name="category"
              placeholder={'Category'}
              label={'Category'}
              options={categoryOptions}
              className={'flex-1'}
              disabled={disableFields?.includes('category')}
            />
            <Select
              className={'flex-1'}
              control={control}
              name="sub_category"
              placeholder={'Type'}
              options={typeOptions}
              label={'Type'}
              disabled={
                !watchCategory || disableFields?.includes('sub_category')
              }
            />
          </div>
        )}
        <div className={'flex gap-2 w-full max-w-full'}>
          <TextInput
            label="Google Maps URL"
            name="google_place_url"
            control={control}
            placeholder="Enter Google Place URL"
            disabled={disableFields?.includes('google_place_url')}
            optional
          />
        </div>
        <div className={'flex gap-2 w-full max-w-full mt-3'}>
          <Select
            className={'flex-1'}
            control={control}
            name="country"
            placeholder={'Enter country'}
            options={sortedCountries}
            label={'Country'}
            filterOption={customCountryFilter}
            disabled={disableFields?.includes('country')}
          />
        </div>
        <TextInput
          label="Address"
          helperText={texts['addressHelperText']}
          name="address"
          control={control}
          placeholder="Enter address"
          disabled={disableFields?.includes('address')}
        />
        <div className={'flex gap-2 w-full max-w-full mt-3'}>
          {watchCountry === 'US' ? (
            <>
              <Select
                className={'flex-1 mt-0'}
                control={control}
                name="state"
                placeholder={'Enter state'}
                options={stateOptions}
                label={'State'}
                disabled={disableFields?.includes('state')}
              />
              <CreatableSelect
                className={'flex-1 mt-0'}
                control={control}
                name="city"
                placeholder={'Enter city'}
                options={cityOptions}
                label={'City'}
                disabled={!watchState || disableFields?.includes('city')}
                onCreateOption={async (city) => {
                  return {
                    label: city,
                    value: city,
                  }
                }}
              />
            </>
          ) : (
            <>
              <TextInput
                noMargin
                label="State"
                name="state"
                control={control}
                placeholder="Enter state"
                disabled={disableFields?.includes('state')}
              />
              <TextInput
                noMargin
                label="City"
                name="city"
                control={control}
                placeholder="Enter city"
                disabled={disableFields?.includes('city')}
              />
            </>
          )}
        </div>
        <div className={'flex gap-2 w-full max-w-full'}>
          <TextInput
            label="ZIP code"
            name="zipcode"
            control={control}
            placeholder="Enter zipcode"
            disabled={disableFields?.includes('zipcode')}
          />
        </div>
        <PhoneInput
          name="phone_number"
          extensionName="extension"
          control={control}
          label="Phone number"
          optional
        />
        {!hideKnownDistributorsAndTags && (
          <>
            <Select
              style={{ flex: 1, flexShrink: 0 }}
              className={'mt-3'}
              name="distributors"
              isMulti
              control={control}
              type="text"
              disabled={disableFields?.includes('distributors')}
              options={distributorsOptions}
              placeholder="Known Distributor(s)"
              label={'Known Distributor(s)'}
            />
            <Select
              style={{ flex: 1, flexShrink: 0 }}
              className={'mt-3'}
              name="taglist"
              isMulti
              control={control}
              type="text"
              disabled={disableFields?.includes('taglist')}
              options={tagsOptions}
              placeholder="Tag(s)"
              label={'Tag(s)'}
            />
          </>
        )}
      </Modal>
      <CreateDealModal
        totalSelectedRows={1}
        optimisticUiTarget="create-company"
        cancelButtonText={'Skip deal creation'}
        show={dealsModal}
        handleClose={() => setDealsModal(false)}
        targetCompanyId={createdCompanyId ?? undefined}
        tableTypeSlug="other"
        companyTypeSlug="other"
      />
    </>
  )
}
