import { CHAIN_COLUMNS } from 'components/DataExpansionComponents/Chains/chainColumnsInfo'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
  QuickFilterGroup,
} from 'components/Filters/components/FilterModal'
import {
  QuickRangeFilter,
  QuickValueFilter,
} from 'components/Filters/fields/QuickFilter'
import {
  RangeFilter,
  RangeFilterWithMinMax,
} from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { DMA_OPTIONS } from 'models/dma_options'
import { InstagramTextPercentileOptions } from 'models/instagram_text_percentile'
import { menu_ingredients } from 'models/menu_ingredients'
import { REPUTATION_PLATFORMS_OPTIONS } from 'models/reputation_platforms'
import { FilterIdentifier } from 'models/saved_view'
import { VelocityGroupOptions } from 'models/velocity_groups'
import apiService from 'services/api'
import { ALL_COUNTRIES } from 'utils/countries'
import { featureFlagService } from 'utils/featureFlagService'
import { Cuisine50Options } from '../../../models/cuisine_50'
import { SERVICE_OPTIONS } from 'models/services'
import { ATMOSPHERES_OPTIONS } from 'models/atmospheres'
import { OFFERINGS_OPTIONS } from 'models/offerings'
import { OPEN_HOURS_OPTIONS } from 'models/openHours'
import { CityAndStateFilter } from 'components/Filters/components/CityAndStateFilter'

export function ChainsFilterset({
  filterIdentifier,
  hideCampaignsFilter,
}: {
  filterIdentifier: FilterIdentifier
  hideCampaignsFilter: boolean
}) {
  const chainsFilterStore = filterStoreRepo.getStore(filterIdentifier)

  const api = apiService()
  const featureFlags = featureFlagService()
  const { data: campaignOptions } = api.useGetCampaignsOptions(
    'restaurants-bars',
    undefined,
    true
  )

  const chainFilterHelper = createFilterColumnHelper({ columns: CHAIN_COLUMNS })

  return (
    <FilterModal identifier={filterIdentifier} store={chainsFilterStore}>
      <QuickFilterGroup>
        <QuickRangeFilter
          {...chainFilterHelper.retrieveProps('one_year_total_value')}
          valueLabel="≥1"
          predefinedValue={{ min: 1 }}
          filterStore={chainsFilterStore}
        />
        <QuickRangeFilter
          {...chainFilterHelper.retrieveProps('count')}
          valueLabel="2+"
          predefinedValue={{ min: 2 }}
          filterStore={chainsFilterStore}
        />
        <QuickValueFilter
          {...chainFilterHelper.retrieveProps('sales_stages')}
          valueLabel="Empty"
          predefinedValue={[{ value: '-1', label: 'Empty' }]}
          filterStore={chainsFilterStore}
        />
        <QuickRangeFilter
          {...chainFilterHelper.retrieveProps('contact_count')}
          valueLabel="≤0"
          predefinedValue={{ max: 0 }}
          filterStore={chainsFilterStore}
        />
        {!hideCampaignsFilter && (
          <QuickValueFilter
            {...chainFilterHelper.retrieveProps('campaigns')}
            valueLabel="No Campaign"
            predefinedValue={[{ label: 'No Campaign', value: 'REMOVE' }]}
            filterStore={chainsFilterStore}
          />
        )}
      </QuickFilterGroup>

      <FilterGroup title="Lifetime Values">
        <RangeFilter
          {...chainFilterHelper.retrieveProps('total_ltv')}
          store={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('brand_ltv')}
          store={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('revenue_ltv')}
          store={chainsFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Predictive Annual Values">
        <RangeFilter
          {...chainFilterHelper.retrieveProps('one_year_total_value')}
          store={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('taro')}
          store={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('tabo')}
          store={chainsFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Menu">
        <SelectFilter
          {...chainFilterHelper.retrieveProps('cuisine_50')}
          options={Cuisine50Options}
          filterStore={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('pounds_per_year')}
          store={chainsFilterStore}
        />
        <SelectFilter
          {...chainFilterHelper.retrieveProps('ingredients_matrix')}
          options={menu_ingredients}
          filterStore={chainsFilterStore}
        />
        {featureFlags.shouldShowMenuSize && (
          <RangeFilter
            {...chainFilterHelper.retrieveProps('menu_length')}
            store={chainsFilterStore}
          />
        )}
        <RangeFilter
          {...chainFilterHelper.retrieveProps('menu_matches')}
          store={chainsFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Features">
        <RangeFilter
          {...chainFilterHelper.retrieveProps('count')}
          store={chainsFilterStore}
        />
        <SelectFilter
          {...chainFilterHelper.retrieveProps('expense_category')}
          options={[
            { label: '$', value: '1' },
            { label: '$$', value: '2' },
            { label: '$$$', value: '3' },
            { label: '$$$$', value: '4' },
          ]}
          filterStore={chainsFilterStore}
        />
        {featureFlags.shouldShowReputationData && (
          <>
            <SelectFilter
              {...chainFilterHelper.retrieveProps('reputation_data')}
              options={REPUTATION_PLATFORMS_OPTIONS}
              filterStore={chainsFilterStore}
            />
            <RangeFilterWithMinMax
              {...chainFilterHelper.retrieveProps('michelin_stars_count')}
              store={chainsFilterStore}
              min={0}
              max={3}
            />
          </>
        )}
        <RangeFilterWithMinMax
          {...chainFilterHelper.retrieveProps('rating')}
          store={chainsFilterStore}
          min={0}
          max={5}
          decimalScale={2}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('reviews_count')}
          store={chainsFilterStore}
        />
        <SelectFilter
          {...chainFilterHelper.retrieveProps('velocity_group')}
          options={VelocityGroupOptions}
          filterStore={chainsFilterStore}
        />
        {featureFlags.enableInstagremData && (
          <RangeFilter
            {...chainFilterHelper.retrieveProps('instagram_followers')}
            store={chainsFilterStore}
          />
        )}
        {featureFlags.enableInstagramPercentile && (
          <SelectFilter
            {...chainFilterHelper.retrieveProps('instagram_text_percentile')}
            options={InstagramTextPercentileOptions}
            filterStore={chainsFilterStore}
          />
        )}
        {featureFlags.enableOppsAttrsServicesAtmosOffersHours && (
          <>
            <SelectFilter
              {...chainFilterHelper.retrieveProps('services')}
              options={SERVICE_OPTIONS}
              filterStore={chainsFilterStore}
            />
            <SelectFilter
              {...chainFilterHelper.retrieveProps('atmospheres')}
              options={ATMOSPHERES_OPTIONS}
              filterStore={chainsFilterStore}
            />
            <SelectFilter
              {...chainFilterHelper.retrieveProps('offerings')}
              options={OFFERINGS_OPTIONS}
              filterStore={chainsFilterStore}
            />
            <SelectFilter
              {...chainFilterHelper.retrieveProps('open_hours')}
              options={OPEN_HOURS_OPTIONS}
              filterStore={chainsFilterStore}
            />
          </>
        )}
      </FilterGroup>

      <CommonFiltersetAccountGroup
        hideCampaignsFilter={hideCampaignsFilter}
        filterStore={chainsFilterStore}
        campaignOptions={campaignOptions}
      />

      <FilterGroup title="Location">
        {featureFlags.enableRestaurantsCountryFilter && (
          <SelectFilter
            {...chainFilterHelper.retrieveProps('country_summary')}
            options={ALL_COUNTRIES}
            filterStore={chainsFilterStore}
          />
        )}
        <CityAndStateFilter
          filterStore={chainsFilterStore}
          stateSelectProps={chainFilterHelper.retrieveProps('state_summary')}
          citySelectProps={chainFilterHelper.retrieveProps('city_summary')}
          cityKey="city_summary"
          stateKey="state_summary"
        />
        <SelectFilter
          {...chainFilterHelper.retrieveProps('dma_summary')}
          options={DMA_OPTIONS}
          filterStore={chainsFilterStore}
        />
        <ZipcodeFilter filterStore={chainsFilterStore} />
      </FilterGroup>

      <FilterGroup title="ZIP Code Demographics">
        <RangeFilter
          {...chainFilterHelper.retrieveProps('median_hhi')}
          store={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('hh_gt100k')}
          store={chainsFilterStore}
        />
        <RangeFilter
          {...chainFilterHelper.retrieveProps('pop_density')}
          store={chainsFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
