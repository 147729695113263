import { NumericInputWithUnitSelector } from 'components/FormUtilsV2/NumericInputWithUnitSelector/numeric-input-with-unit-selector'
import { TextInput } from 'components/FormUtilsV2/TextInput/text-input'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'
import BaseStep from './BaseStep'
import { useProductModalContext } from './ProductModalContext'
import * as S from './styles'

export function ProductDetailsStep({ onNext }: { onNext: () => void }) {
  const {
    methods: { handleClose, productForm },
    state: { selectedProductID },
  } = useProductModalContext()
  const [isBrandExpanded, setIsBrandExpanded] = useState(false)

  const {
    control,
    formState: { isValid },
    watch,
  } = productForm

  const unit = watch('unit')
  const productFormat = watch('product_format')

  const productName = watch('name')

  const unitOptions = [
    { label: 'lbs', value: 'lbs' },
    { label: 'kg', value: 'kg' },
    { label: 'oz', value: 'oz' },
  ]

  return (
    <BaseStep
      handleClose={handleClose}
      onContinue={onNext}
      title={selectedProductID ? 'Update Product' : 'Create Product'}
      description="To get started, add preliminary sales estimates. You can always adjust these later."
      continueButtonText="Next"
      cancelButtonText="Cancel"
      currentStep={0}
      totalSteps={3}
      continueDisabled={!isValid}
    >
      <div className="space-y-4">
        <S.Card>
          <h3 className="text-sm font-medium text-gray-900 mb-4">
            Default product sales
          </h3>
          <div className="space-y-4">
            <TextInput
              control={control}
              name="name"
              type="text"
              label={
                `Name` + (productName ? ` (${productName.length}/60)` : '')
              }
              placeholder="Name"
            />

            <TextInput
              control={control}
              name="product_format"
              type="text"
              label="Product Format"
              placeholder="ie, case, bag, can"
            />

            <NumericInputWithUnitSelector
              min={0}
              name="avg_serving_size_per_lb"
              control={control}
              label={`Size (avg. quantity per ${productFormat || 'product'})`}
              placeholder="500"
              allowDecimals
              unit={unit}
              unit_name="unit"
              unitOptions={unitOptions}
            />

            <div className="grid grid-cols-2 gap-y-2 gap-x-4">
              <TextInput
                min={0}
                name="mfg_avg_list_price_per_lb"
                control={control}
                type="number"
                label={`Avg. revenue per ${productFormat || 'product'}`}
                placeholder={`Avg. revenue per ${productFormat || 'product'}`}
                prefix="$"
                allowDecimals
              />

              <TextInput
                min={1}
                max={100}
                name="est_monthly_churn_rate"
                control={control}
                type="number"
                label="Est. monthly churn rate"
                placeholder="Est. monthly churn rate"
                suffix="%"
                allowDecimals
              />
            </div>
          </div>
        </S.Card>

        <S.Card>
          <button
            onClick={() => setIsBrandExpanded(!isBrandExpanded)}
            className="w-full flex items-center justify-between"
          >
            <div className="flex items-center gap-2">
              <h3 className="text-sm font-medium text-gray-900">Brand value</h3>
              <S.Badge>Optional</S.Badge>
            </div>
            {isBrandExpanded ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </button>

          {isBrandExpanded && (
            <>
              <div className="text-sm text-gray-600 mt-2 mb-4">
                You may consider some opportunities more valuable based on their
                reputation. Add details here to see brand values calculated and
                included in total value calculations.
              </div>
              <div className="grid grid-cols-2 gap-y-2 gap-x-4">
                <div>
                  <TextInput
                    min={0}
                    name="value_per_consumer_new_trial"
                    control={control}
                    type="number"
                    label="Value"
                    placeholder="Value"
                    prefix="$"
                    allowDecimals
                  />
                  <p className="text-gray-600 text-sm ml-2 mt-2">
                    Per new informed trial participant
                  </p>
                </div>

                <div>
                  <TextInput
                    min={0}
                    name="lbs_per_case"
                    control={control}
                    type="number"
                    label="Avg. serving size"
                    placeholder="Avg. serving size"
                    suffix={unit || 'lbs'}
                    allowDecimals
                  />
                  <p className="text-gray-600 text-sm ml-2 mt-2">
                    Per new informed trial participant
                  </p>
                </div>

                <TextInput
                  min={0}
                  max={100}
                  name="current_aided_awareness"
                  control={control}
                  type="number"
                  label="Aided awareness"
                  placeholder="Aided awareness"
                  suffix="%"
                  allowDecimals
                />

                <TextInput
                  min={0}
                  max={100}
                  name="current_national_trial_perc"
                  control={control}
                  type="number"
                  label="Existing aided awareness"
                  placeholder="Existing aided awareness"
                  suffix="%"
                  allowDecimals
                />
              </div>
            </>
          )}
        </S.Card>
      </div>
    </BaseStep>
  )
}
