import { useMemo, useState } from 'react'
import * as S from 'components/Tables/CommonTable.styles'
import apiService from '../../services/api'
import dialogConfirm from '../../components/dialogConfirm'
import { ColumnDef } from '@tanstack/react-table'
import { ActionButtons } from '../../components/Buttons/ActionButtons'
import { OverflownText } from '../../components/OverflownText'
import { Campaign } from '../../models/campaign'
import { CampaignsCell } from '../../components/FbUI/StagePill'
import { DataTableContainer } from '../../components/DataTable/UI'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { TableSearch } from 'components/Filters/TableSearch'
import { DateCell } from 'components/Tables/CommonTableCells/DateCell'
import { CAMPAIGNS_COLUMNS } from './campaignsColumns'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { CampaignsFilterset } from './CampaignsFilterset'
import { USDCell } from 'components/Tables/CommonTableCells/USDCell'
import { formatInteger } from 'utils/formatting'
import {
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable/DataTableContext'
import DataTable from 'components/DataTable/DataTable/DataTable'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { IColumnVisibility } from 'components/DataTable/types'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { useQuery } from '@tanstack/react-query'
import { ALL_CAMPAIGNS_TABLE_KEY } from 'constants/tableQueryKeys'
import { IDropdownItem } from 'components/UI/Dropdown/Dropdown'
import { BsDownload } from 'react-icons/bs'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import { cn } from 'components/UI/cn'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import CampaignExportModal from 'components/Modals/ExportModal/CampaignExportModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'

const PAGE_SIZE = 100
interface CampaignsTableProps {
  tableKey: string
  onEditCampaign?: (campaign: Campaign) => void
}

function _CampaignsTable(props: CampaignsTableProps) {
  const api = apiService()
  const campaignsFilterStore = filterStoreRepo.getStore(ALL_CAMPAIGNS_TABLE_KEY)
  const { page, setPage } = campaignsFilterStore()
  const filterParams = useFilterParams(campaignsFilterStore, PAGE_SIZE, page)
  const abortController = new AbortController()
  const deleteCampaignMutation = api.useDeleteCampaign()
  const [showExportModal, setShowExportModal] = useState(false)
  const {
    state: { rowSelection, totalSelectedRows, isAllRowsSelected },
  } = useDataTableContext()

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterParams,
    page: page,
  })

  const { data: campaignData, isFetching } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: () => api.getCampaigns(abortController.signal, filterParams),
    refetchOnMount: true,
  })

  async function handleRowDelete(index: number) {
    const id = campaignData?.results[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this campaign?',
        'Delete Campaign'
      )
    ) {
      await deleteCampaignMutation.mutateAsync(id)
    }
  }

  const selectedCampaignIds = useMemo(() => {
    const accessor = 'id'
    return (
      campaignData?.results

        ?.filter((_: any, i: number) => rowSelection[i])

        ?.map((r: any) => {
          return accessor.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []
    )
  }, [campaignData, rowSelection])

  const unselectedCampaignIds = useMemo(() => {
    if (!isAllRowsSelected) return []
    return (
      campaignData?.results
        ?.filter((_, i) => !rowSelection[i])
        ?.map((r) => r.id) ?? []
    )
  }, [campaignData, rowSelection, isAllRowsSelected])

  const { preferences } = usePreferences()
  const tooltips: Record<string, any> = preferences?.tooltips || {}
  const campaignsColumnHelper = createTableColumnHelper({
    columns: CAMPAIGNS_COLUMNS,
    tooltips,
  })

  const columns = useMemo<ColumnDef<Campaign, any>[]>(
    () => [
      {
        ...campaignsColumnHelper.retrieveColumn('name'),
        accessorKey: 'name',
        size: 300,
        cell: (info) => {
          return <CampaignsCell campaigns={[info.row.original]} />
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('description'),
        accessorKey: 'description',
        size: 400,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('company_type'),
        accessorKey: 'type.name',
        size: 150,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('product'),
        accessorKey: 'product.name',
        size: 250,
        cell: (info) => {
          const value = info.getValue()
          return value ? (
            <OverflownText maxLines={2}>{value}</OverflownText>
          ) : (
            'N/A'
          )
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('created'),
        accessorKey: 'created',
        size: 200,
        cell: DateCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('modified'),
        accessorKey: 'modified',
        size: 200,
        cell: DateCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('days_since_last_update'),
        accessorKey: 'days_since_last_update',
        size: 200,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('contact_company_count'),
        accessorKey: 'contact_company_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('door_count'),
        accessorKey: 'door_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_count'),
        accessorKey: 'deal_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_count_won'),
        accessorKey: 'deal_count_won',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_perc'),
        accessorKey: 'deal_perc',
        cell: (info) => {
          return info.getValue() ? info.getValue() : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_perc_won'),
        accessorKey: 'deal_perc_won',
        cell: (info) => {
          return info.getValue() ? info.getValue() : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('contact_count'),
        accessorKey: 'contact_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('companies_with_contacts'),
        accessorKey: 'companies_with_contacts',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('companies_with_contacts_perc'),
        accessorKey: 'companies_with_contacts_perc',
        cell: (info) => {
          return info.getValue() ? info.getValue() : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('taro'),
        accessorKey: 'taro',

        cell: USDCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('taro_closed'),
        accessorKey: 'taro_closed',

        cell: USDCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('est_meals_day'),
        accessorKey: 'est_meals_day',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 130,
        meta: { rightAlign: true },
        cell: ({ row }) => (
          <ActionButtons
            onDelete={() => handleRowDelete(row.index)}
            onEdit={() => props.onEditCampaign?.(row.original)}
          />
        ),
      },
    ],
    [campaignData]
  )

  const actions = useMemo(() => {
    const items: IDropdownItem[] = [
      {
        label: 'Export Selected',
        icon: <BsDownload size={20} />,
        callback: () => setShowExportModal(true),
      },
    ]

    return items
  }, [])

  return (
    <DataTableContainer key={props.tableKey + '-container'}>
      <DataTable
        tableHeader={
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearch
                className={'flex-1'}
                filterStore={campaignsFilterStore}
                searchPlaceholder="Search by campaign name"
              />
              <CampaignsFilterset filterIdentifier={ALL_CAMPAIGNS_TABLE_KEY} />
              <ColumnSelectorRecipient tableKey={props.tableKey} />
            </div>
            <div className={cn('flex flex-row justify-between gap-4')}>
              <TableButtonAction
                items={actions}
                disabled={!totalSelectedRows}
                selectedRowsCount={totalSelectedRows ?? 0}
              />
              <FilterChips
                identifier={ALL_CAMPAIGNS_TABLE_KEY}
                store={campaignsFilterStore}
                showActive
                clearAllButton
              />
            </div>
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        key={props.tableKey}
        stickyLastColumn={true}
        stickyFirstColumn={true}
        loading={isFetching}
        data={campaignData?.results ?? []}
        columns={columns}
        isPaginationEnabled
        paginationOptions={{
          totalRows: campaignData?.count ?? 0,
          pageSize: PAGE_SIZE,
          setPage: setPage,
          page: page,
          isPaginationLoading: isFetching,
        }}
        footerControls={
          <OpportunitiesTableFooterControls
            hideCreateCompanyButton={true}
            onDownloadButtonClick={() => setShowExportModal(true)}
          />
        }
      />
      <CampaignExportModal
        count={
          (showExportModal
            ? selectedCampaignIds?.length
            : campaignData?.count) ?? 0
        }
        show={showExportModal}
        _handleClose={() => {
          setShowExportModal(false)
        }}
        filters={filterParams}
        selectedIds={!isAllRowsSelected ? selectedCampaignIds : undefined}
        excludeIds={isAllRowsSelected ? unselectedCampaignIds : undefined}
      />
    </DataTableContainer>
  )
}

export function CampaignsTable(
  props: CampaignsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<unknown>
  }
) {
  const { defaultColumnVisibility, ...rest } = props
  return (
    <ColumnsStoreProvider
      tableKey={props.tableKey}
      savedPresetsIdentifier={ALL_CAMPAIGNS_TABLE_KEY}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={props.tableKey}>
        <_CampaignsTable {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
