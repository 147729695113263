import {
  ContactRequestJobResponse,
  RequestedContactResponse,
} from 'models/contact_request'
import { CompanyTypeSlug } from '../models/companies'
import { clientInstance } from '../utils/http-client'
import { baseAPICall } from './baseAPICall'
import { useMutation } from '@tanstack/react-query'
import {
  CONTACT_REQUEST_JOB_TABLE_KEY,
  getCampaignTableKey,
} from '../constants/tableQueryKeys'
import { queryClient } from './queryClient'

export type ContactRequestJobCompanyType = 'chain' | 'k12' | 'cnu' | 'ugc'

/**
 * Create Contact Request Job
 **/
export type CreateContactRequestJobRequest = {
  chain_proxy_ids?: Array<number>
  exclude_chain_proxy_ids?: Array<number>
  max_contacts: number
  sub_category_slug: CompanyTypeSlug
}

export async function createContactRequestJob(
  companyTypeSlug: CompanyTypeSlug,
  body: CreateContactRequestJobRequest,
  params: Record<string, any>
) {
  let companyType: ContactRequestJobCompanyType
  switch (companyTypeSlug) {
    case 'restaurants-bars':
      companyType = 'chain'
      break
    case 'education-cu':
      companyType = 'cnu'
      break
    case 'education-k-12':
      companyType = 'k12'
      break
    default:
      companyType = 'ugc'
      break
  }

  return baseAPICall<void>(() =>
    clientInstance.post(`contact_request/${companyType}/`, body, {
      params: {
        ...params,
      },
    })
  )
}

/**
 * Get All Contact Request Jobs
 **/
export type PaginatedResponse<T> = {
  count: number
  next: string | null
  previous: string | null
  results: Array<T>
}

// Extended pagination response specifically for contact request jobs
export type ContactRequestJobPaginatedResponse =
  PaginatedResponse<ContactRequestJobResponse> & {
    total_credits_used: number
  }

export async function fetchContactRequestJobs(
  params: Record<string, any> = {},
  signal?: AbortSignal
): Promise<ContactRequestJobPaginatedResponse> {
  return baseAPICall<ContactRequestJobPaginatedResponse>(() =>
    clientInstance.get(`contact_request/`, {
      params: params,
      signal,
    })
  )
}

export type ContactRequestJobDetailResponse = ContactRequestJobResponse & {
  requested_contacts: RequestedContactResponse[]
}

export async function fetchContactRequestJobDetails(
  jobId: number
): Promise<ContactRequestJobDetailResponse> {
  return baseAPICall<ContactRequestJobDetailResponse>(() =>
    clientInstance.get(`contact_request/${jobId}/`)
  )
}

/**
 * Fetch Requested Contacts
 */
export async function fetchRequestedContacts(
  jobId: number,
  params: Record<string, string | number> = {},
  signal?: AbortSignal
): Promise<PaginatedResponse<RequestedContactResponse>> {
  return baseAPICall<PaginatedResponse<RequestedContactResponse>>(() =>
    clientInstance.get(`contact_request/${jobId}/requested-contacts/`, {
      params: params,
      signal,
    })
  )
}

/* -------------
Request Contacts From Campaign
-------------- */
interface RequestContactsFromCampaignProps {
  campaign_id: number
  max_contacts: number
  company_ids: number[]
}

function requestContactsFromCampaign(args: RequestContactsFromCampaignProps) {
  return baseAPICall(
    () =>
      clientInstance.post(`contact_request/campaign/`, {
        campaign_id: args.campaign_id,
        max_contacts: args.max_contacts,
        company_ids: args.company_ids,
      }),
    {
      successMessage: 'Contacts requested from campaign successfully',
    }
  )
}

export const useRequestContactsFromCampaign = () => {
  return useMutation({
    mutationFn: (variables: RequestContactsFromCampaignProps) =>
      requestContactsFromCampaign(variables),
    onSettled: (_, __, variables) => {
      const queryKey = getCampaignTableKey(
        CONTACT_REQUEST_JOB_TABLE_KEY,
        variables.campaign_id
      )
      void queryClient.refetchQueries({ queryKey: [queryKey] })
    },
  })
}

/* -------------
Check Contact Request Status
-------------- */
export type ContactStatusResponse = {
  status: 'in_progress' | 'found_contacts' | null
  message: string
  companies_in_progress?: Record<string, any>
  companies_with_contacts?: Record<string, any>
  has_companies_in_progress?: boolean
  has_companies_with_contacts?: boolean
  campaign_companies?: {
    results: Array<{
      id: number
      name: string
      status: string
    }>
    total_count: number
  }
}

export async function checkContactRequestStatus(
  campaignId: number
): Promise<ContactStatusResponse> {
  return baseAPICall(() =>
    clientInstance.get('contact_request/check-status/', {
      params: {
        campaign_id: campaignId,
      },
    })
  )
}
