import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'
import { LODGING_COLUMNS } from 'components/DataExpansionComponents/GooglePlaces/Lodging/LodgingColumnsInfo'

export default function LodgingExportModal(props: ExportModalProps) {
  const api = apiService()

  const dataTypeName = 'Lodging & Hospitality'

  const exportLodging = async ({
    filter,
    selectedColumns,
    exportType,
    ids,
    excludeIds,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
    ids?: number[] | undefined
    excludeIds?: number[] | undefined
  }) => {
    const res = await api.exportLodging(
      filter,
      selectedColumns,
      exportType,
      ids,
      excludeIds,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: ${dataTypeName} - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const nursingHomeColumnHelper = createExportColumnHelper({
    columns: LODGING_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        nursingHomeColumnHelper.retrieveColumn('name'),
        nursingHomeColumnHelper.retrieveColumn('city_summary'),
        nursingHomeColumnHelper.retrieveColumn('state_summary'),
        nursingHomeColumnHelper.retrieveColumn('zipcode_summary'),
        nursingHomeColumnHelper.retrieveColumn('domain'),
        nursingHomeColumnHelper.retrieveColumn('reviews_avg'),
        nursingHomeColumnHelper.retrieveColumn('reviews_cnt'),
        nursingHomeColumnHelper.retrieveColumn('reviews_per_score'),
        nursingHomeColumnHelper.retrieveColumn('open_hours'),
        nursingHomeColumnHelper.retrieveColumn('doors_cnt'),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: getCommonChainProxyExportColumns(),
    },
  ]

  return (
    <ExportModal
      title={`Select Columns for ${dataTypeName} Download`}
      subTitle={`Download up to 10,000 ${dataTypeName.toLocaleLowerCase()} at a time, with any of the following columns. Any filters already applied will remain active.`}
      submitText={`Export ${dataTypeName}`}
      columns={columns}
      exportAPIAction={exportLodging}
      {...props}
    />
  )
}
