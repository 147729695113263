import { usePagination } from 'utils/usePagination'
import * as S from './styles'
import { ITablePaginationOptions } from '../types'
import { useDataTableContext } from '../DataTableContext'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import FbButton from 'components/FbUI/FbButton'

export interface ITablePaginationProps {
  paginationOptions?: ITablePaginationOptions
}

export default function DataTablePagination({
  paginationOptions = {
    page: 1,
    totalRows: 0,
    pageSize: 100,
    setPage: () => {},
    isPaginationLoading: false,
  },
}: ITablePaginationProps) {
  const pageCount = Math.ceil(
    paginationOptions.totalRows / paginationOptions.pageSize
  )
  const currentPage = paginationOptions.page ?? 1
  const canPreviousPage = currentPage > 1
  const canNextPage = currentPage < pageCount

  function nextPage() {
    paginationOptions.setPage?.(currentPage + 1)
  }

  function prevPage() {
    paginationOptions.setPage?.(currentPage - 1)
  }

  const paginationRange = usePagination({
    siblingCount: 1,
    currentPage: currentPage,
    pageCount: pageCount,
  })

  const {
    methods: { clearSelectedRows },
  } = useDataTableContext()

  return (
    <S.DataTablePaginationContainer>
      <FbButton
        variant="secondary"
        onClick={() => {
          if (canPreviousPage) {
            clearSelectedRows()
            prevPage()
          }
        }}
        style={{
          visibility: canPreviousPage ? 'visible' : 'hidden',
        }}
        disabled={paginationOptions.isPaginationLoading}
      >
        <MdArrowBack />
        Previous
      </FbButton>
      <S.DataTablePaginationButtonsContainer>
        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === '...') {
            return (
              <S.DataTablePaginationDots key={index}>
                <span>. . .</span>
              </S.DataTablePaginationDots>
            )
          }

          return (
            <S.PageButton
              isActive={pageNumber === currentPage}
              key={'page-' + pageNumber}
              onClick={() => {
                clearSelectedRows()
                paginationOptions.setPage?.(pageNumber as number)
              }}
            >
              {pageNumber}
            </S.PageButton>
          )
        })}
      </S.DataTablePaginationButtonsContainer>
      <FbButton
        variant="secondary"
        onClick={() => {
          if (canNextPage) {
            clearSelectedRows()
            nextPage()
          }
        }}
        style={{
          visibility: canNextPage ? 'visible' : 'hidden',
        }}
        disabled={paginationOptions.isPaginationLoading}
      >
        Next
        <MdArrowForward />
      </FbButton>
    </S.DataTablePaginationContainer>
  )
}
