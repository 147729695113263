import { RebateOfferProduct } from '../tradespend_api'
import styled from 'styled-components'
import { ProductVariantInfo } from './ProductVariantInfo'

interface ProductApprovalSectionProps {
  productApprovalInfo: Record<string, any>
  productMap: Record<string, RebateOfferProduct>
  variantMap: Record<string, RebateOfferProduct['product_variants'][0]>
}

export function ProductApprovalSection({
  productApprovalInfo,
  productMap,
  variantMap,
}: ProductApprovalSectionProps) {
  // Helper function to calculate total units for a product
  const calculateTotalUnits = (
    variantInfo: Record<string, { num_units: number }>
  ) => {
    return Object.values(variantInfo).reduce(
      (sum, variant) => sum + variant.num_units,
      0
    )
  }

  return Object.keys(productApprovalInfo).length === 0 ? (
    <div className="text-gray-500 italic p-4 bg-gray-50 rounded-lg">
      No products have been approved for this invoice yet.
    </div>
  ) : (
    <ProductsGrid>
      {Object.entries(productApprovalInfo).map(([productId, variantInfo]) => {
        const product = productMap[productId]
        return (
          <ProductCard key={productId}>
            <div className="flex justify-between items-start mb-3">
              <div>
                <ProductTitle>
                  {product?.product_name || `Product ID: ${productId}`}
                </ProductTitle>
                <div className="text-sm text-gray-500">
                  {calculateTotalUnits(variantInfo)} total units approved
                </div>
                {product?.product_desc && (
                  <div className="text-sm text-gray-500 mt-1">
                    {product.product_desc}
                  </div>
                )}
              </div>
            </div>
            {Object.entries(
              variantInfo as Record<
                string,
                {
                  num_units: number
                  product_identifiers: Array<{
                    product_identifier_type: string
                    product_identifier: string
                  }>
                }
              >
            ).map(([variantId, info]) => (
              <ProductVariantInfo
                key={variantId}
                variant={variantMap[variantId]}
                variantId={variantId}
                info={info}
              />
            ))}
          </ProductCard>
        )
      })}
    </ProductsGrid>
  )
}

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
`

const ProductCard = styled.div`
  padding: 16px;
  background: #f9fafb;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
`

const ProductTitle = styled.div`
  font-weight: 600;
  color: #111827;
  margin-bottom: 4px;
`
