import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import { toast } from 'react-toastify'
import apiService from '../../../services/api'
import { z } from 'zod'
import { checkContactRequestStatus } from 'services/contactRequestApi'
import { useSelection } from './hooks/useSelection'

// Form validation schema
export const contactRequestFormSchema = z.object({
  company_name_pattern: z
    .string()
    .trim()
    .min(1, 'Company name pattern is required'),
  job_title: z.string().trim().min(1, 'Job title is required'),
  message: z.string().trim().min(1, 'Message is required'),
})

// Response types
export type ContactStatusResponse = {
  status: 'in_progress' | 'found_contacts' | null
  message: string
  companies_in_progress?: Record<number, any>
  companies_with_contacts?: Record<number, any>
  has_companies_in_progress?: boolean
  has_companies_with_contacts?: boolean
  campaign_companies?: {
    results: Array<{
      id: number
      name: string
      status: string
    }>
    total_count: number
  }
}

export enum ContactRequestStep {
  IN_PROGRESS = 0,
  FOUND_CONTACTS = 1,
  CONFIRM = 2,
  SUCCESS = 3,
}

type Company = {
  id: number
  name: string
  last_requested_date?: string
  last_found_date?: string
  selected?: boolean
}

type CampaignCompany = {
  id: number
  name: string
  status: string
}

type ContactRequestContextType = {
  campaignId: number
  step: ContactRequestStep
  setStep: (step: ContactRequestStep) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  error: string | null
  setError: (error: string | null) => void
  companyNamePattern: string
  setCompanyNamePattern: (pattern: string) => void
  jobTitle: string
  setJobTitle: (title: string) => void
  requestMessage: string
  setRequestMessage: (message: string) => void
  companiesInProgress: Company[]
  setCompaniesInProgress: (companies: Company[]) => void
  companiesWithContacts: Company[]
  setCompaniesWithContacts: (companies: Company[]) => void
  campaignCompanies: CampaignCompany[]
  campaignCompaniesTotal: number
  checkStatus: () => Promise<void>
  createJob: (
    maxContacts?: number,
    selectedCompanyIds?: number[]
  ) => Promise<void>
  selectAllCompaniesInProgress: (selected: boolean) => void
  selectAllCompaniesWithContacts: (selected: boolean) => void
  toggleCompanyInProgressSelection: (companyId: number) => void
  toggleCompanyWithContactsSelection: (companyId: number) => void
  getSelectedCompaniesInProgress: () => Company[]
  getSelectedCompaniesWithContacts: () => Company[]
  // Keep compatibility with existing code
  nextStep: () => void
  prevStep: () => void
  resetFlow: () => void
  handleClose: () => void
}

const ContactRequestContext = createContext<
  ContactRequestContextType | undefined
>(undefined)

export const ContactRequestProvider: React.FC<{
  children: ReactNode
  campaignId: number
  onStepChange?: (step: number) => void
  handleClose: () => void
  onContactRequested?: () => void
}> = ({
  children,
  campaignId,
  onStepChange,
  handleClose,
  onContactRequested,
}) => {
  const api = apiService()
  const mutation = api.useRequestContactsFromCampaign()

  // Step management
  const [step, setStep] = useState<ContactRequestStep>(
    ContactRequestStep.IN_PROGRESS
  )

  // Loading state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [companyNamePattern, setCompanyNamePattern] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [requestMessage, setRequestMessage] = useState('')
  const [companiesInProgress, setCompaniesInProgress] = useState<Company[]>([])
  const [companiesWithContacts, setCompaniesWithContacts] = useState<Company[]>(
    []
  )
  const [campaignCompanies, setCampaignCompanies] = useState<CampaignCompany[]>(
    []
  )
  const [campaignCompaniesTotal, setCampaignCompaniesTotal] = useState(0)

  // Use the useSelection hook for selection state management
  const inProgressSelection = useSelection(
    companiesInProgress,
    setCompaniesInProgress
  )

  const withContactsSelection = useSelection(
    companiesWithContacts,
    setCompaniesWithContacts
  )

  // Effect to notify parent of step changes
  useEffect(() => {
    if (onStepChange) {
      onStepChange(step)
    }
  }, [step, onStepChange])

  const checkStatus = useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await checkContactRequestStatus(campaignId)

      if (response.companies_in_progress) {
        // Convert object to array of Company objects
        const inProgressCompanies = Object.entries(
          response.companies_in_progress
        ).map(([id, data]: [string, any]) => ({
          id: parseInt(id),
          name: data.name || `Company ${id}`,
          last_requested_date: data.created,
          selected: false,
        }))
        setCompaniesInProgress(inProgressCompanies)
      } else {
        setCompaniesInProgress([])
      }

      if (response.companies_with_contacts) {
        // Convert object to array of Company objects
        const companiesWithContactsArray = Object.entries(
          response.companies_with_contacts
        ).map(([id, data]: [string, any]) => ({
          id: parseInt(id),
          name: data.name || `Company ${id}`,
          last_found_date: data.last_found_date || data.created,
          selected: false,
        }))
        setCompaniesWithContacts(companiesWithContactsArray)
      } else {
        setCompaniesWithContacts([])
      }

      // Store campaign companies data
      if (response.campaign_companies) {
        setCampaignCompanies(response.campaign_companies.results)
        setCampaignCompaniesTotal(response.campaign_companies.total_count)
      }

      // Determine next step based on available companies
      if (response.has_companies_in_progress) {
        setStep(ContactRequestStep.IN_PROGRESS)
      } else if (response.has_companies_with_contacts) {
        setStep(ContactRequestStep.FOUND_CONTACTS)
      } else {
        setStep(ContactRequestStep.CONFIRM)
      }
    } catch (err: any) {
      setError(err.message || 'Failed to check status')
      toast.error(err.message || 'Failed to check status')
    } finally {
      setLoading(false)
    }
  }, [
    campaignId,
    setCompaniesInProgress,
    setCompaniesWithContacts,
    setCampaignCompanies,
    setCampaignCompaniesTotal,
    setStep,
    setLoading,
    setError,
  ])

  const createJob = async (
    maxContacts: number = 1,
    selectedCompanyIds: number[] = []
  ) => {
    setLoading(true)
    setError(null)

    try {
      // Use the mutation which follows the RequestContactsFromCampaignProps interface
      await mutation.mutateAsync({
        campaign_id: campaignId,
        max_contacts: maxContacts,
        company_ids: selectedCompanyIds,
      })

      setStep(ContactRequestStep.SUCCESS)

      if (onContactRequested) {
        onContactRequested()
      }
    } catch (err: any) {
      setError(err.message || 'Failed to create contact request job')
      toast.error(err.message || 'Failed to create contact request job')
    } finally {
      setLoading(false)
    }
  }

  // Add a function to determine the next step based on the current step
  const getNextStep = useCallback(() => {
    switch (step) {
      case ContactRequestStep.IN_PROGRESS:
        // If there are companies with contacts, go to found contacts step
        if (companiesWithContacts.length) {
          return ContactRequestStep.FOUND_CONTACTS
        }
        return ContactRequestStep.CONFIRM
      case ContactRequestStep.FOUND_CONTACTS:
        return ContactRequestStep.CONFIRM
      case ContactRequestStep.CONFIRM:
        return ContactRequestStep.SUCCESS
      case ContactRequestStep.SUCCESS:
        return ContactRequestStep.SUCCESS
      default:
        return ContactRequestStep.CONFIRM
    }
  }, [step, companiesWithContacts])

  // Navigation helpers
  const nextStep = useCallback(() => {
    const next = getNextStep()
    setStep(next)
  }, [getNextStep])

  const prevStep = useCallback(() => {
    switch (step) {
      case ContactRequestStep.FOUND_CONTACTS:
        setStep(ContactRequestStep.IN_PROGRESS)
        break
      case ContactRequestStep.CONFIRM:
        if (companiesWithContacts.length) {
          setStep(ContactRequestStep.FOUND_CONTACTS)
        } else {
          setStep(ContactRequestStep.IN_PROGRESS)
        }
        break
      default:
        break
    }
  }, [step, companiesWithContacts.length])

  const resetFlow = useCallback(() => {
    setStep(ContactRequestStep.IN_PROGRESS)
    setCompaniesInProgress([])
    setCompaniesWithContacts([])
    setError(null)
  }, [])

  return (
    <ContactRequestContext.Provider
      value={{
        campaignId,
        step,
        setStep,
        loading,
        setLoading,
        error,
        setError,
        companyNamePattern,
        setCompanyNamePattern,
        jobTitle,
        setJobTitle,
        requestMessage,
        setRequestMessage,
        companiesInProgress,
        setCompaniesInProgress,
        companiesWithContacts,
        setCompaniesWithContacts,
        campaignCompanies,
        campaignCompaniesTotal,
        checkStatus,
        createJob,
        selectAllCompaniesInProgress: inProgressSelection.selectAll,
        selectAllCompaniesWithContacts: withContactsSelection.selectAll,
        toggleCompanyInProgressSelection: inProgressSelection.toggleSelection,
        toggleCompanyWithContactsSelection:
          withContactsSelection.toggleSelection,
        getSelectedCompaniesInProgress: inProgressSelection.getSelected,
        getSelectedCompaniesWithContacts: withContactsSelection.getSelected,
        nextStep,
        prevStep,
        resetFlow,
        handleClose,
      }}
    >
      {children}
    </ContactRequestContext.Provider>
  )
}

export const useContactRequestContext = () => {
  const context = useContext(ContactRequestContext)
  if (context === undefined) {
    throw new Error(
      'useContactRequestContext must be used within a ContactRequestProvider'
    )
  }
  return context
}
