import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { TextInput } from 'components/FormUtils/TextInput'
import { FilterIdentifier } from 'models/saved_view'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { z } from 'zod'
import { Modal } from '../../UI/Modal/Modal'
import { FilterStore } from 'components/Filters/FilterStore'
import { Checkbox } from '../../FormUtils/Checkbox'

const filterViewSchema = z.object({
  viewName: z.string().min(3, 'Must be at least 3 characters'),
  shared: z.boolean(),
})

type SaveFilterModalProps = {
  show: boolean
  setShow: (show: boolean) => void
  identifier: FilterIdentifier
  filterStore: FilterStore
}

export default function SaveFilterModal({
  show,
  setShow,
  identifier,
  filterStore,
}: SaveFilterModalProps) {
  const _handleClose = () => setShow(false)
  const api = apiService()
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm({
    resolver: zodResolver(filterViewSchema),
    defaultValues: { viewName: '', shared: true },
    mode: 'onChange',
  })

  const { valueFilters, rangeFilters, zipCodeFilter, search, criteria } =
    filterStore()

  const onSubmit = handleSubmit(async (data) => {
    try {
      const filters = {
        valueFilters,
        rangeFilters,
        zipCodeFilter,
        search,
        criteria,
      }

      await api.saveFilters({
        identifier,
        name: data.viewName,
        shared: data.shared,
        payload: filters,
      })

      await queryClient.invalidateQueries({
        queryKey: ['saved_filters', identifier],
      })

      reset()
      toast.success('Filter saved!')
      _handleClose()
    } catch (e: any) {
      if (e.response?.data?.error) {
        const errorMsg = e.response.data.error
        setError('viewName', { message: errorMsg })
      } else {
        toast.error(`Error saving filter: ${e.message}`)
      }
    }
  })

  return (
    <Modal
      open={show}
      onOpenChange={(open) => !open && _handleClose()}
      title={'Save Filter'}
      onAccept={onSubmit}
      acceptButtonText={'Save'}
      description={`Create a new filter to save this view, which includes both filters and any search terms.`}
      loading={isSubmitting}
      blockAccept={!isValid}
    >
      <TextInput
        control={control}
        label="Filter Name"
        name="viewName"
        placeholder="Hint: make names descriptive to help find them later!"
      />
      <Checkbox
        style={{ marginTop: 5 }}
        control={control}
        name={'shared'}
        label={'Share filter with team'}
      />
    </Modal>
  )
}
