import apiService from 'services/api'
import * as S from './styles'

function RebatePlatformAgreement() {
  const api = apiService()

  const { data } = api.useGetTextContent()

  return (
    <S.Page>
      <S.Container>
        <div
          className="px-5 py-10"
          dangerouslySetInnerHTML={{ __html: data?.rebates_platform_agreement }}
        />
      </S.Container>
    </S.Page>
  )
}

export default RebatePlatformAgreement
