import { zodResolver } from '@hookform/resolvers/zod'
import to from 'await-to-js'
import { CompanyAutocomplete } from 'components/CompanyAutocomplete'
import FbButton from 'components/FbUI/FbButton'
import { Checkbox } from 'components/FormUtils/Checkbox'
import { PhoneInput } from 'components/FormUtils/PhoneInput'
import Select from 'components/FormUtils/Select'
import { TextInput } from 'components/FormUtils/TextInput'
import { TextareaInput } from 'components/FormUtils/TextareaInput'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineEdit } from 'react-icons/ai'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { z } from 'zod'
import { Modal } from '../../UI/Modal/Modal'
import { TableTypeSlug } from 'models/companies'

const suggestionFormShape = z
  .object({
    incorrect_name: z
      .boolean()
      .default(false)
      .describe('Is the name incorrect?'),
    suggested_name: z.string().optional().describe('Suggested Name').nullable(),

    // chain only
    incorrect_website: z
      .boolean()
      .default(false)
      .describe('Is the website incorrect?'),
    suggested_website: z
      .string()
      .optional()
      .describe('Suggested Website')
      .nullable(),

    incorrect_category: z
      .boolean()
      .default(false)
      .describe('Is category correction needed?'),
    suggested_sub_category: z
      .number()
      .optional()
      .describe('Suggested Sub Category')
      .nullable(),

    missing_doors: z
      .boolean()
      .default(false)
      .describe('Are there missing doors?'),
    incorrect_doors: z
      .boolean()
      .default(false)
      .describe("Are there doors in this chain that shouldn't be?"),

    // door only
    incorrect_address: z
      .boolean()
      .default(false)
      .describe('Is the address incorrect?'),
    suggested_address: z
      .string()
      .optional()
      .describe('Suggested Address')
      .nullable(),

    incorrect_phone_number: z
      .boolean()
      .default(false)
      .describe('Is the phone number incorrect?'),
    suggested_phone_number: z
      .string()
      .optional()
      .describe('Suggested Phone Number')
      .nullable(),

    location_closed: z
      .boolean()
      .default(false)
      .describe('Is the location closed?'),
    incorrect_chain_association: z
      .boolean()
      .default(false)
      .describe("Is this location part of a chain it shouldn't be?"),

    incorrect_chain_association_different_chain: z
      .boolean()
      .default(false)
      .describe('Should this location be part of a different chain?'),
    suggested_contact_company: z
      .object({
        name: z.string(),
        id: z.number(),
        domain: z.string().nullable().optional(),
      })
      .optional()
      .nullable(),

    incorrect_food_mgmt_company: z
      .boolean()
      .default(false)
      .describe('Is the food management company incorrect?'),
    suggested_food_mgmt_company: z.string().optional().nullable(),

    incorrect_parent_food_mgmt_company: z
      .boolean()
      .default(false)
      .describe('Is the parent food management company incorrect?'),
    suggested_parent_food_mgmt_company: z.string().optional().nullable(),

    // chain & door
    missing_information: z
      .boolean()
      .default(false)
      .describe('Is there missing information?'),

    something_else: z.string().optional().nullable().describe('Something Else'),
  })
  .superRefine((data, ctx) => {
    if (data.incorrect_name && !data.suggested_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Suggested name is required if incorrect name is true',
        path: ['suggested_name'],
      })
    }
    if (data.incorrect_website && !data.suggested_website) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Suggested website is required if incorrect website is true',
        path: ['suggested_website'],
      })
    }
    if (data.incorrect_address && !data.suggested_address) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Suggested address is required if incorrect address is true',
        path: ['suggested_address'],
      })
    }
    if (data.incorrect_phone_number && !data.suggested_phone_number) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Suggested phone number is required if incorrect phone number is true',
        path: ['suggested_phone_number'],
      })
    }
    if (data.incorrect_category && !data.suggested_sub_category) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Suggested category is required if category correction needed is true',
        path: ['suggested_sub_category'],
      })
    }
    if (
      data.incorrect_chain_association_different_chain &&
      !data.suggested_contact_company
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Suggested chain contact company is required if incorrect chain association is true',
        path: ['suggested_contact_company'],
      })
    }
  })

type SuggestionModalProps = {
  contactCompanyId: number
  doorId?: number
  defaultValues?: Partial<z.infer<typeof suggestionFormShape>>
  tableTypeSlug: TableTypeSlug
}

export default function SuggestionModal({
  contactCompanyId,
  doorId,
  defaultValues,
  tableTypeSlug,
}: SuggestionModalProps) {
  const api = apiService()
  const { data: categories } = api.useGetCompanyCategories()
  const { data: foodCompanyManagementOptions } =
    api.useGetUniversityFoodManagementCompanyOptions(false)

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    reset()
  }

  const defaultFormValues = {
    incorrect_name: false,
    suggested_name: null,
    incorrect_website: false,
    suggested_website: null,
    incorrect_address: false,
    suggested_address: null,
    incorrect_phone_number: false,
    suggested_phone_number: null,
    location_closed: false,
    incorrect_category: false,
    suggested_sub_category: null,
    missing_information: false,
    suggested_contact_company: null,
    something_else: '',
    // chain specific
    missing_doors: false,
    incorrect_doors: false,
    // door specific
    incorrect_chain_association: false,
    incorrect_chain_association_different_chain: false,
    // cu specific
    incorrect_food_mgmt_company: false,
    suggested_food_mgmt_company: null,
    incorrect_parent_food_mgmt_company: false,
    suggested_parent_food_mgmt_company: null,
  }

  const { control, reset, handleSubmit, formState, watch } = useForm({
    resolver: zodResolver(suggestionFormShape),
    defaultValues: {
      ...defaultFormValues,
      ...defaultValues,
    },
  })

  const watchIncorrectName = watch('incorrect_name')

  // door specific
  const watchIncorrectAddress = watch('incorrect_address')
  const watchIncorrectPhoneNumber = watch('incorrect_phone_number')
  const watchIncorrectChainAssociationDiffChain = watch(
    'incorrect_chain_association_different_chain'
  )

  // chain specific
  const watchIncorrectCategory = watch('incorrect_category')
  const watchIncorrectWebsite = watch('incorrect_website')

  // cu specific
  const watchIncorrectFoodMgmtCompany = watch('incorrect_food_mgmt_company')
  const watchIncorrectParentFoodMgmtCompany = watch(
    'incorrect_parent_food_mgmt_company'
  )

  const typeOptions = useMemo(() => {
    return (
      categories?.company_categories.flatMap((category) =>
        category.sub_categories.map((subCategory) => ({
          label:
            subCategory.name === 'Other'
              ? 'Other ' + category.name
              : subCategory.name,
          value: subCategory.id,
        }))
      ) || []
    )
  }, [categories, show])

  const getOnlyChangedOrNewValues = (
    values: z.infer<typeof suggestionFormShape>
  ) => {
    return Object.keys(values).reduce((acc, key) => {
      const typedKey = key as keyof typeof values
      if (
        Object.keys(defaultValues ?? {}).includes(typedKey as string) === false
      ) {
        if (values[typedKey]) {
          acc[typedKey] = values[typedKey]
        }
      } else if (values[typedKey] !== defaultValues?.[typedKey]) {
        acc[typedKey] = values[typedKey]
      }
      return acc
    }, {} as any)
  }

  const onSubmit = handleSubmit(async (data) => {
    const [err] = await to(
      api.postSuggestion({
        contact_company: contactCompanyId,
        restaurant: doorId,
        form_json: getOnlyChangedOrNewValues(data),
        suggested_contact_company:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (data.suggested_contact_company as any)?.id,
      })
    )
    if (err) {
      toast.error(`Error submitting suggestion: ${err.message}`)
      return
    }
    toast.success('Suggestion submitted successfully!')
    handleClose()
  })

  const incorrectCategoryText = useMemo(() => {
    const defaultCategoryText = 'restaurant/bar'
    const typeMap: Partial<Record<TableTypeSlug, string>> = {
      'education-k-12': 'school/district',
      'education-cu': 'college/university',
      'healthcare-hospitals': 'hospital/clinic',
      'healthcare-nursinghomes': 'nursing home',
    }
    return typeMap[tableTypeSlug] || defaultCategoryText
  }, [foodCompanyManagementOptions?.parent_food_management_companies])

  const foodMgmtCompanyOptions = useMemo(() => {
    const otherOption = {
      label: 'Other (Add Below)',
      value: 'other',
    }

    const options = foodCompanyManagementOptions?.food_management_companies
      ? [otherOption, ...foodCompanyManagementOptions.food_management_companies]
      : [otherOption]

    return options
  }, [foodCompanyManagementOptions?.food_management_companies])

  const parentFoodMgmtCompanyOptions = useMemo(() => {
    const otherOption = {
      label: 'Other (Add Below)',
      value: 'other',
    }

    const options =
      foodCompanyManagementOptions?.parent_food_management_companies
        ? [
            otherOption,
            ...foodCompanyManagementOptions.parent_food_management_companies,
          ]
        : [otherOption]

    return options
  }, [foodCompanyManagementOptions?.parent_food_management_companies])

  return (
    <>
      <FbButton
        variant="secondary"
        onClick={() => setShow(true)}
        className="whitespace-nowrap"
      >
        <AiOutlineEdit color="#344054" />
        <span className="text-[#344054] font-normal">Suggest an edit</span>
      </FbButton>
      <Modal
        title={'Suggest an edit'}
        open={show}
        description={
          'Is something missing, or look incorrect? Let us know here.'
        }
        onOpenChange={(open) => !open && handleClose()}
        onAccept={onSubmit}
        acceptButtonText={'Submit'}
        blockAccept={!formState.isValid}
      >
        <div className="grid gap-3">
          {!doorId && (
            <>
              <Checkbox control={control} name="incorrect_category">
                This isn&apos;t a {incorrectCategoryText}
              </Checkbox>
              {watchIncorrectCategory && (
                <Select
                  className={'w-full mt-0'}
                  control={control}
                  name="suggested_sub_category"
                  placeholder={'Type'}
                  options={typeOptions}
                />
              )}
            </>
          )}
          <Checkbox control={control} name="incorrect_name">
            The name is incorrect
          </Checkbox>
          {watchIncorrectName && (
            <TextInput
              name="suggested_name"
              placeholder="Restaurant ABC"
              control={control}
              noMargin
            />
          )}
          {doorId && (
            <>
              <Checkbox control={control} name="incorrect_address">
                The address is incorrect
              </Checkbox>
              {watchIncorrectAddress && (
                <TextInput
                  name="suggested_address"
                  placeholder="123 Main St, New York, NY 10001"
                  control={control}
                  noMargin
                />
              )}
              <Checkbox control={control} name="incorrect_phone_number">
                The phone number is incorrect
              </Checkbox>
              {watchIncorrectPhoneNumber && (
                <PhoneInput name="suggested_phone_number" control={control} />
              )}
              <Checkbox control={control} name="location_closed">
                This location is closed
              </Checkbox>
              <Checkbox control={control} name="incorrect_chain_association">
                This location is part of a chain it shouldn&apos;t be{' '}
                <span className="text-sm text-gray-500">(split/remove)</span>
              </Checkbox>
              <Checkbox
                control={control}
                name="incorrect_chain_association_different_chain"
              >
                This location should be part of a different chain{' '}
                <span className="text-sm text-gray-500">(add)</span>
              </Checkbox>
              {watchIncorrectChainAssociationDiffChain && (
                <CompanyAutocomplete
                  control={control}
                  name="suggested_contact_company"
                  placeholder="Start typing company name"
                  label={null}
                  className="!mt-0"
                  chainsOnly={true}
                />
              )}
            </>
          )}
          {!doorId && tableTypeSlug === 'restaurants-bars' && (
            <>
              <Checkbox control={control} name="incorrect_website">
                The website/domain is incorrect
              </Checkbox>
              {watchIncorrectWebsite && (
                <TextInput
                  name="suggested_website"
                  placeholder="https://restaurantabc.com"
                  control={control}
                  noMargin
                />
              )}
              <Checkbox control={control} name="missing_doors">
                There are doors missing in this chain{' '}
                <span className="text-sm text-gray-500">(add)</span>
              </Checkbox>
              <Checkbox control={control} name="incorrect_doors">
                There are doors in this chain that shouldn&apos;t be{' '}
                <span className="text-sm text-gray-500">(remove)</span>
              </Checkbox>
            </>
          )}
          {tableTypeSlug === 'education-cu' && (
            <>
              <Checkbox control={control} name="incorrect_food_mgmt_company">
                The food management company is incorrect
              </Checkbox>
              {watchIncorrectFoodMgmtCompany && (
                <Select
                  className={'w-full'}
                  control={control}
                  name="suggested_food_mgmt_company"
                  placeholder={'Food Mgmt Company'}
                  options={foodMgmtCompanyOptions}
                />
              )}
              <Checkbox
                control={control}
                name="incorrect_parent_food_mgmt_company"
              >
                The parent food management company is incorrect
              </Checkbox>
              {watchIncorrectParentFoodMgmtCompany && (
                <Select
                  className={'w-full'}
                  control={control}
                  name="suggested_parent_food_mgmt_company"
                  placeholder={'Parent Food Mgmt Company'}
                  options={parentFoodMgmtCompanyOptions}
                />
              )}
            </>
          )}
          <Checkbox control={control} name="missing_information">
            {doorId
              ? 'This location is missing information '
              : 'There is missing information '}
            <span className="text-sm text-gray-500">(add below)</span>
          </Checkbox>
          <TextareaInput
            name="something_else"
            label="Something else"
            placeholder="Share details about what should be added or changed."
            control={control}
          />
        </div>
      </Modal>
    </>
  )
}
