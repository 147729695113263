import React from 'react'
import { useContactRequestContext } from './ContactRequestContext'
import { StepContainer } from './components/StepContainer'
import { SelectableCompaniesTable } from './components/SelectableCompaniesTable'
import { useSelection } from './hooks/useSelection'

export function InProgressStep() {
  const {
    companiesInProgress,
    setCompaniesInProgress,
    toggleCompanyInProgressSelection,
    selectAllCompaniesInProgress,
    loading,
    error,
    nextStep,
    prevStep,
    handleClose,
  } = useContactRequestContext()

  // Use the selection hook for more consistent behavior
  const { getContinueButtonText } = useSelection(
    companiesInProgress,
    setCompaniesInProgress
  )

  // Get the button text based on selection
  const continueButtonText = getContinueButtonText('in-progress requests')

  return (
    <StepContainer
      title="Companies with In-Progress Requests"
      subtitle={
        <>
          {companiesInProgress.length} companies already have requests in
          progress. You can <strong>skip</strong> the companies and remove them
          from the request, or <strong>select</strong> the companies to include
          in the request and find additional contacts.
        </>
      }
      onBack={prevStep}
      onContinue={nextStep}
      handleClose={handleClose}
      continueButtonText={continueButtonText}
      loading={loading}
      error={error}
      currentStep={1}
      totalSteps={3}
    >
      <SelectableCompaniesTable
        companies={companiesInProgress}
        toggleCompanySelection={toggleCompanyInProgressSelection}
        selectAllCompanies={selectAllCompaniesInProgress}
        dateColumn={{
          header: 'Requested',
          accessorKey: 'requested',
          dateField: 'last_requested_date',
        }}
        enableLogging={false}
      />
    </StepContainer>
  )
}
