import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useContactRequestContext } from '../ContactRequestContext'
import { StepContainer } from './StepContainer'

interface LoadingStepProps {
  title: string
  currentStep: number
  totalSteps?: number
}

export function LoadingStep({
  title,
  currentStep,
  totalSteps = 3,
}: LoadingStepProps) {
  const { handleClose } = useContactRequestContext()

  return (
    <StepContainer
      title={title}
      handleClose={handleClose}
      loading={true}
      currentStep={currentStep}
      totalSteps={totalSteps}
      continueButtonText="Please wait"
    >
      <div className="flex justify-center items-center py-10">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    </StepContainer>
  )
}
