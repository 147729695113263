import { DataTableColumnSort } from '../../../../DataTable/DataTableSorting/DataTableSortingStore'
import { useGooglePlacesBaseTableCols } from '../../GooglePlaceBase/GooglePlaceBaseTable/GooglePlaceBaseTableColumns'
import { LODGING_COLUMNS } from '../LodgingColumnsInfo'

export const useLodgingTableCols = ({
  sorting,
  inCampaigns,
}: {
  sorting?: DataTableColumnSort[]
  inCampaigns?: boolean
}) => {
  const commonColumns = useGooglePlacesBaseTableCols({
    sorting,
    inCampaigns,
    accesor: 'lodging',
    tableColumns: LODGING_COLUMNS,
    linkPrefix: 'lodging/chain',
  })
  return commonColumns
}

// This is an example on how to add custom columns into each data type
// export const useLodgingTableCols = ({
//   sorting,
//   inCampaigns,
// }: {
//   sorting?: DataTableColumnSort[]
//   inCampaigns?: boolean
// }) => {
//   const { preferences } = usePreferences()
//   const tooltips: Record<string, unknown> = preferences?.tooltips || {}
//   const columnHelper = createColumnHelper<LodgingChainProxy>()
//   const googlePlaceColumnHelper = createTableColumnHelper({
//     columns: LODGING_COLUMNS,
//     tooltips,
//   })
//   const commonColumns = useGooglePlacesBaseTableCols({
//     sorting,
//     inCampaigns,
//     accesor: 'lodging',
//     tableColumns: LODGING_COLUMNS,
//   })
//   const columns = useMemo(() => {
//     return [
//       ...commonColumns,
//       columnHelper.accessor('lodging.open_hours', {
//         ...googlePlaceColumnHelper.retrieveColumn('open_hours'),
//       }),
//     ]
//   }, [commonColumns])
//   return columns
// }
