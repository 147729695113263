import React, { ReactNode } from 'react'
import { BaseStep } from '../BaseStep'

interface StepContainerProps {
  title: string
  subtitle?: ReactNode
  currentStep: number
  totalSteps: number
  onBack?: () => void
  onContinue?: () => void
  handleClose: () => void
  continueButtonText: string
  backButtonText?: string
  loading: boolean
  canContinue?: boolean
  error?: string | null
  children: ReactNode
}

export function StepContainer({
  title,
  subtitle,
  currentStep,
  totalSteps,
  onBack,
  onContinue,
  handleClose,
  continueButtonText,
  backButtonText = 'Back',
  loading,
  canContinue = true,
  error,
  children,
}: StepContainerProps) {
  return (
    <BaseStep
      title={title}
      subtitle={subtitle}
      onBack={onBack}
      onContinue={onContinue}
      handleClose={handleClose}
      backButtonText={backButtonText}
      continueButtonText={continueButtonText}
      cancelButtonText="Cancel"
      canContinue={!loading && canContinue}
      isContinueLoading={loading}
      currentStep={currentStep}
      totalSteps={totalSteps}
      error={error}
    >
      {children}
    </BaseStep>
  )
}
