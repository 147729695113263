import React from 'react'
import apiService from 'services/api'
import { filterStoreRepo } from '../../../../Filters/FilterStore'
import FilterModal from '../../../../Filters/components/FilterModal'
import { FilterIdentifier } from 'models/saved_view'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'
import { CommonFiltersetGooglePlaceGroup } from '../../GooglePlaceBase/GooglePlaceBaseTable/CommonFiltersetGooglePlaceGroup'
import { LODGING_COLUMNS } from '../LodgingColumnsInfo'

export function LodgingFilterset({
  filterIdentifier,
  hideCampaignsFilter,
}: {
  filterIdentifier: FilterIdentifier
  hideCampaignsFilter?: boolean
}) {
  const api = apiService()
  const { data: campaignOptions } = api.useGetCampaignsOptions(
    'lodging-hospitality'
  )

  const lodgingFilterStore = filterStoreRepo.getStore(filterIdentifier)

  return (
    <FilterModal identifier={filterIdentifier} store={lodgingFilterStore}>
      <CommonFiltersetAccountGroup
        filterStore={lodgingFilterStore}
        campaignOptions={campaignOptions}
        hideCampaignsFilter={hideCampaignsFilter}
      />
      <CommonFiltersetGooglePlaceGroup
        filterStore={lodgingFilterStore}
        columns={LODGING_COLUMNS}
      />
    </FilterModal>
  )
}
