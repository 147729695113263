import React from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import CommonPage from 'components/DataExpansionComponents/Common/CommonPage'

export default function LodgingDoorPage() {
  const urlParams = useParams()
  const lodgingID = urlParams.lodging_id!

  const api = apiService()
  const { data, isLoading, isError, error } = api.useGetLodgingDoor(
    parseInt(lodgingID)
  )

  return (
    <CommonPage
      uniqueCompanyTypeId={parseInt(lodgingID)}
      useGetCompany={{
        companyData: data,
        contactCompanyData: data?.chain_proxy,
        isLoading,
        isError,
        error,
      }}
      tableTypeSlug="lodging-hospitality-doors"
      companyTypeSlug="lodging-hospitality"
      breadcrumbs={[
        {
          title: 'Lodging & Hospitality',
          breadcrumb: data?.lodging?.name,
        },
      ]}
      getFieldsFromData={(data) => {
        return {
          contactCompanyId: data?.chain_proxy.id,
          companyName: data?.lodging?.name,
          websiteAddress: data?.lodging?.website,
          address: {
            addressInfo: {
              fullAddress: data?.lodging?.address,
              city: data?.lodging?.city,
              state: data?.lodging?.state,
            },
            fullAddressInfo: data?.lodging?.address_info,
          },
        }
      }}
      customBottomLeftItems={
        [
          // {
          //   header: 'Provider Number',
          //   text: data?.hospital?.provider_number || 'Not available',
          // },
          // {
          //   header: 'Hospital Beds',
          //   text: formatInteger(data?.hospital?.total_hospital_beds),
          // },
          // {
          //   header: 'Hospital Days',
          //   text: formatInteger(data?.hospital?.total_hospital_days),
          // },
          // {
          //   header: 'Cafeteria Expenditures',
          //   text: data?.hospital?.cafeteria
          //     ? formatUsd(parseInt(data?.hospital?.cafeteria))
          //     : 'N/A',
          // },
          // {
          //   header: 'Patient Meal Expenditures',
          //   text:
          //     // convert to integer and format
          //     data?.hospital?.dietary
          //       ? formatUsd(parseInt(data?.hospital?.dietary))
          //       : 'N/A',
          // },
          // {
          //   header: 'Contracted Patient Meal Expenditures',
          //   text: data?.hospital?.dietary_under_contract
          //     ? formatUsd(parseInt(data?.hospital?.dietary_under_contract))
          //     : 'N/A',
          // },
        ]
      }
    />
  )
}
