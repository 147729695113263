import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom/client'

export const VIEWPORT_PRESETS = {
  iPhone: { width: 414, height: 897, label: 'Phone' }, // 19.5:9 aspect ratio (modern iPhone)
  Desktop: { width: 1100, height: 800, label: 'Desktop' },
} as const

export type ViewportPreset = keyof typeof VIEWPORT_PRESETS

interface MobilePreviewProps {
  children?: React.ReactNode
  url?: string
  selectedViewport?: ViewportPreset
  className?: string
}

export function MobilePreview({
  children,
  url,
  selectedViewport = 'iPhone',
  className = '',
}: MobilePreviewProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [scale, setScale] = useState(1)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const currentViewport = VIEWPORT_PRESETS[selectedViewport]
  const BORDER_WIDTH = 12 // Border width in pixels
  const outerWidth = currentViewport.width + BORDER_WIDTH * 2
  const outerHeight = currentViewport.height + BORDER_WIDTH * 2

  useEffect(() => {
    const updateScale = () => {
      if (!containerRef.current) return

      const containerRect = containerRef.current.getBoundingClientRect()
      setContainerSize({
        width: containerRect.width,
        height: containerRect.height,
      })
    }

    updateScale()
    window.addEventListener('resize', updateScale)
    return () => window.removeEventListener('resize', updateScale)
  }, [])

  useEffect(() => {
    // Calculate scale based on container size
    const availableWidth = containerSize.width
    const availableHeight = containerSize.height

    // Calculate scale based on both width and height constraints
    // Use 95% of the available space to ensure some padding
    const scaleByWidth = (availableWidth * 0.95) / outerWidth
    const scaleByHeight = (availableHeight * 0.95) / outerHeight

    // Use the smaller scale to ensure it fits both dimensions
    const newScale = Math.min(scaleByWidth, scaleByHeight)
    setScale(newScale)
  }, [containerSize, outerWidth, outerHeight])

  useEffect(() => {
    // Only run portal logic if we're using children (not URL)
    if (url || !children) return

    const iframe = iframeRef.current
    if (!iframe) return

    const renderContent = () => {
      const doc = iframe.contentWindow?.document
      if (!doc) return

      let root = doc.getElementById('mobile-preview-root')
      if (!root) {
        root = doc.createElement('div')
        root.id = 'mobile-preview-root'
        doc.body.appendChild(root)
      }

      ReactDOM.createRoot(root).render(children)
    }

    // Get all stylesheet rules from the parent document
    const styles = Array.from(document.styleSheets)
      .map((sheet) => {
        try {
          return Array.from(sheet.cssRules)
            .map((rule) => rule.cssText)
            .join('\n')
        } catch (e) {
          // Skip external stylesheets that might cause CORS issues
          return ''
        }
      })
      .join('\n')
      .replace(/!important/g, '')

    iframe.srcdoc = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <style>
            ${styles}
            body {
              margin: 0;
              min-height: 100vh;
              background: white;
              overflow-x: hidden;
            }
            #mobile-preview-root {
              min-height: 100vh;
            }
          </style>
        </head>
        <body>
          <div id="mobile-preview-root"></div>
        </body>
      </html>
    `

    iframe.onload = renderContent

    return () => {
      const doc = iframe.contentWindow?.document
      const root = doc?.getElementById('mobile-preview-root')
      if (root) {
        ReactDOM.createRoot(root).unmount()
      }
    }
  }, [children, url])

  const scaledWidth = outerWidth * scale
  const scaledHeight = outerHeight * scale

  return (
    <div
      ref={containerRef}
      className={`flex flex-col items-center justify-center w-full h-full ${className}`}
    >
      <div
        style={{
          width: scaledWidth,
          height: scaledHeight,
        }}
        className="relative"
      >
        <div
          className="absolute top-1/2 left-1/2 border-[12px] border-gray-900 rounded-[2.5rem] bg-gray-900"
          style={{
            width: outerWidth,
            height: outerHeight,
            transform: `translate(-50%, -50%) scale(${scale})`,
            transformOrigin: 'center center',
          }}
        >
          <div className="w-full h-full overflow-hidden rounded-[2rem] bg-white">
            <iframe
              ref={iframeRef}
              src={url}
              style={{
                width: currentViewport.width,
                height: currentViewport.height,
              }}
              className="border-0"
              title="Device Preview"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
