import apiService from 'services/api'

export function RebateCurrentInvoiceTab() {
  const api = apiService()
  const { data, isLoading } = api.useGetCurrentManufacturerInvoice()

  if (isLoading) return <div>Loading...</div>
  if (!data)
    return (
      <div className="text-center">
        <div className="text-lg font-bold">No claims for current period.</div>
      </div>
    )

  // Calculate total amount including fees
  const subtotal =
    data.invoice_items_to_create.reduce((sum, item) => sum + item.amount, 0) /
    100

  return (
    <div className="max-w-[1200px] mx-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left py-3">Item</th>
            <th className="text-right py-3 min-w-[100px]">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.invoice_items_to_create.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="py-3">{item.description}</td>
              <td className="text-right py-3">
                ${(item.amount / 100).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="border-b">
            <td className="text-right py-3">Subtotal</td>
            <td className="text-right py-3">${subtotal.toFixed(2)}</td>
          </tr>
          <tr className="border-b">
            <td className="text-right py-3 font-bold">Amount due</td>
            <td className="text-right py-3 font-bold">
              ${subtotal.toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
